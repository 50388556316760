import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { profileService } from '../../Services/ProfileService';
import Header from '../../Components/Header/Header';
import DatePicker from 'react-datepicker';
import Address from '../../Components/Address';
import _ from 'lodash';
import PriceStruct from '../../Components/PriceStruct';
import TextAreaCharCount from '../../Components/TextAreaCharCount';
import Footer from '../../Components/Footer/Footer';
import { getUserInfoFromSession } from '../../Services/session.mgmt';
import { format } from 'date-fns';
import { validate } from './ProfileUpdateValidation';
import './ProfileUpdate.css';

const ProfileUpdate = () => {
  const [contractorProfile, setContractorProfile] = useState(null);
  const [personalDetail, setPersonalDetail] = useState({});
  const [loading, setLoading] = useState(true);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [closeErrorDiaglog, setCloseErrorDiaglog] = useState(true);
  const [errors, setErrors] = useState({});
  const [localPriceStructs, setLocalPriceStructs] = useState([]);
  const [buzCenters, setBuzCenters] = useState([]);
  const { contractorNb } = useParams();
  const [contractorLoginId, setContractorLoginId] = useState(null);
  const [originalContractorLoginId, setOriginalContractorLoginId] = useState(null);
  const userInfo = getUserInfoFromSession();
  const nav = useNavigate();
  const taxIdClassName = userInfo.countryCd === 'CAN' ? 'fmc-input readOnly' : 'fmc-input';
  const [phoneCodes, setPhoneCodes] = useState([]);
  const [phoneDisplayVals, setPhoneDisplayVals] = useState({
    primary: { label: 'Primary Phone:', areaCode: '', num: '', cntctCd: 1 },
    fax: { label: 'Fax Number:', areaCode: '', num: '', cntctCd: 2 },
    afterHours: { label: 'After Hours Phone:', areaCode: '', num: '', cntctCd: 7 },
    mobile: { label: 'Cell Phone:', areaCode: '', num: '', cntctCd: 4 },
    primaryText: { label: 'Primary Text Phone:', areaCode: '', num: '', cntctCd: 5 },
    add1: { label: 'Additional Phones:', areaCode: '', num: '', cntctCd: '' },
    add2: { label: '', areaCode: '', num: '', cntctCd: '' },
    add3: { label: '', areaCode: '', num: '', cntctCd: '' },
  });
  let contacts;

  useEffect(() => {
    const fetchContractorProfile = async () => {
      try {
        const buzCenterResponse = await profileService.getBuzCenters();
        setBuzCenters(buzCenterResponse.data);
        const contractorIdDetails = await profileService.getContractorIdDetails(contractorNb);
        setContractorLoginId(contractorIdDetails?.data?.result?.trim());
        setOriginalContractorLoginId(contractorIdDetails?.data?.result?.trim());

        const contractProfileResp = await profileService.getContractorProfile(
          contractorIdDetails?.data?.result?.trim()
        );
        const contractProfileRespData = contractProfileResp.data[0];
        setContractorProfile(contractProfileRespData);
        console.log('=========== contractorProfile =========' + JSON.stringify(contractorProfile));

        const contractPersonalResp = await profileService.getContractorPersonal(contractorNb);
        const personalDetailsRespData = {
          ...contractPersonalResp.data.result.cntrctrData2ApiList[0],
          cntrctrNb: contractorNb,
          cntrctrTypeCd: contractProfileRespData.cntrctrTypeCd,
          lstUpdtUserIdCd: userInfo.userid,
        };
        setPersonalDetail(personalDetailsRespData);
        console.log('======== personalDetails ==============' + JSON.stringify(personalDetail));
        //get price structor data
        const feePriceStructResp = await profileService.getContractorPriceStructs(contractorNb);
        _.forEach(feePriceStructResp.data.result.cntrctrFeeVector, (value) => {
          value.status = 'exist';
        });
        setLocalPriceStructs(feePriceStructResp.data.result.cntrctrFeeVector);

        const phoneCodesResp = await profileService.getPhoneCodes();
        const defaultPhoneCode = {
          cntctCd: '',
          cntctCdDs: '',
        };
        phoneCodesResp.data.unshift(defaultPhoneCode);
        setPhoneCodes(phoneCodesResp.data);

        const contactsResp = await profileService.getContractContactsByCntrctrNb(contractorNb);
        setPhoneDisplayVals((prevVals) => {
          // Create a copy of the previous state to avoid direct mutation
          const updatedVals = { ...prevVals };

          // Loop through each phone data entry from the API response
          contactsResp.data.forEach(({ cntctCd, phnCityAreaCd, phnNb }) => {
            // Check if cntctCd is one of the special values (6, 8, 9, or 10)
            if ([6, 8, 9, 10].includes(cntctCd)) {
              // Find the first available 'add' field (add1, add2, add3)
              for (let addKey of ['add1', 'add2', 'add3']) {
                if (!updatedVals[addKey].areaCode) {
                  // If areaCode is empty, update this field with the phone data
                  updatedVals[addKey] = {
                    ...updatedVals[addKey],
                    areaCode: phnCityAreaCd,
                    num: phnNb,
                    cntctCd: cntctCd, // Optionally, you can store the cntctCd here if needed
                  };
                  break; // Stop once we update the first available add field
                }
              }
            } else {
              // For other cntctCd values, update the corresponding fields (primary, fax, etc.)
              const key = Object.keys(updatedVals).find((key) => updatedVals[key].cntctCd === cntctCd);

              if (key) {
                updatedVals[key] = {
                  ...updatedVals[key],
                  areaCode: phnCityAreaCd,
                  num: phnNb,
                };
              }
            }
          });

          return updatedVals;
        });
      } catch (err) {
        console.error('Failed to fetch contractorData' + err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchContractorProfile();
  }, [contractorNb, userInfo.userid]);

  const validateInputs = () => {
    let errs = validate(personalDetail, contractorLoginId, userInfo.countryCd, localPriceStructs, phoneDisplayVals);
    setErrors(errs);
    if (Object.keys(errs).length > 0) {
      setCloseErrorDiaglog(false);
    }
    return errs;
  };

  const handleUpdateProfile = (e) => {
    e.preventDefault();
    const errs = validateInputs();
    if (Object.keys(errs).length === 0) {
      try {
        mapContacts();
        checkAndUpdateUserId();
      } catch (e) {
        console.log('update personalDetail failed: ' + JSON.stringify(e));
      }
    } else {
      setCloseErrorDiaglog(false);
    }
  };

  const mapContacts = () => {
    let updatedValues = [];
    let phoneSqnc = 0;
    for (let key in phoneDisplayVals) {
      if (phoneDisplayVals[key].num.length === 7 && phoneDisplayVals[key].areaCode.length === 3) {
        updatedValues[updatedValues.length] = {
          cntctSqncNb: phoneSqnc++,
          cntctCd: Number(phoneDisplayVals[key].cntctCd),
          phnCityAreaCd: phoneDisplayVals[key].areaCode,
          phnNb: phoneDisplayVals[key].num,
        };
      }
    }
    contacts = updatedValues;
  };

  const checkAndUpdateUserId = () => {
    if (originalContractorLoginId !== contractorLoginId) {
      profileService.isContractorAvailable(contractorLoginId).then((value) => {
        // 200 means contractor id exists. 204 means it does not exist
        if (value.status === 204) {
          saveContractorIdDetails();
        } else {
          setErrors((prevState) => {
            return { ...prevState, userId: 'Contractor login id is not available.' };
          });
          setCloseErrorDiaglog(false);
        }
      });
    } else {
      updateProfilePromise();
    }
  };

  const saveContractorIdDetails = () => {
    profileService
      .saveContractorIdDetails({
        cntrctrUserIdCd: contractorLoginId,
        cntrctrNb: Number(personalDetail.cntrctrNb),
        lstUpdtUserId: userInfo['userid'],
      })
      .then(() => {
        updateProfilePromise();
      })
      .catch((e) => {
        setErrors((prevState) => {
          return { ...prevState, cntrctrUserIdCd: 'Contractor login id is not available.' };
        });
        setCloseErrorDiaglog(false);
      });
  };

  const updateProfilePromise = () => {
    Promise.all([
      profileService.updatePersonalDetail(personalDetail),
      profileService.updatePriceStruct(
        localPriceStructs.map((ps) => {
          return {
            prcStrctNb: ps.prcStrctNb,
            prcStrctTx: ps.prcStrctTx,
            feeAm: Number(ps.feeAm),
            vlntrFeeAm: Number(ps.vlntrFeeAm),
            vlntrTodlrFeeAm: Number(ps.vlntrTodlrFeeAm),
            cntngntIn: ps.cntngntIn,
            dlvryToAuctIn: ps.dlvryToAuctIn,
            gvwTypeCd: ps.gvwTypeCd,
            clsFeeAm: Number(ps.clsFeeAm),
            effDtStr: ps.effDt ?? format(new Date(), 'yyyy-MM-dd'),
            lstUpdtUserId: userInfo.userid,
            status: ps.status,
          };
        }),
        personalDetail.cntrctrNb,
        personalDetail.cntryCd
      ),
      profileService.updateContacts(contacts, personalDetail.cntrctrNb, userInfo.userid),
    ]).then((results) => {
      setUpdateSuccess(true);
    });
  };

  const handleCancelProfile = () => {
    nav('/contractorprofile');
  };
  const updateContractAddress = (fieldName, fieldValue) => {
    setPersonalDetail((prevCntrctrPersonal) => ({
      ...prevCntrctrPersonal,
      [fieldName]: fieldValue,
    }));
  };
  const handleAddPriceStruct = () => {
    let idMax = localPriceStructs.length;
    //get
    idMax = _.maxBy(localPriceStructs, 'prcStrctNb')?.prcStrctNb + 1;
    const newPriceStruct = {
      cntngntIn: 'C',
      dlvryToAuctIn: 'D',
      gvwTypeCd: 'M',
      prcStrctNb: idMax,
      status: 'new',
      clsFeeAm: '0',
      prcStrctTx: '',
      vlntrFeeAm: '',
      vlntrTodlrFeeAm: '',
      feeAm: '',
    };
    setLocalPriceStructs((prevPriceStructs) => [...prevPriceStructs, newPriceStruct]);
  };
  const handleTextCommentsUpdate = (textArea) => {
    switch (textArea.fieldName) {
      case 'busLcnsTx':
        setPersonalDetail((prevPersonalDetail) => ({
          ...prevPersonalDetail,
          busLcnsTx: textArea.text,
        }));
        console.log('update textArea from busLcnsTx: ' + JSON.stringify(personalDetail));
        break;
      case 'insInfoTx':
        setPersonalDetail((prevPersonalDetail) => ({
          ...prevPersonalDetail,
          insInfoTx: textArea.text,
        }));
        console.log('update textArea from insInfoTx: ' + JSON.stringify(personalDetail));
        break;
      case 'othrInfoTx':
        setPersonalDetail((prevPersonalDetail) => ({
          ...prevPersonalDetail,
          othrInfoTx: textArea.text,
        }));
        console.log('update textArea from othrInfoTx: ' + JSON.stringify(personalDetail));
        break;
      default:
        break;
    }
  };
  const handleBuzCenterSelected = (e) => {
    const { value } = e.target;
    setPersonalDetail((prevPersonalDetail) => ({
      ...prevPersonalDetail,
      mngtBcLoc: value,
    }));
  };
  const handlePersonalDetailGsdbCd = (e) => {
    const { value } = e.target;
    setPersonalDetail((prevPersonalDetail) => ({
      ...prevPersonalDetail,
      gsdbCd: value,
    }));
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setPersonalDetail((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleContactChange = (e) => {
    const { name, value, dataset } = e.target;
    const { key } = dataset; // This is the unique key of the current phone entry (like primary, add1, add2, etc.)

    console.log('phoneDisplayVals', phoneDisplayVals);
    setPhoneDisplayVals((prevVals) => {
      // Create a copy of the previous state to avoid direct mutation
      const updatedVals = { ...prevVals };

      // Check if the key exists in the state
      if (updatedVals[key]) {
        // Update the corresponding phone entry based on the name of the field (areaCode, num, cntctCd)
        updatedVals[key] = {
          ...updatedVals[key],
          [name]: value, // Dynamically update the property (either areaCode, num, or cntctCd)
        };
      }

      console.log('phoneDisplayVals', phoneDisplayVals);
      return updatedVals;
    });
  };

  const handleInsInfoExpirDtChange = (date) => {
    const formattedDate = formatDate(date);
    setPersonalDetail((prevData) => ({
      ...prevData,
      insInfoExpirDt: formattedDate,
    }));
  };

  const handleBusLcnsExpirDtChange = (date) => {
    const formattedDate = formatDate(date);
    setPersonalDetail((prevData) => ({
      ...prevData,
      busLcnsExpirDt: formattedDate,
    }));
  };

  const handleExpirDtChange = (date) => {
    const formattedDate = formatDate(date);
    setPersonalDetail((prevData) => ({
      ...prevData,
      expirDt: formattedDate,
    }));
  };

  const formatDate = (date) => {
    if (!date) return '';

    // Extract month, day, and year
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();

    // Return formatted string
    return `${month}/${day}/${year}`;
  };
  const handlePersonalDetailEffDt = (date) => {
    setPersonalDetail((prevPersonalDetail) => ({
      ...prevPersonalDetail,
      effDt: formatDate(date),
    }));
  };
  const handlePersonalDetailTaxNb = (e) => {
    const { value } = e.target;
    setPersonalDetail((prevPersonalDetail) => ({
      ...prevPersonalDetail,
      taxNb: value,
    }));
  };

  const handleCompanyName = (e) => {
    const { value } = e.target;
    setPersonalDetail((prevCntrctrPersonal) => ({
      ...prevCntrctrPersonal,
      cntrctrBusNm: value,
    }));
  };
  const handleUserId = (e) => {
    const { value } = e.target;
    setContractorLoginId(value);
  };
  const handleRdnInd = (e) => {
    const { value } = e.target;
    setPersonalDetail((prevCntrctrPersonal) => ({
      ...prevCntrctrPersonal,
      rdnInd: Number(value),
    }));
  };
  const handleContractorTypeCd = (e) => {
    const { value } = e.target;
    setPersonalDetail((prevCntrctrPersonal) => ({
      ...prevCntrctrPersonal,
      cntrctrTypeCd: Number(value),
    }));
  };

  const handlePriceStructUpdate = (psIdIn, fieldName, fieldValue) => {
    const updatedPriceStructs = _.map(localPriceStructs, (elm) => {
      if (psIdIn === elm.prcStrctNb) {
        return { ...elm, [fieldName]: fieldValue };
      }
      return elm;
    });
    setLocalPriceStructs(updatedPriceStructs);
  };
  const handleCloseErrorDiaglog = () => {
    setCloseErrorDiaglog(true);
    setErrors({});
  };
  const handleBackOrCancel = () => {
    nav('/contractorprofile');
  };
  if (loading)
    return (
      <>
        <Header heading="Modify Contractor Profile" summaryDisplay="none" profileDisplay="none" />
        <br />
        Loading...
      </>
    );
  if (!personalDetail)
    return (
      <>
        <Header heading="Modify Contractor Profile" summaryDisplay="none" profileDisplay="none" />
        <br />
        No profile found
      </>
    );
  if (Object.keys(errors).length > 0 && !closeErrorDiaglog)
    return (
      <>
        <div
          className="fmc-dialog fmc-dialog--opened fds-align--center"
          role="dialog"
          aria-modal="true"
          aria-label="Lorem Ipsum Dolor"
        >
          <div className="fmc-dialog__content">
            <button
              className="fmc-dialog__close fds-icon fds-font--ford-icons__clear"
              aria-label="A close button."
              onClick={handleCloseErrorDiaglog}
            ></button>
            <h3 className="fmc-type--heading5 fds-m--b-2">Errors</h3>
            <div style={{ textAlign: 'left' }}>
              {_.map(errors, (error, key) => (
                <p key={key} style={{ color: 'red' }} className="fmc-type--body1 fds-m--b-2">
                  {error}
                </p>
              ))}
            </div>
            <div className="fds-m--t-3">
              <button className="fmc-button fmc-button--outlined fds-m--b-2" onClick={handleCloseErrorDiaglog}>
                Close
              </button>
            </div>
          </div>
        </div>
      </>
    );
  return (
    <>
      <Header heading="Modify Contractor Profile" summaryDisplay="none" profileDisplay="none" />
      {updateSuccess ? (
        <div className="fmc-alert fmc-alert--success fmc-mb-3" role="alert">
          <p>Contractor information updated successfully</p>
          <fmc-button name="Back" label="Back" onClick={handleBackOrCancel} />
        </div>
      ) : (
        <div className="margin10">
          <table border="5" style={{ width: '100%' }} className="fmc-table">
            <thead>
              <tr>
                <th width="40%"></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <label for="cntrctrBusNm">Company name:</label>
                </td>
                <td>
                  <input
                    type="text"
                    id="cntrctrBusNm"
                    name="cntrctrBusNm"
                    className="fmc-input"
                    value={personalDetail.cntrctrBusNm}
                    maxLength="30"
                    onChange={handleCompanyName}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label>Contractor number:</label>
                </td>
                <td>{personalDetail.cntrctrNb}</td>
              </tr>
              <tr>
                <td>
                  <label>Contractor login id:</label>
                </td>
                <td>
                  <input
                    type="text"
                    name="cntrctrUserIdCd"
                    data-testid="cntrctrUserIdCd"
                    value={contractorLoginId}
                    className="fmc-input"
                    onChange={handleUserId}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label>RDN Indicator:</label>
                </td>
                <td>
                  <select
                    className="fmc-select"
                    id="rdnInd"
                    name="rdnInd"
                    value={personalDetail.rdnInd}
                    onChange={handleRdnInd}
                  >
                    <option value="0">No</option>
                    <option value="1">Yes</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <label>GSDB Code:</label>
                </td>
                <td>
                  <input
                    id="gsdbCd"
                    name="gsdbCd"
                    type="text"
                    value={personalDetail.gsdbCd}
                    className="fmc-input"
                    onChange={handlePersonalDetailGsdbCd}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label>Member Since Date (Hire Date):</label>
                </td>
                <td>
                  <div className="datePicker" style={{ minWidth: '12em' }}>
                    <DatePicker selected={personalDetail.effDt} onChange={(date) => handlePersonalDetailEffDt(date)} />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <label>Contractor Type Code:</label>
                </td>
                <td>
                  <select
                    id="cntrctrTypeCd"
                    name="cntrctrTypeCd"
                    value={personalDetail.cntrctrTypeCd}
                    className="fmc-select"
                    onChange={handleContractorTypeCd}
                  >
                    <option value="7">Repossession</option>
                    <option value="9">CSR-Repo</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <label>Managing Business Center Location:</label>
                </td>
                <td>
                  <select
                    id="superBranchCd"
                    name="superBranchCd"
                    value={personalDetail.mngtBcLoc}
                    className="fmc-select"
                    onChange={handleBuzCenterSelected}
                  >
                    {buzCenters.map((buz) => (
                      <option value={buz.superBranchCd} key={buz.superBranchCd}>
                        {buz.superBranchCd} - {buz.branchNm}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              <Address
                addressData={{
                  addressLine: personalDetail.addrLine1Tx,
                  stateOrProvCodes: personalDetail.stAbbrCd,
                  stateOrZip: personalDetail.zipCd,
                  city: personalDetail.cityNm,
                  email: personalDetail.emailAddrTx,
                  secondaryEmail: personalDetail.scndryEmailAddrTx,
                  countryCd: userInfo.countryCd,
                }}
                onChange={updateContractAddress}
              ></Address>
              <tr>
                <td>
                  <label>Federal Tax Id:</label>
                </td>
                <td>
                  <input
                    id="taxNb"
                    name="taxNb"
                    type="text"
                    className={taxIdClassName}
                    value={personalDetail.taxNb}
                    onChange={handlePersonalDetailTaxNb}
                    readOnly={userInfo.countryCd === 'CAN'}
                  />
                </td>
              </tr>
              {localPriceStructs ? (
                _.map(localPriceStructs, (ps) => (
                  <PriceStruct
                    key={ps.prcStrctNb}
                    prcStrctData={{
                      psId: ps.prcStrctNb,
                      name: ps.prcStrctTx,
                      selfHelpAmt: ps.feeAm,
                      volFeeAmt: ps.vlntrFeeAm,
                      dealerVolFeeAmt: ps.vlntrTodlrFeeAm,
                      closeFeeAmt: ps.clsFeeAm,
                      cntngntIn: ps.cntngntIn,
                      dlvryToAuctIn: ps.dlvryToAuctIn,
                      gvwTypeCd: ps.gvwTypeCd,
                      status: ps.status,
                    }}
                    onUpdate={handlePriceStructUpdate}
                  />
                ))
              ) : (
                <PriceStruct prcStrctData={{ prcStrctNb: 0, status: 'new' }} onUpdate={handlePriceStructUpdate} />
              )}
              <tr>
                <td>
                  <fmc-button
                    data-testid="addPriceStructButton"
                    name="Add a New Price Structure"
                    label="Add a New Price Structure"
                    onClick={handleAddPriceStruct}
                  />
                </td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <label>Primary Contact:</label>
                </td>
                <td>
                  <input
                    type="text"
                    name="prmryCntctNm"
                    className="fmc-input"
                    value={personalDetail.prmryCntctNm}
                    onChange={handleOnChange}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label>Secondary Contact:</label>
                </td>
                <td>
                  <input
                    type="text"
                    name="scndryCntctNm"
                    className="fmc-input"
                    value={personalDetail.scndryCntctNm}
                    onChange={handleOnChange}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label>Suspension Date:</label>
                </td>
                <td>
                  <div className="datePicker" style={{ minWidth: '12em' }}>
                    <DatePicker selected={personalDetail.expirDt} onChange={(date) => handleExpirDtChange(date)} />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <label>Business License Expiration Date:</label>
                </td>
                <td>
                  <div className="datePicker" style={{ minWidth: '12em' }}>
                    <DatePicker
                      selected={personalDetail.busLcnsExpirDt}
                      onChange={(date) => handleBusLcnsExpirDtChange(date)}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <label>Insurance Expiration Date:</label>
                </td>
                <td>
                  <div className="datePicker" style={{ minWidth: '12em' }}>
                    <DatePicker
                      selected={personalDetail.insInfoExpirDt}
                      onChange={(date) => handleInsInfoExpirDtChange(date)}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <label>Alert Preference:</label>
                </td>
                <td>
                  <select
                    name="alrtPrfrncCd"
                    className="fmc-select"
                    style={{ width: '16em' }}
                    value={personalDetail.alrtPrfrncCd}
                    onChange={handleOnChange}
                  >
                    <option value="E">Email</option>
                    <option value="T">Text via Phone</option>
                    <option value="B">Both Email and Text</option>
                  </select>
                </td>
              </tr>
              {Object.entries(phoneDisplayVals).map(([key, { label, areaCode, num, cntctCd }]) => (
                <tr key={key}>
                  <td style={{ width: '36%' }}>
                    <label>{label}</label>
                  </td>
                  <td>
                    {['add1', 'add2', 'add3'].includes(key) && (
                      <select
                        name="cntctCd"
                        value={cntctCd}
                        className="fmc-select"
                        onChange={handleContactChange}
                        style={{ width: '14em', display: 'inline-block' }}
                        data-key={key}
                      >
                        {phoneCodes?.map((phoneCode) => (
                          <option key={phoneCode.cntctCd} value={phoneCode.cntctCd}>
                            {phoneCode.cntctCdDs}
                          </option>
                        ))}
                      </select>
                    )}
                    <input
                      type="text"
                      name="areaCode" // Name is areaCode for the input field
                      className="fmc-input"
                      style={{ width: '5em', display: 'inline-block' }}
                      value={areaCode}
                      onChange={handleContactChange}
                      maxLength="3"
                      pattern="\d*"
                      data-key={key}
                    />
                    &nbsp;&nbsp;
                    <input
                      type="text"
                      name="num"
                      className="fmc-input"
                      style={{ width: '12em', display: 'inline-block' }}
                      value={num}
                      onChange={handleContactChange}
                      maxLength="7"
                      pattern="\d*"
                      data-key={key}
                    />
                  </td>
                </tr>
              ))}
              <tr>
                <td>
                  <label>Bus Lcns Text:</label>
                </td>
                <td>
                  {personalDetail ? (
                    <TextAreaCharCount
                      formData={{
                        fieldName: 'busLcnsTx',
                        text: personalDetail.busLcnsTx,
                      }}
                      maxChar={120}
                      onUpdate={handleTextCommentsUpdate}
                    />
                  ) : (
                    <p>Loading...</p>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <label>Ins Info Text:</label>
                </td>
                <td>
                  {personalDetail ? (
                    <TextAreaCharCount
                      formData={{
                        fieldName: 'insInfoTx',
                        text: personalDetail.insInfoTx,
                      }}
                      maxChar={120}
                      onUpdate={handleTextCommentsUpdate}
                    />
                  ) : (
                    <p>Loading...</p>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <label>Other:</label>
                </td>
                <td>
                  {personalDetail ? (
                    <TextAreaCharCount
                      formData={{
                        fieldName: 'othrInfoTx',
                        text: personalDetail.othrInfoTx,
                      }}
                      maxChar={500}
                      onUpdate={handleTextCommentsUpdate}
                    />
                  ) : (
                    <p>Loading...</p>
                  )}
                </td>
              </tr>
            </tbody>
          </table>

          <div className="centerAlign" style={{ paddingTop: '2em' }}>
            <fmc-button name="Update" data-testid="updateFMCButton" label="Update" onClick={handleUpdateProfile} />
            &nbsp;&nbsp;
            <fmc-button name="Cancel" data-testid="cancelFMCButton" label="Cancel" onClick={handleCancelProfile} />
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};
export default ProfileUpdate;
