import './Profile.css';
import React, { useEffect, useState } from 'react';
import Header from '../../Components/Header/Header';
import { getUserInfoFromSession } from '../../Services/session.mgmt';
import { getCall, putCall } from '../../Services/CommonService';
import '@ford/ford-design-system/dist/web-components.js';
import { coverageService } from '../../Services/CoverageService';
import _ from 'lodash';
import { profileService } from '../../Services/ProfileService';
import { showAlert } from '../../Services/CommonFunctions';
import { useNavigate } from 'react-router-dom';
import { CAN_POSTAL_PATTERN, USA_ZIP_PATTERN } from '../../constants/RepoReactConstants';

function Profile() {
  const [Contacts, setContacts] = useState([]);
  const [CntrPersonalDetail, setCntrPersonalDetail] = useState([]);
  const [StatesOrProvince, setStatesOrProvince] = useState([]);
  const [priceStructures, setPriceStructures] = useState([]);
  const [GroupedCoverages, setGroupedCoverages] = useState([]);
  const [remainingChars, setRemainingChars] = useState(255);
  const error = {};
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const userInfo = getUserInfoFromSession();

  const countCharacters = (e) => {
    const value = e.target.value;
    setCntrPersonalDetail((prevCntrPersonalDetail) => ({
      ...prevCntrPersonalDetail,
      othrInfoTx: value,
    }));
    setRemainingChars(255 - value.length);
  };

  const updateContactAreaCd = (cntctCd, e) => {
    const value = e.target.value;
    setContacts((prevContacts) =>
      prevContacts.map((contact) =>
        contact.cntctCd == cntctCd
          ? {
              ...contact,
              phnCityAreaCd: value,
            }
          : contact
      )
    );
  };

  const updateContactPhoneNb = (cntctCd, e) => {
    const value = e.target.value;
    setContacts((prevContacts) =>
      prevContacts.map((contact) =>
        contact.cntctCd == cntctCd
          ? {
              ...contact,
              phnNb: value,
            }
          : contact
      )
    );
  };

  const handleMandatoryFieldChange = (e) => {
    const { name, value } = e.target;
    if (!value) {
      error[name] = `${name} is required`;
    } else {
      delete error[name];
      setCntrPersonalDetail((prevCntrPersonalDetail) => ({
        ...prevCntrPersonalDetail,
        [name]: value,
      }));
    }
  };

  const handleZipCodeChange = (e) => {
    const value = e.target.value;
    delete error.zipCd;
    //validation rules based on userInfo.countryCd
    if (!value) {
      error['zipCd'] = 'Zip code is required';
    }
    if (!error.zipCd) {
      if (userInfo.countryCd === 'CAN') {
        if (!CAN_POSTAL_PATTERN.test(value)) {
          error['zipCd'] = 'Invalid Canadian postal code';
        }
      } else {
        if (!USA_ZIP_PATTERN.test(value)) {
          error['zipCd'] = 'Invalid US zipCode';
        }
      }
    }
    if (!error.zipCd) {
      setCntrPersonalDetail((prevCntrPersonalDetail) => ({
        ...prevCntrPersonalDetail,
        zipCd: value,
      }));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        Promise.all([
          // getCall('SqlMgr/getCntrctrProfile/' + userInfo.userid),
          getCall('profileMaint/getContractorContact/' + userInfo.cntrctrNb),
          getCall('modifyContractorProfile/getContractorPersonalDetails/' + userInfo.cntrctrNb),
          coverageService.getStateOrProvinceByStAbbr(userInfo.countryCd),
          coverageService.getCoverageByContractorNb(userInfo.cntrctrNb, userInfo.countryCd),
          profileService.getContractorPriceStructs(userInfo.cntrctrNb),
        ])
          .then(
            ([
              //contractorResponse,
              contactResponse,
              cntrPersonalDetailsResponse,
              stateOrProviceResponse,
              coveragesResponse,
              priceStructureResponse,
            ]) => {
              // setContractorProfile(contractorResponse.data[0]);
              setContacts(contactResponse.data);
              setCntrPersonalDetail(cntrPersonalDetailsResponse.data.result.cntrctrData2ApiList[0]);
              setStatesOrProvince(stateOrProviceResponse);
              const groupedData = _.chain(coveragesResponse)
                .groupBy('prcStrctTx')
                .mapValues((value) => _.groupBy(value, 'stateOrProv'))
                .value();
              setGroupedCoverages(groupedData);
              console.log('groupedData : ' + JSON.stringify(groupedData));
              setPriceStructures(priceStructureResponse.data.result.cntrctrFeeVector);
              //set otherComm char counts
              setRemainingChars(
                255 - cntrPersonalDetailsResponse.data.result.cntrctrData2ApiList[0].othrInfoTx?.length
              );
            }
          )
          .catch((error) => {
            console.error(error);
          });
      } catch (error) {
        console.error('Failed to fetch states:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  function update() {
    if (_.isEmpty(error)) {
      const personalDetailUpdate = _.pick(CntrPersonalDetail, [
        'addrLine1Tx',
        'alrtPrfrncCd',
        'cityNm',
        'emailAddrTx',
        'gsdbCd',
        'othrInfoTx',
        'prmryCntctNm',
        'rdnInd',
        'scndryCntctNm',
        'stAbbrCd',
        'zipCd',
      ]);
      console.log('personalDetailUpdate : ' + JSON.stringify(personalDetailUpdate));
      Promise.all([
        putCall('profileMaint/updateContractorData/' + userInfo.userid, personalDetailUpdate),
        profileService.updateContacts(Contacts, userInfo.cntrctrNb, userInfo.userid),
      ])
        .then(([psnDetailResponse, contactsResponse]) => {
          showAlert('Profile Saved successfully!!', 'success').then(() => {
            navigate('/misc');
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      showAlert(Object.values(error).join('\n'), 'error');
    }
  }

  return loading ? (
    <div>Loading...</div>
  ) : (
    <>
      <Header heading="Profile" />
      <br />
      <div className="details_cntr">
        <img
          className="fmc-top-banner__demo-billboard"
          src={process.env.PUBLIC_URL + '/Assets/Profile.png'}
          alt=""
          height="100px"
          width="230px"
        />

        <div className="fds-layout-gri p-0">
          <div className="fds-layout-grid__inner p-0 ">
            <div className="fds-layout-grid__cell--span-6">
              <div className="fds-layout-gri p-0">
                <div className="fds-layout-grid__inner p-0 ">
                  <div className="fds-layout-grid__cell--span-4">Name:</div>
                  <div className="fds-layout-grid__cell--span-8">{CntrPersonalDetail.cntrctrBusNm}</div>
                </div>
                <br />
                <div className="fds-layout-grid__inner p-0 ">
                  <div className="fds-layout-grid__cell--span-4">Address:</div>
                  <div className="fds-layout-grid__cell--span-8">
                    <fmc-input
                      name="addrLine1Tx"
                      value={CntrPersonalDetail.addrLine1Tx}
                      onInput={handleMandatoryFieldChange}
                    ></fmc-input>
                    <fmc-input
                      name="cityNm"
                      value={CntrPersonalDetail.cityNm}
                      onInput={handleMandatoryFieldChange}
                    ></fmc-input>

                    <div className="fds-layout-gri p-0">
                      <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-6">
                          <select
                            className="fmc-select"
                            slot="input"
                            name="stAbbrCd"
                            status="standard"
                            value={CntrPersonalDetail.stAbbrCd}
                            required
                            onChange={(e) =>
                              setCntrPersonalDetail((prevState) => ({
                                ...prevState,
                                stAbbrCd: e.target.value,
                              }))
                            }
                          >
                            {StatesOrProvince.map((item) => (
                              <option key={item.stAbbrCd} value={item.stAbbrCd}>
                                {item.stAbbrCd}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="fds-layout-grid__cell--span-6">
                          <fmc-input
                            name="zipCd"
                            value={CntrPersonalDetail.zipCd}
                            onInput={handleZipCodeChange}
                          ></fmc-input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className="fds-layout-grid__inner p-0 ">
                  <div className="fds-layout-grid__cell--span-4">Fedrel Tax Id:</div>
                  <div className="fds-layout-grid__cell--span-8">{CntrPersonalDetail.taxNb}</div>
                </div>
                <br />
                <div className="fds-layout-grid__inner p-0 ">
                  <div className="fds-layout-grid__cell--span-4">Alert Preference:</div>
                  <div className="fds-layout-grid__cell--span-8">
                    <select
                      name="alrtPrfrncCd"
                      id="vehicle-select"
                      className="fmc-select"
                      value={CntrPersonalDetail.alrtPrfrncCd}
                      onInput={(e) =>
                        setCntrPersonalDetail((prevState) => ({
                          ...prevState,
                          alrtPrfrncCd: e.target.value,
                        }))
                      }
                    >
                      <option label="Email" value="E">
                        Email
                      </option>
                      <option label="Both Email and Text" value="B">
                        Both Email and Text
                      </option>
                      <option label="Text via Phone" value="T">
                        Text via Phone
                      </option>
                    </select>
                  </div>
                </div>
                <br />
                <div className="fds-layout-grid__inner p-0 ">
                  <div className="fds-layout-grid__cell--span-4">Email Addresess:</div>
                  <div className="fds-layout-grid__cell--span-8">
                    <fmc-input
                      name="emailAddrTx"
                      value={CntrPersonalDetail.emailAddrTx}
                      onInput={handleMandatoryFieldChange}
                    ></fmc-input>
                  </div>
                </div>
                <br />
                <div className="fds-layout-grid__inner p-0 ">
                  <div className="fds-layout-grid__cell--span-4">Primary Contact:</div>
                  <div className="fds-layout-grid__cell--span-8">
                    <fmc-input
                      name="prmryCntctNm"
                      value={CntrPersonalDetail.prmryCntctNm}
                      onInput={handleMandatoryFieldChange}
                    ></fmc-input>
                  </div>
                </div>
                <br />
                <div className="fds-layout-grid__inner p-0 ">
                  <div className="fds-layout-grid__cell--span-4">Secondary Contact:</div>
                  <div className="fds-layout-grid__cell--span-8">
                    <fmc-input
                      name="scndryCntctNm"
                      value={CntrPersonalDetail.scndryCntctNm}
                      onInput={(e) =>
                        setCntrPersonalDetail((prevState) => ({
                          ...prevState,
                          scndryCntctNm: e.target.value,
                        }))
                      }
                    ></fmc-input>
                  </div>
                </div>
                <br />
                <div className="fds-layout-grid__inner p-0 ">
                  <div className="fds-layout-grid__cell--span-4">Other:</div>
                  <div className="fds-layout-grid__cell--span-8">
                    <fmc-textarea
                      maxLength="255"
                      name="othrInfoTx"
                      value={CntrPersonalDetail.othrInfoTx}
                      onKeyUp={countCharacters}
                      onFocus={countCharacters}
                      onPaste={countCharacters}
                    ></fmc-textarea>
                    Remaining characters: {remainingChars} of 255
                  </div>
                </div>
                <br />
              </div>
            </div>
            <div className="fds-layout-grid__cell--span-6">
              <div className="fds-layout-grid__inner p-0 ">
                <div className="fds-layout-grid__cell--span-4">Primary Phone:</div>
                <div className="fds-layout-grid__cell--span-8">
                  <div className="fds-layout-gri p-0">
                    <div className="fds-layout-grid__inner p-0 ">
                      {Contacts.filter((contact) => contact.cntctCd === 1).map((contact) => (
                        <>
                          <div className="fds-layout-grid__cell--span-4">
                            <fmc-input
                              maxLength="3"
                              onBlur={(elm) => updateContactAreaCd('1', elm)}
                              name="phoneArea1"
                              limit="3n"
                              value={contact.phnCityAreaCd}
                            ></fmc-input>
                          </div>
                          <div className="fds-layout-grid__cell--span-8">
                            <fmc-input
                              name="phoneNumber1"
                              value={contact.phnNb}
                              maxLength="7"
                              onBlur={(elm) => updateContactPhoneNb('1', elm)}
                            ></fmc-input>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="fds-layout-grid__inner p-0 ">
                <div className="fds-layout-grid__cell--span-4">Fax Number:</div>
                <div className="fds-layout-grid__cell--span-8">
                  <div className="fds-layout-gri p-0">
                    <div className="fds-layout-grid__inner p-0 ">
                      {Contacts.filter((contact) => contact.cntctCd === 2).map((contact) => (
                        <>
                          <div className="fds-layout-grid__cell--span-4">
                            <fmc-input
                              maxLength="3"
                              onBlur={(elm) => updateContactAreaCd('2', elm)}
                              name="phoneArea2"
                              limit="3n"
                              value={contact.phnCityAreaCd}
                            ></fmc-input>
                          </div>
                          <div className="fds-layout-grid__cell--span-8">
                            <fmc-input
                              name="phoneNumber2"
                              value={contact.phnNb}
                              maxLength="7"
                              onBlur={(elm) => updateContactPhoneNb('2', elm)}
                            ></fmc-input>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="fds-layout-grid__inner p-0 ">
                <div className="fds-layout-grid__cell--span-4">After Hours Phone:</div>
                <div className="fds-layout-grid__cell--span-8">
                  <div className="fds-layout-gri p-0">
                    <div className="fds-layout-grid__inner p-0 ">
                      {Contacts.filter((contact) => contact.cntctCd === 7).map((contact) => (
                        <>
                          <div className="fds-layout-grid__cell--span-4">
                            <fmc-input
                              maxLength="3"
                              onBlur={(elm) => updateContactAreaCd('7', elm)}
                              name="phoneArea7"
                              limit="3n"
                              value={contact.phnCityAreaCd}
                            ></fmc-input>
                          </div>
                          <div className="fds-layout-grid__cell--span-8">
                            <fmc-input
                              name="phoneNumber7"
                              value={contact.phnNb}
                              maxLength="7"
                              onBlur={(elm) => updateContactPhoneNb('7', elm)}
                            ></fmc-input>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="fds-layout-grid__inner p-0 ">
                <div className="fds-layout-grid__cell--span-4">Cell Phone:</div>
                <div className="fds-layout-grid__cell--span-8">
                  <div className="fds-layout-gri p-0">
                    <div className="fds-layout-grid__inner p-0 ">
                      {Contacts.filter((contact) => contact.cntctCd === 4).map((contact) => (
                        <>
                          <div className="fds-layout-grid__cell--span-4">
                            <fmc-input
                              maxLength="3"
                              onBlur={(elm) => updateContactAreaCd('4', elm)}
                              name="phoneArea4"
                              limit="3n"
                              value={contact.phnCityAreaCd}
                            ></fmc-input>
                          </div>
                          <div className="fds-layout-grid__cell--span-8">
                            <fmc-input
                              name="phoneNumber4"
                              value={contact.phnNb}
                              maxLength="7"
                              onBlur={(elm) => updateContactPhoneNb('4', elm)}
                            ></fmc-input>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="fds-layout-grid__inner p-0 ">
                <div className="fds-layout-grid__cell--span-4">Primary Text Phone:</div>
                <div className="fds-layout-grid__cell--span-8">
                  <div className="fds-layout-gri p-0">
                    <div className="fds-layout-grid__inner p-0 ">
                      {Contacts.filter((contact) => contact.cntctCd === 5).map((contact) => (
                        <>
                          <div className="fds-layout-grid__cell--span-4">
                            <fmc-input
                              maxLength="3"
                              onBlur={(elm) => updateContactAreaCd('5', elm)}
                              name="phoneArea5"
                              limit="3n"
                              value={contact.phnCityAreaCd}
                            ></fmc-input>
                          </div>
                          <div className="fds-layout-grid__cell--span-8">
                            <fmc-input
                              name="phoneNumber5"
                              value={contact.phnNb}
                              maxLength="7"
                              onBlur={(elm) => updateContactPhoneNb('5', elm)}
                            ></fmc-input>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="fds-layout-grid__inner p-0 ">
                <div className="fds-layout-grid__cell--span-4">Additional Phones:</div>
                <div className="fds-layout-grid__cell--span-8">
                  {Contacts.filter((c) => [6, 8, 9, 10].includes(c.cntctCd)).map((contact) => (
                    <>
                      <div className="fds-layout-gri p-0">
                        <div className="fds-layout-grid__inner p-0 ">
                          <div className="fds-layout-grid__cell--span-8">
                            <select
                              slot="input"
                              name={'phoneSelect' + contact.cntctCd}
                              status="standard"
                              value={contact.cntctCd}
                              className="fmc-select"
                              required
                            >
                              <option label="" value=""></option>
                              <option label="Other Mobile Phone" key="6" value="6">
                                Other Mobile Phone
                              </option>
                              <option label="Other Text Phone" key="7" value="7">
                                Other Text Phone
                              </option>
                              <option label="Phone" key="8" value="8">
                                Phone
                              </option>
                              <option label="Fax Phone" key="9" value="9">
                                Fax Phone
                              </option>
                            </select>
                          </div>
                          <div className="fds-layout-grid__cell--span-4">
                            <fmc-input
                              name={'phoneArea' + contact.cntctCd}
                              maxLength="3"
                              value={contact.phnCityAreaCd}
                              onBlur={(elm) => updateContactAreaCd(contact.cntctCd, elm)}
                            ></fmc-input>
                          </div>
                        </div>
                      </div>
                      <fmc-input
                        name={'phoneNumber' + contact.cntctCd}
                        maxLength="7"
                        value={contact.phnNb}
                        onBlur={(elm) => updateContactPhoneNb(contact.cntctCd, elm)}
                      ></fmc-input>
                    </>
                  ))}
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>

        <div className="fds-layout-grid fmc-pa-3 dividerdiv">
          <div className="fds-layout-grid__inner">
            <div
              className="
      fds-layout-grid__cell--span-12
      fmc-pb-3
      fmc-pb-5-md
      fmc-divider--bottom fmc-divider--top
    "
            >
              <div className="fds-weight--bold"></div>
              <div className="fds-layout-gri p-0">
                <div className="fds-layout-grid__inner p-0 "></div>
              </div>
              <div className="fds-layout-gri p-0">
                <div className="fds-layout-grid__inner p-0 ">
                  <div className="fds-layout-grid__cell--span-6">
                    <div className="fds-layout-gri p-0">
                      <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-4">Effective Info:</div>
                        <div className="fds-layout-grid__cell--span-6">{CntrPersonalDetail.effDt}</div>
                      </div>
                    </div>
                  </div>
                  <div className="fds-layout-grid__cell--span-6">
                    <div className="fds-layout-gri p-0">
                      <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-4">Expires:</div>
                        <div className="fds-layout-grid__cell--span-6">{CntrPersonalDetail.expirDt}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="fds-layout-grid__cell--span-12 fmc-pt-1-md fmc-divider--bottom">
              <div className="fds-layout-gri p-0">
                <div className="fds-layout-grid__inner p-0 "></div>
              </div>
              <div className="fds-layout-gri p-0">
                <div className="fds-layout-grid__inner p-0 ">
                  <div className="fds-layout-grid__cell--span-6">
                    <div className="fds-layout-gri p-0">
                      <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-4">Insurance Info:</div>
                        <div className="fds-layout-grid__cell--span-6">{CntrPersonalDetail.insInfoTx}</div>
                      </div>
                    </div>
                  </div>
                  <div className="fds-layout-grid__cell--span-6">
                    <div className="fds-layout-gri p-0">
                      <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-4">Expires:</div>
                        <div className="fds-layout-grid__cell--span-6">{CntrPersonalDetail.insInfoExpirDt}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br></br>
              <div className="fds-layout-gri p-0">
                <div className="fds-layout-grid__inner p-0 ">
                  <div className="fds-layout-grid__cell--span-6">
                    <div className="fds-layout-gri p-0">
                      <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-4">Business License:</div>
                        <div className="fds-layout-grid__cell--span-6">{CntrPersonalDetail.busLcnsTx}</div>
                      </div>
                    </div>
                  </div>
                  <div className="fds-layout-grid__cell--span-6">
                    <div className="fds-layout-gri p-0">
                      <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-4">Expires:</div>
                        <div className="fds-layout-grid__cell--span-6">{CntrPersonalDetail.busLcnsExpirDt}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {_.map(priceStructures, (priceStructure) => (
          <div className="fds-layout-grid fmc-pa-3 dividerdiv">
            <div className="fds-layout-grid__inner">
              <div className="fds-layout-grid__cell--span-12 fmc-pb-3 fmc-pb-5-md fmc-divider--bottom">
                <div className="fds-weight--bold"></div>
                <div className="fds-layout-gri p-0">
                  <div className="fds-layout-grid__inner p-0 "></div>
                  <p style={{ 'text-align': 'center' }}> Price structure Name :{priceStructure.prcStrctTx}</p>
                  <p>FEE DETAILS</p>
                  <br></br>
                  <div className="fds-layout-gri p-0">
                    <div className="fds-layout-grid__inner p-0 ">
                      <div className="fds-layout-grid__cell--span-6">
                        <div className="fds-layout-gri p-0">
                          <div className="fds-layout-grid__inner p-0 ">
                            <div className="fds-layout-grid__cell--span-4">
                              Base Repo Fee: <p>(Self- Help)</p>
                            </div>
                            <div className="fds-layout-grid__cell--span-6">{'$' + priceStructure.feeAm}</div>
                          </div>
                        </div>
                      </div>
                      <div className="fds-layout-grid__cell--span-6">
                        <input
                          type="checkbox"
                          checked={priceStructure.cntngntIn == 'C' ? true : false}
                          name="checkbox-item-3"
                          value="item-3"
                          disabled=""
                        />{' '}
                        Contingency
                      </div>
                    </div>
                  </div>
                  <div className="fds-layout-gri p-0">
                    <div className="fds-layout-grid__inner p-0 ">
                      <div className="fds-layout-grid__cell--span-6">
                        <div className="fds-layout-gri p-0">
                          <div className="fds-layout-grid__inner p-0 ">
                            <div className="fds-layout-grid__cell--span-4">
                              Base Repo Fee: <p>(Voluntary)</p>
                            </div>
                            <div className="fds-layout-grid__cell--span-6">{'$' + priceStructure.vlntrFeeAm}</div>
                          </div>
                        </div>
                      </div>

                      <div className="fds-layout-grid__cell--span-6">
                        <input
                          type="checkbox"
                          checked={priceStructure.dlvryToAuctIn == 'D' ? true : false}
                          name="checkbox-item-3"
                          value="item-3"
                          disabled=""
                        />
                        Delivery Auction
                      </div>
                    </div>
                  </div>
                  <div className="fds-layout-gri p-0">
                    <div className="fds-layout-grid__inner p-0 ">
                      <div className="fds-layout-grid__cell--span-6">
                        <div className="fds-layout-gri p-0">
                          <div className="fds-layout-grid__inner p-0 ">
                            <div className="fds-layout-grid__cell--span-4">
                              Base Repo Fee: <p>(Dealer Voluntary)</p>
                            </div>
                            <div className="fds-layout-grid__cell--span-6">{'$' + priceStructure.vlntrTodlrFeeAm}</div>
                          </div>
                        </div>
                      </div>
                      <div className="fds-layout-grid__cell--span-6">GVW Indicator= {priceStructure.gvwTypeCd}</div>
                    </div>
                  </div>
                  {priceStructure && priceStructure.clsFeeAm ? (
                    <div className="fds-layout-gri p-0">
                      <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-6">
                          <div className="fds-layout-gri p-0">
                            <div className="fds-layout-grid__inner p-0 ">
                              <div className="fds-layout-grid__cell--span-4">
                                Base Repo Fee: <p>(Close Fee)</p>
                              </div>
                              <div className="fds-layout-grid__cell--span-6">{'$' + priceStructure.clsFeeAm}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>

                {/*rendering the coverage area*/}
                <div className="fds-layout-grid__cell--span-12 fmc-pt-1-md">
                  <p className="center">COVERAGE AREA</p>
                  <div className="fds-weight--bold"></div>
                  <div className="fds-layout-gri p-0">
                    <div className="fds-layout-grid__inner p-0 "></div>
                    <div className="fds-layout-grid__cell--span-8">
                      {_.filter(GroupedCoverages, (value, prcStrctTx) => prcStrctTx === priceStructure.prcStrctTx).map(
                        (psCoverage, prcStrctTx) =>
                          _.map(psCoverage, (cov, stateOrProv) => (
                            <>
                              <p>
                                <strong>{stateOrProv}</strong>
                              </p>
                              <p>
                                {_.map(cov, (v, idx) => (
                                  <>{v.countyOrPC + ', '}</>
                                ))}
                              </p>
                            </>
                          ))
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        <section>
          <div className="center">
            <fmc-button label="Update" onClick={update}></fmc-button>
          </div>
        </section>
      </div>
    </>
  );
}

export default Profile;
