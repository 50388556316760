import HoldAssignment from './Pages/HoldAssignment/HoldAssignment';
import NewAssignment from './Pages/NewAssignment/NewAssignment';
import Misc from './Pages/common/Misc';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import InvoicePage from './Pages/invoice/InvoicePage';
import Maintab from './Pages/common/Maintab';
import PersonalProprtySum from './Pages/Summarypage/PersonalProprtySum';
import Invoicesummary from './Pages/Summarypage/Invoicesummary';
import VCRsummary from './Pages/Summarypage/VCRsummary';
import ContractorProfile from './Pages/RepoAdmin/ContractorProfile/ContractorProfile';
import AddContractorProfile from './Pages/RepoAdmin/AddContractorProfile/AddContractorProfile';
import VehicleConditionReport from './Pages/VehicleConditionReport/VehicleConditionReport';
import AssignmentDetails from './Pages/AssignmentDetails/AssignmentDetails';
import OutstandingAssignment from './Pages/OutstandingAssignment/OutstandingAssignment';
import UserVerification from './Pages/RepoAdmin/UserVerification/UserVerification';
import LoginAuthorization from './Pages/LoginAuthorization/LoginAuthorization';
import { ViewCoverage } from './Pages/Coverage/ViewCoverage';
import { UpdateCoverage } from './Pages/Coverage/UpdateCoverage';
import ProfileUpdate from './Pages/ProfileUpdate/ProfileUpdate';
import AuthLoginCallback from './Components/AuthLoginCallback';
import { getUserInfoFromSession } from './Services/session.mgmt';
import PersonalProperty1 from './Pages/PersonalProperty/PersonalProperty';
import CancelAssignment from './Pages/CancelAssignment/CancelAssignment';
import Outstandingsummary from './Pages/Summarypage/Outstandingsummary';
import Profile from './Pages/Profile/Profile';
import MockUserInfo from './config/MockUserInfo';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== '/auth-callback') {
      const userInfo = getUserInfoFromSession();
      if (userInfo) {
        setIsAuthenticated(true);
      } else {
        const callbackUrl = `${window.location.origin}/auth-callback`;
        const authUrl = `${process.env.REACT_APP_GATEWAY_URL}/RepoWeb/auth/login?homePage=${encodeURIComponent(callbackUrl)}`;
        window.location.assign(authUrl);
      }
    }
  }, [location.pathname]);

  if (location.pathname !== '/auth-callback' && !isAuthenticated) {
    return <div>Loading... will redirect to authentication</div>;
  }
  return (
    <Routes>
      <Route path="/" element={<UserVerification />} />
      <Route path="/auth-callback" element={<AuthLoginCallback />} />
      <Route path="/login" element={<LoginAuthorization />} />
      <Route path="/newAssignment/:teamWrkBpiNb" element={<NewAssignment />} />
      <Route path="/cancelAssignment/:teamWrkBpiNb" element={<CancelAssignment />} />
      <Route path="/misc" element={<Misc />} />
      <Route path="/userVerification" element={<UserVerification />} />
      <Route path="/holdAssignment/:teamWrkBpiNb" element={<HoldAssignment />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/invoice" element={<InvoicePage />} />
      <Route path="/main/:teamWrkBpiNb" element={<Maintab />} />
      <Route path="/persopropsummary" element={<PersonalProprtySum />} />
      <Route path="/invoicesummary" element={<Invoicesummary />} />
      <Route path="/vcrsummary" element={<VCRsummary />} />
      <Route path="/contractorprofile" element={<ContractorProfile />} />
      <Route path="/addcontractorprofile" element={<AddContractorProfile />} />
      <Route path="/vcr/:teamWrkBpiNb" element={<VehicleConditionReport />} />
      <Route path="/personalProperty" element={<PersonalProperty1 />} />
      <Route path="/assignmentDetails/:teamWrkBpiNb" element={<AssignmentDetails />} />
      <Route path="/outstandingAssignment" element={<OutstandingAssignment />} />
      <Route path="/userVerification" element={<UserVerification />} />
      <Route path="/viewCoverage/:contractorNb" element={<ViewCoverage />} />
      <Route path="/updateCoverage/:contractorNb" element={<UpdateCoverage />} />
      <Route path="/updateProfile/:contractorNb" element={<ProfileUpdate />} />
      <Route path="/outstandingsummary/:teamWrkBpiNb" element={<Outstandingsummary />} />
      <Route path="/mockUserInfo" element={<MockUserInfo />} />
    </Routes>
  );
};

export default App;
