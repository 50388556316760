import Header from '../../Components/Header/Header';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { coverageService } from '../../Services/CoverageService';
import { getUserInfoFromSession } from '../../Services/session.mgmt';
import _ from 'lodash';
import { profileService } from '../../Services/ProfileService';
import Footer from '../../Components/Footer/Footer';
import '@ford/ford-design-system/dist/web-components.js';

export function UpdateCoverage() {
  const [stateProvSelected, setStateProvSelected] = useState(true);
  const [countyOrPCSelected, setCountyOrPCSelected] = useState(false);
  const [priceStrct, setPriceStrct] = useState([]);
  const [stateOrProvince, setStateOrProvince] = useState([]);
  const [countyOrPC, setCountyOrPC] = useState([]);
  const [stateOrProvinceSelected, setStateOrProvinceSelected] = useState();
  const [priceStrctSelected, setPriceStrctSelected] = useState();
  const [personalDetail, setPersonalDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const countyOrPCRef = useRef();
  const nav = useNavigate();

  const { contractorNb } = useParams();
  const userInfo = getUserInfoFromSession();
  const cellStyles = {
    border: '1px solid black',
    verticalAlign: 'top',
  };

  useEffect(() => {
    const fetchPriceStructor = async () => {
      try {
        const priceStructResp = await coverageService.getPriceStructByContractorNb(contractorNb);
        setPriceStrct(priceStructResp.data);
        setPriceStrctSelected(priceStructResp.data[0].prcStrctNb);
        const contractPersonalResp = await profileService.getContractorPersonal(contractorNb);
        setPersonalDetail(contractPersonalResp.data.result.cntrctrData2ApiList[0]);
      } catch (err) {
        console.error('Failed to fetch coverage with id' + err.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchStateOrProvinces = async () => {
      try {
        const stateOrProviceResponse = await coverageService.getStateOrProvinceByStAbbr(userInfo.countryCd);
        setStateOrProvince(stateOrProviceResponse);
      } catch (err) {
        console.error('Failed to fetch coverage with id' + err.message);
      }
    };
    console.log('countryCode : [' + userInfo.countryCd + ']');
    fetchPriceStructor();
    fetchStateOrProvinces();
  }, []);

  const renderStateOrProvn = async (evn) => {
    console.log('StateOrProvince' + evn.target.value);
    //get selected pc or county
    try {
      const [countyOrPCs, selectedCountyOrPCs] = await Promise.all([
        coverageService.getCountyOrPCByStAbbr(evn.target.value, userInfo.countryCd),
        coverageService.getSelectedCountyOrPCs(userInfo.countryCd, contractorNb, evn.target.value),
      ]);
      const mergedCountyOrPCs = countyOrPCs.map((cp) => {
        return {
          ...cp,
          exists: _.filter(
            selectedCountyOrPCs,
            (cntPc) => cntPc.countyOrPc === cp.value && cntPc.prcStrctNb === parseInt(priceStrctSelected)
          ).length,
          disabled:
            _.filter(
              selectedCountyOrPCs,
              (cntPc) => cntPc.countyOrPc === cp.value && cntPc.prcStrctNb !== parseInt(priceStrctSelected)
            ).length !== 0,
        };
      });
      setCountyOrPC(mergedCountyOrPCs);
      setStateProvSelected(false);
      setCountyOrPCSelected(true);
      setStateOrProvinceSelected(evn.target.value);
    } catch (err) {
      console.error('Failed to fetch coverage with id' + err.message);
    }
  };

  function handleSelectPriceStruct(ent) {
    setPriceStrctSelected(ent.target.value);
    setStateProvSelected(true);
    setCountyOrPCSelected(false);
  }

  const handleGetCounties = async () => {
    const currentSelectedState = document.getElementById('stateOrProvCodesId').value;
    setStateOrProvinceSelected(currentSelectedState);
    try {
      const [countyOrPCs, selectedCountyOrPCs] = await Promise.all([
        coverageService.getCountyOrPCByStAbbr(currentSelectedState, userInfo.countryCd),
        coverageService.getSelectedCountyOrPCs(userInfo.countryCd, contractorNb, currentSelectedState),
      ]);
      const mergedCountyOrPCs = countyOrPCs.map((cp) => {
        return {
          ...cp,
          exists: _.filter(
            selectedCountyOrPCs,
            (cntPc) => cntPc.countyOrPc === cp.value && cntPc.prcStrctNb === parseInt(priceStrctSelected)
          ).length,
          disabled:
            _.filter(
              selectedCountyOrPCs,
              (cntPc) => cntPc.countyOrPc === cp.value && cntPc.prcStrctNb !== parseInt(priceStrctSelected)
            ).length !== 0,
        };
      });
      setCountyOrPC(mergedCountyOrPCs);
      setStateProvSelected(false);
      setCountyOrPCSelected(true);
    } catch (err) {
      console.error('Failed to fetch coverage with id' + err.message);
    }
  };

  const handleSelectAllCountyOrPC = () => {
    _.forEach(countyOrPCRef.current.querySelectorAll('input[type="checkbox"]'), (chxElm) => {
      if (!chxElm.disabled) {
        chxElm.checked = true;
      }
    });
  };

  const handleClearCountyOrPC = () => {
    _.forEach(countyOrPCRef.current.querySelectorAll('input[type="checkbox"]'), (chxElm) => {
      if (!chxElm.defaultChecked && !chxElm.disabled) {
        chxElm.checked = false;
      }
    });
  };
  const toggleCountyOrPC = () => {
    _.forEach(countyOrPCRef.current.querySelectorAll('input[type="checkbox"]'), (chxElm) => {
      if (!chxElm.disabled) {
        chxElm.checked = !chxElm.checked;
      }
    });
  };
  const handleUpdateCountyOrPC = async () => {
    //all county or PC from Reference
    try {
      const countyOrPCs = _.chain(countyOrPCRef.current.querySelectorAll('input[type="checkbox"]'))
        .filter((chxInputElm) => chxInputElm.checked)
        .map((inputElm) => inputElm.value)
        .value();
      const response = await coverageService.updateCountyOrPC(
        userInfo.countryCd,
        contractorNb,
        stateOrProvinceSelected,
        priceStrctSelected,
        countyOrPCs,
        userInfo.userid
      );
      console.log('UpSertCoverages :: ' + response.data);
    } catch (err) {
      console.log('upSertCountyOrPCs failed:: ' + err);
    }
    setUpdateSuccess(true);
  };
  const handleCancelCountyOrPC = () => {
    _.forEach(countyOrPCRef.current.querySelectorAll('input[type="checkbox"]'), (chxElm) => {
      chxElm.checked = chxElm.defaultChecked;
    });
    nav('/contractorprofile');
  };
  const handleBackOrCancel = () => {
    nav('/contractorprofile');
  };

  if (loading) return <div>Loading...</div>;
  return (
    <div ref={countyOrPCRef}>
      <Header heading="Update Coverage Area" />
      <div className="details_cntr">
        {updateSuccess ? (
          <div data-testid="updateSuccessTestId" className="fmc-alert fmc-alert--success fmc-mb-3" role="alert">
            <p>Coverage area modified successfully</p>
            <fmc-button name="Back" label="Back" onClick={handleBackOrCancel} />
          </div>
        ) : (
          <>
            <table style={{ width: '100%', border: '2px solid black' }} className="fmc-table">
              <tbody>
                <tr style={cellStyles}>
                  <td style={cellStyles}>Company name:</td>
                  <td style={cellStyles}>{personalDetail.cntrctrBusNm}</td>
                </tr>
                <tr style={cellStyles}>
                  <td style={cellStyles}>Price Structure Name:</td>
                  <td style={cellStyles}>
                    <select
                      name="prcStrct"
                      id="price-structure"
                      className="fmc-select"
                      data-testid="priceStructureSelectedTestId"
                      onChange={handleSelectPriceStruct}
                    >
                      {priceStrct.map((prSt) => (
                        <option key={prSt.prcStrctNb} value={prSt.prcStrctNb}>
                          {prSt.prcStrctTx}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
                {stateProvSelected ? (
                  <tr id="provTr" style={cellStyles}>
                    <td style={cellStyles}>{userInfo.countryCd === 'USA' ? 'State' : 'Province'} :</td>
                    <td style={cellStyles}>
                      <select
                        name="stateOrProvCodes"
                        data-testid="stateProvSelectedTestId"
                        id="stateOrProvCodesId"
                        size="1"
                        className="fmc-select"
                        onChange={renderStateOrProvn}
                      >
                        {stateOrProvince?.map((sp) => (
                          <option key={sp.stAbbrCd} value={sp.stAbbrCd}>
                            {sp.stAbbrCd}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                ) : null}
                {countyOrPCSelected ? (
                  <tr style={cellStyles}>
                    <td style={cellStyles}>{userInfo.countryCd === 'USA' ? 'Counties ' : 'Postal Codes '} :</td>
                    <td style={cellStyles}>
                      <div className="content" data-testid="countyOrPCSelectedTestId">
                        {countyOrPC?.map((cp) => (
                          <div key={cp.value}>
                            <label>
                              <input
                                type="checkbox"
                                value={cp.value}
                                defaultChecked={cp.exists !== 0}
                                disabled={cp.disabled}
                              />
                              {cp.label}
                            </label>
                          </div>
                        ))}
                      </div>
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
            <br />
            <br />
            {stateOrProvinceSelected ? (
              <div style={{ textAlign: 'center' }}>
                <section>
                  <fmc-button
                    name="Select All"
                    label="Select All"
                    data-testid="selectAllBtnTestId"
                    onClick={handleSelectAllCountyOrPC}
                  />
                  &nbsp;&nbsp;&nbsp;
                  <fmc-button name="Clear" label="Clear" data-testid="clearBtnTestId" onClick={handleClearCountyOrPC} />
                  &nbsp;&nbsp;&nbsp;
                  <fmc-button name="Toggle" label="Toggle" data-testid="toggleBtnTestId" onClick={toggleCountyOrPC} />
                </section>
                <br />
                <section>
                  <fmc-button
                    name="Update"
                    label="Update"
                    data-testid="updateButtonTestId"
                    onClick={handleUpdateCountyOrPC}
                  />
                  &nbsp;&nbsp;&nbsp;
                  <fmc-button name="Cancel" label="Cancel" onClick={handleCancelCountyOrPC} />
                </section>
              </div>
            ) : (
              <div style={{ textAlign: 'center' }}>
                <fmc-button
                  name="Get Counties"
                  label="Get Counties"
                  data-testid="getCountyButtonTestId"
                  onClick={handleGetCounties}
                />
                &nbsp;&nbsp;&nbsp;
                <fmc-button
                  name="Cancel"
                  label="Cancel"
                  data-testid="cancelButtonTestId"
                  onClick={handleCancelCountyOrPC}
                />
              </div>
            )}
          </>
        )}
      </div>
      <Footer />
    </div>
  );
}
