import serviceAxiosInstance from '../config/api.service.config';
import { ENDPOINTS } from '../constants/endpoints';

export function savePPDetails(ppDetails) {
  return serviceAxiosInstance.post(ENDPOINTS['SAVE_PP_DETAILS'], ppDetails);
}
export function savePPLocStat(ppLocStat) {
  return serviceAxiosInstance.post(ENDPOINTS['SAVE_PP_LOC_STAT'], ppLocStat);
}
export function deletePsnlPropDetailByTeamWrkBpiNb(teamWrkBpiNb) {
  return serviceAxiosInstance.delete(ENDPOINTS['SAVE_PP_DETAILS'] + `/${teamWrkBpiNb}`);
}
