import React from 'react';
import { useEffect, useRef, useState } from 'react';
import '@ford/ford-design-system/dist/web-components.js';

const TextAreaCharCount = ({ formData: initFormData, maxChar, onUpdate }) => {
  const [localTextArea, setLocalTextArea] = useState(initFormData);
  const inputRef = useRef();

  useEffect(() => {
    const handleTextUpdate = (e) => {
      const { value } = e.target;
      console.log('textArea in: ' + value);
      const updatedText = {
        ...localTextArea,
        text: value,
      };
      setLocalTextArea(updatedText);
      onUpdate(updatedText);
    };

    const element = inputRef.current;

    element.addEventListener('fmc-input-focused', handleTextUpdate);

    return () => {
      element.removeEventListener('fmc-input-focused', handleTextUpdate);
    };
  }, []);

  return (
    <fmc-control slug={initFormData.fieldName + '-id'} message="Update..." status="standard" fixed>
      <fmc-textarea
        data-testid={initFormData.fieldName + 'TextArea'}
        ref={inputRef}
        required
        rows="6"
        slot="input"
        name="text"
        status="standard"
        value={initFormData.text}
      />
      <fmc-count slot="count" message="characters remaining." limit={maxChar} validate-immediately></fmc-count>
    </fmc-control>
  );
};

export default TextAreaCharCount;
