import serviceAxiosInstance from '../config/api.service.config';
import { ENDPOINTS } from '../constants/endpoints';

export function getPostalCodeData(contractorNb) {
  return serviceAxiosInstance.get(ENDPOINTS['GET_PC_DATA'] + '/' + contractorNb);
}

export function getCountyData(contractorNb) {
  return serviceAxiosInstance.get(ENDPOINTS['GET_COUNTY_DATA'] + '/' + contractorNb);
}

export function getPriceStructByContractorNb(contractorNb) {
  return serviceAxiosInstance.get(ENDPOINTS['GET_PRICE_STRUCT'] + '/' + contractorNb);
}

export function getCountiesByStAbbrCd(stAbbrCd) {
  return serviceAxiosInstance.get(ENDPOINTS['GET_COUNTY_META'] + '/' + stAbbrCd);
}

export function getPostalCodeByStAbbrCd(stAbbrCd) {
  return serviceAxiosInstance.get(ENDPOINTS['GET_CA_PC_META'] + '/' + stAbbrCd);
}

export function getSelectedCountiesByStAbbrCd(contractorNb, stAbbrCd) {
  return serviceAxiosInstance.get(ENDPOINTS['GET_SELECT_COVERAGE_COUNTY'] + '/' + contractorNb + '-' + stAbbrCd);
}

export function getSelectedPostalCodeByStAbbrCd(contractorNb, stAbbrCd) {
  return serviceAxiosInstance.get(ENDPOINTS['GET_SELECT_COVERAGE_CA_PC'] + '/' + contractorNb + '-' + stAbbrCd);
}

export function getStates() {
  return serviceAxiosInstance.get(ENDPOINTS['GET_STATES_META']);
}

export function getProvinces() {
  return serviceAxiosInstance.get(ENDPOINTS['GET_PROVINCE_META']);
}

export function createCounty(cntrctrNb, countyCode, stateOrProvn, userId, prcStrctNb) {
  return serviceAxiosInstance.post(ENDPOINTS['UPDATE_COVERAGE_USA'], {
    cntrctrNb: cntrctrNb,
    cntyCd: countyCode,
    stAbbrCd: stateOrProvn,
    lstUpdtUserId: userId,
    prcStrctNb: prcStrctNb,
  });
}

export function createPostalCode(cntrctrNb, zipCode, stateOrProvn, userId, prcStrctNb) {
  return serviceAxiosInstance.post(ENDPOINTS['UPDATE_COVERAGE_CA'], {
    cntrctrNb: cntrctrNb,
    zipPrefxCd: zipCode,
    stAbbrCd: stateOrProvn,
    lstUpdtUserId: userId,
    prcStrctNb: prcStrctNb,
  });
}

export function deleteCACoverage(cntrctrNb, stateOrProvn, prcStrctNb) {
  return serviceAxiosInstance.delete(
    ENDPOINTS['DELETE_COVERAGE_CA'] +
      '?cntrctrNb=' +
      cntrctrNb +
      '&stAbbrCd=' +
      stateOrProvn +
      '&prcStrctNb=' +
      prcStrctNb
  );
}

export function deleteUSCoverage(cntrctrNb, stateOrProvn, prcStrctNb) {
  return serviceAxiosInstance.delete(
    ENDPOINTS['DELETE_COVERAGE_USA'] +
      '?cntrctrNb=' +
      cntrctrNb +
      '&stAbbrCd=' +
      stateOrProvn +
      '&prcStrctNb=' +
      prcStrctNb
  );
}
