//RepoWeb Change 16.4
export const PCKUP_TYPE_CD_REPO = 1;
export const PCKUP_TYPE_CD_TERM = 2;
export const FINANCIAL_LABEL_CD_LSE = '1';
export const FINANCIAL_LABEL_CD_RTL = '2';
export const DATE_FORMAT = 'MM/dd/yyyy';
export const CAN_POSTAL_PATTERN = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/i;
export const USA_ZIP_PATTERN = /^\d{5}(-\d{4})?$/;
export const ACTION_ACCEPT = 'Contractor Accepted Assignment';
export const ACTION_CANCEL = 'Contractor Acknowledge Cancel Assignment';
export const ACTION_HOLD = 'Contractor Acknowledge Hold Assignment';
export const ACTION_UPDATE = 'Contractor assignment update:';
export const ACTION_GROUNDING = 'Vehicle grounded - VCR created';

//constants used for DB2 VCR Image storing..
export const DOCTYPE_VCR = 'REPO_VCR';
export const CSCM_Web_API = 'CSCMWebAPI';

export const REPO_WEB_ACTN = 'RepoWebActn';
export const REPO_WEB_GRNDG = 'RepoWebGrndg';
export const REPO_WEB_API = 'RepoWebAPI';
export const RELOCATE_AGENT_WITH_PARAM = 'FC_BP_CM_RelocateAgentWithParam';
export const RELOCATE_AGENT = 'FC_BP_CM_RelocateAgent';
export const BPM_API = 'BPMAPI';
export const RESPONSE_BODY_STRING = 'responseBodyString = ';
export const API_RETURN_HTTP_STATUS_CODE = 'HttpStatus Code=';

export const rsACCEPT = 'ACCEPT';
export const rsGROUNDED = 'GROUNDED';
export const rsDECLINE = 'DECLINE';
export const rsPAYMENT = 'PAYMENT';
export const rsUPDATE = 'Update';
export const rsDELETE = 'DELETE';
export const rsHOLD_CONFIRM = 'HOLD_CONFIRMATION';
export const rsCANCEL_CONFIRM = 'CANCEL_CONFIRMATION';

// Constants  to specify repoweb endpoint
export const API_ENDPOINT_UPDATE_CONTRACTOR_DATA = 'profileMaint/updateContractorData/';
export const API_ENDPOINT_GET_CONTRACTOR_DATA = 'profileMaint/getContractorData/';
export const API_ENDPOINT_GET_CONTRACTOR_PROFILES = 'profileMaint/getContractorProfiles/';
export const API_ENDPOINT_USA_CONTRACTOR_CVG = 'profileMaint/USAContractorCVG/';
export const API_ENDPOINT_CAN_CONTRACTOR_CVG = 'profileMaint/CANContractorCVG/';
export const API_ENDPOINT_GET_CONTRACTOR_CONTACT = 'profileMaint/getContractorContact/';
export const API_ENDPOINT_GET_PROFILE_CONTACT_INFO = 'profileMaint/getProfileContactInfo/';
export const API_ENDPOINT_CONTACT_CODES_OTHER = 'profileMaint/contactCodes/other';
export const API_ENDPOINT_CONTACT_CODES = 'profileMaint/contactCodes';

export const API_ENDPOINT_INSERT_CONTRACTOR_NUMBER_DETAILS = 'addContractorProfile/insertContractorNumberDetails';
export const API_ENDPOINT_IS_CONTRACTOR_AVAILABLE = 'addContractorProfile/isContractorAvailable';
export const API_ENDPOINT_INSERT_CONTRACTOR_ID_DETAILS = 'addContractorProfile/insertContractorIdDetails';
export const API_ENDPOINT_INSERT_CONTRACTOR_FEE_DETAILS = 'addContractorProfile/insertContractorFeeDetails';
export const API_ENDPOINT_INSERT_PERSONAL_DETAILS = 'addContractorProfile/insertPersonalDetails';
export const API_ENDPOINT_GET_CNTRCTR_SQNC_NB = 'addContractorProfile/getCntrctrSqncNb';

export const API_ENDPOINT_REPO_SECURITY_REPO_USER_ID = 'repoSecurity/isRepoUser/id?userId=';
export const API_ENDPOINT_REPO_USER_DETAIL = 'repoSecurity/isRepoUser/detail?userId=';
export const API_ENDPOINT_REPO_SECURITY_SITE_CODE_ID = 'repoSecurity/isRepoUser/sitecode?gsdbCd=';

export const API_ENDPOINT_GET_CONTRACTOR_ID_DETAILS = 'modifyContractorProfile/getContractorIdDetails/';
export const API_ENDPOINT_CONSTRUCT_CONTRACTOR_FEE_VECTOR = 'modifyContractorProfile/constructContractorFeeVector/';
export const API_ENDPOINT_GET_CONTRACTOR_PERSONAL_DETAILS = 'modifyContractorProfile/getContractorPersonalDetails/';

export const API_ENDPOINT_UPDATE_CONTRACTOR_FEE_U2 = 'modifyContractorProfileSave/updateContractorFeeU2';
export const API_ENDPOINT_UPDATE_CONTRACTOR_FEE_U = 'modifyContractorProfileSave/updateContractorFeeU';
export const API_ENDPOINT_UPDATE_CONTRACTOR_FEE_D = 'modifyContractorProfileSave/updateContractorFeeD';
export const API_ENDPOINT_SAVE_PERSONAL_DETAILS = 'modifyContractorProfileSave/savePersonalDetails';
export const API_ENDPOINT_DELETE_CAN_COVERAGE = 'modifyContractorProfileSave/deleteCANCoverage';
export const API_ENDPOINT_DELETE_USA_COVERAGE = 'modifyContractorProfileSave/deleteUSACoverage';
export const API_ENDPOINT_DELETE_CONTACT_DETAILS = 'modifyContractorProfileSave/deleteContactDetails';
export const API_ENDPOINT_DELETE_CONTRACTOR_ID_DETAILS = 'modifyContractorProfileSave/deleteContractorIdDetails';

export const API_ENDPOINT_COVERAGE_AREA_COUNTIES = 'viewCoverageArea/getCountiesData/';
export const API_ENDPOINT_COVERAGE_AREA_POSTAL_CODES = 'viewCoverageArea/getPostalCodesData/';

export const API_ENDPOINT_GET_PRC_STRCT_TX = 'modifyCoverageArea/getPrcStrctTx/';
export const API_ENDPOINT_UPDATE_USA_COVERAGE = 'modifyCoverageArea/updateUSACoverageData';
export const API_ENDPOINT_UPDATE_CAN_COVERAGE = 'modifyCoverageArea/updateCANCoverageData';
export const API_ENDPOINT_DELETE_USA_COVERAGE_DATA_STATE = 'modifyCoverageArea/deleteUSACoverageData';
export const API_ENDPOINT_DELETE_CAN_COVERAGE_DATA_STATE = 'modifyCoverageArea/deleteCANCoverageData';

export const API_ENDPOINT_VALIDATE_ZBPT150 = 'deleteContractor/validateZBPT150';
export const API_ENDPOINT_VALIDATE_ZBPT105 = 'deleteContractor/validateZBPT105';
export const API_ENDPOINT_DELETE_CONTRACTOR = 'deleteContractor/deleteContractor';

export const API_ENDPOINT_ADMIN_GET_CONTRACTOR_DATA = 'contractorRequestManager/getContractorData/';
export const API_ENDPOINT_GET_CONTRACTOR_NUMBER_FROM_BUSINESS_NAME =
  'contractorRequestManager/getContractorNumberFromBusinessName?cntrctrBusNm=';
export const API_ENDPOINT_GET_CONTRACTOR_NAME_FROM_CONTRACTOR_NUMBER =
  'contractorRequestManager/getContractorNameFromContractorNumber?cntrctrNb=';
export const API_ENDPOINT_GET_SPECIFIC_CONTRACTOR_TYPE_CODE = 'contractorRequestManager/getSpecificContractorTypeCode/';
export const API_ENDPOINT_GET_SUPER_BRANCH_CODES = 'contractorRequestManager/getSuperBranchCodes';
export const API_ENDPOINT_GET_CONTRACTOR_TYPE_CODES = 'contractorRequestManager/getContractorTypeCodes';
export const API_ENDPOINT_GET_CONTRACTOR_TYPE_CODE = 'contractorRequestManager/getContractorTypeCode/';
export const API_ENDPOINT_GET_COUNTIES = 'contractorRequestManager/getCounties/';
export const API_ENDPOINT_GET_SELECTED_COUNTY = 'contractorRequestManager/getSelectedCounty/';
export const API_ENDPOINT_GET_SELECTED_POSTAL_CODE = 'contractorRequestManager/getSelectedPostalCode/';
export const API_ENDPOINT_GET_POSTAL_CODE = 'contractorRequestManager/getPostalCode/';

export const API_ENDPOINT_GET_INVOICE_CODE = 'lookupMgr/getInvoiceCode?invoiceCode=';
export const API_ENDPOINT_GET_INVOICE_CODE_LIST = 'lookupMgr/getInvoiceCodeList?prodCode=';
export const API_ENDPOINT_GET_TIRE_CONDITION_CODES = 'lookupMgr/getTireConditionCodes';
export const API_ENDPOINT_GET_GENERAL_CONDITION_CODES = 'lookupMgr/getGeneralConditionCodes';
export const API_ENDPOINT_GET_INTERIOR_CONDITION_CODES = 'lookupMgr/getInteriorConditionCodes';
export const API_ENDPOINT_GET_GLASS_CONDITION_CODES = 'lookupMgr/getGlassConditionCodes';
export const API_ENDPOINT_GET_MECHANICAL_CONDITION_CODES = 'lookupMgr/getMechanicalConditionCodes';
export const API_ENDPOINT_GET_OVERALL_CONDITION_CODES = 'lookupMgr/getOverallConditionCodes';

export const API_ENDPOINT_GET_QUESTION = 'HomePage/getQuestion/';
export const API_ENDPOINT_GET_PERSONAL_PROPERTY_FUP = 'HomePage/getPersonalPropertyFUP/';
export const API_ENDPOINT_GET_SUMMARY = 'HomePage/getSummary/';

export const API_ENDPOINT_QUESTION_UPDATE_CNTRCT_ACCT = 'processQuestion/updateCntrctAcct?bpiNb=';
export const API_ENDPOINT_QUESTION_GROUND_COUNT = 'processQuestion/groundCount/';

export const API_ENDPOINT_PROPERTY_UPDATE_PRSN_PRPTY_FUP_STAT = 'processProperty/updatePrsnPrptyFupStat?bpiNb=';
export const API_ENDPOINT_PROPERTY_UPDATE_PRSN_PRPTY_STAT = 'processProperty/updatePrsnPrptyStat?bpiNb=';
export const API_ENDPOINT_PROPERTY_UPDATE_CNTL_TABLE = 'processProperty/updateCntlTable?bpiNb=';

export const API_ENDPOINT_GET_REPO_ASSIGNMENT_STATUS = 'DataMgr/getRepoAssignmentStatus/';
export const API_ENDPOINT_CONTRACTOR_UPDATE_EXPIRE = 'DataMgr/contractorUpdateExpire';
export const API_ENDPOINT_GET_COMMON_DATA = 'DataMgr/getCommonData/';
export const API_ENDPOINT_GET_REPO_COMMON_DATA = 'DataMgr/getRepoCommonData/';
export const API_ENDPOINT_GET_STALE_COUNT = 'DataMgr/getStaleCount/';
export const API_ENDPOINT_GET_STALE_COUNT_WITH_ACTN = 'DataMgr/getStaleCountWithActn/';
export const API_ENDPOINT_GET_ASSIGN_COUNT = 'DataMgr/getAssignCount/';

export const API_ENDPOINT_VCR = 'SqlMgr/VCR';
export const API_ENDPOINT_INSERT_C_PKUP_LOG = 'SqlMgr/insertCPkupLog';
export const API_ENDPOINT_INS_DB_VEHICLE_LOC = 'SqlMgr/insDbVehicleLoc';
export const API_ENDPOINT_PROPERTY_DETAIL = 'SqlMgr/DbPropertyDetail';
export const API_ENDPOINT__PROPERTY_LOC_STAT = 'SqlMgr/DbPropertyLocStat';
export const API_ENDPOINT_DB_INVOICE = 'SqlMgr/DbInvoice';
export const API_ENDPOINT_WF_CMT = 'SqlMgr/WFCmt';
export const API_ENDPOINT_UPDATE_ASSIGNMENT_DETAILS = 'SqlMgr/updateAssignmentDetails';
export const API_ENDPOINT_UPDATE_GROUNDING_INFO_NON_AUCTION = 'SqlMgr/updateGroundingInfo/nonAuction';
export const API_ENDPOINT_UPDATE_GROUNDING_INFO_AUCTION = 'SqlMgr/updateGroundingInfo/auction';
export const API_ENDPOINT_UPDATE_ASSIGNMENT = 'SqlMgr/updateAssignment';
export const API_ENDPOINT_GET_ASSIGNED_CNTRCTRS = 'SqlMgr/getAssignedCntrctrs/';
export const API_ENDPOINT_GET_CNTRCTR_INFO_FOR_COMMENTS = 'SqlMgr/getCntrctrInfoForComments/';
export const API_ENDPOINT_GET_CNTRCTR_PROFILE = 'SqlMgr/getCntrctrProfile/';
