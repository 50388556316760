import './Summary.css';
import { useEffect, useState } from 'react';
import { AssignmentService } from '../../Services/AssignmentService';
import { getUserInfoFromSession } from '../../Services/session.mgmt';

function VCRsummary({ teamWrkBpiNb }) {
  const [vcrData, setVcrData] = useState({});
  const [vehloc, setVehLoc] = useState({});
  const [assignmentDetails, setAddignmentDetails] = useState({});
  const userInfo = getUserInfoFromSession();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchVcr = async () => {
      try {
        const [VCRResponse, VehLocResponse, GroundingResponse, AssignmentDetailsResponse] = await Promise.all([
          AssignmentService.getVcrDetails(teamWrkBpiNb),
          AssignmentService.getVehLocDetails(teamWrkBpiNb),
          AssignmentService.getGroundingInfo(teamWrkBpiNb),
          AssignmentService.getAssignmentInfo(teamWrkBpiNb, userInfo.cntrctrNb),
        ]);
        setVcrData(VCRResponse.data.vcr);
        setVehLoc({
          vehlocTypCd: GroundingResponse?.data?.vehLocTypCd ?? '',
          auctCd: GroundingResponse?.data?.auctLocCd ?? '',
          auctDs: GroundingResponse?.data?.auctLocNm ?? '',
          teamWrkBpiNb: teamWrkBpiNb,
          addrLine1Tx: VehLocResponse?.data?.addrLine1Tx ?? '',
          addrLine2Tx: VehLocResponse?.data?.addrLine2Tx ?? '',
          cityNm: VehLocResponse?.data?.cityNm ?? '',
          custNm: GroundingResponse?.data?.custNm ?? '',
          stAbbrCd: VehLocResponse?.data?.stAbbrCd ?? '',
          zipCd: VehLocResponse?.data?.zipCd ?? '',
          phnCityAreaCd: VehLocResponse?.data?.phnCityAreaCd ?? '',
          phnNb: VehLocResponse?.data?.phnNb ?? '',
          lstUpdtUserId: GroundingResponse?.data?.lstUpdtUserId ?? '',
          vehLocNm: VehLocResponse?.data?.vehLocNm ?? '',
        });
        setAddignmentDetails(AssignmentDetailsResponse.data);
      } catch (e) {
        console.log('Error Fetching VCR Details: ' + e);
      } finally {
        setLoading(false);
      }
    };
    fetchVcr();
  }, []);
  return loading ? (
    <div>Loading</div>
  ) : (
    <div className="details_cntr_summmary">
      <div id="invoiceheader" className="center">
        VCR
      </div>
      <div id="persnprptbl_cntr">
        <section className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white">
          <section className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white">
            <div className="fds-layout-gri p-0">
              <div className="fds-layout-grid__inner p-0 ">
                <div className="fds-layout-grid__cell--span-6">CUSTOMER NAME: {vehloc.custNm} </div>
                <div className="fds-layout-grid__cell--span-6">ACCOUNT NO: {assignmentDetails[0].custAcctNb} </div>
              </div>
            </div>
          </section>

          <section className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white">
            <div className="fds-layout-gri p-0">
              <div className="fds-layout-grid__inner p-0 ">
                <div className="fds-layout-grid__cell--span-3">
                  Date of Recovery:
                  <br />
                  {vcrData?.rcvrDt}
                  <br />
                </div>
                <div className="fds-layout-grid__cell--span-1">
                  YEAR: {assignmentDetails[0].vehMdlYr}
                  <br />
                </div>
                <div className="fds-layout-grid__cell--span-1">MAKE: {assignmentDetails[0].makeDescTx}</div>
                <div className="fds-layout-grid__cell--span-2">
                  MODEL: {assignmentDetails[0].mdlDescTx}
                  <br />
                </div>
                <div className="fds-layout-grid__cell--span-4">
                  VEHICLE IDENTIFICATION NUMBER: {assignmentDetails[0].vinSerialNb}{' '}
                </div>
              </div>
            </div>
          </section>
          <section className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white">
            <div className="fds-layout-gri p-0">
              <div className="fds-layout-grid__inner p-0 ">
                <div className="fds-layout-grid__cell--span-6">
                  PREPARED BY(NAME OF THE EMPLOYEE): {vcrData?.prepByTx}
                </div>
                <div className="fds-layout-grid__cell--span-6">DATE PREPARED: {vcrData?.prepDt} </div>
              </div>
            </div>
          </section>
          <section className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white">
            <div className="fds-layout-gri p-0">
              <div className="fds-layout-grid__inner p-0 ">
                <div className="fds-layout-grid__cell--span-2">MILAGE : {vcrData?.vehMlgeNb}</div>
                <div className="fds-layout-grid__cell--span-4">IGNITION KEY NO: {assignmentDetails[0].keyIgntnNb}</div>
                <div className="fds-layout-grid__cell--span-2"> COLOR: {assignmentDetails[0].vehColorTx}</div>
                <div className="fds-layout-grid__cell--span-4">
                  General Condition:&nbsp;
                  {vcrData?.genCndtnCd === 1
                    ? 'CLEAN'
                    : vcrData?.genCndtnCd === 2
                      ? 'JUNK'
                      : vcrData?.genCndtnCd === 3
                        ? 'ROUGH'
                        : 'AVERAGE'}
                </div>
              </div>
            </div>
          </section>

          <section className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white">
            <div>
              <div className="fds-layout-grid p-0">
                <div className="fds-layout-grid__cell--span-12">
                  {/* Flex container to align the key and radio buttons horizontally */}
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: '20px' }}>KEYS:</div>
                    <fieldset className="fmc-check fmc-check--horizontal" style={{ display: 'flex', gap: '20px' }}>
                      <label>
                        <input type="radio" name="keysInd" value="Y" checked={vcrData?.keysInd === 'Y'} />
                        <span>YES</span>
                      </label>
                      <label>
                        <input type="radio" name="keysInd" value="N" checked={vcrData?.keysInd === 'N'} />
                        <span>NO</span>
                      </label>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white">
            <div className="fds-layout-gri p-0">
              <div className="fds-layout-grid__inner p-0 ">
                <div className="fds-layout-grid__cell--span-2"> ACCESSORIES:</div>
                <div className="fds-layout-grid__cell--span-2">
                  <div class="fmc-check">
                    <label>
                      <input
                        type="checkbox"
                        name="checkbox-item-1"
                        value="Auto Trans"
                        checked={vcrData?.accAtrnsIn === 'Y'}
                      />
                      <span className="r-1">Auto Trans</span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        className="disable-input"
                        name="checkbox-item-2"
                        value="A/C"
                        checked={vcrData?.accAcIn === 'Y'}
                      />
                      <span className="r-1">A/C</span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        name="checkbox-item-2"
                        value="Vinyl Roof"
                        checked={vcrData?.accVnlRfIn === 'Y'}
                      />
                      <span className="r-1">Vinyl Roof</span>
                    </label>
                  </div>
                </div>
                <div className="fds-layout-grid__cell--span-2">
                  <div class="fmc-check">
                    <label>
                      <input
                        type="checkbox"
                        name="checkbox-item-2"
                        value="P Seats"
                        checked={vcrData?.accPwrSeatIn === 'Y'}
                      />
                      <span className="r-1">P .Seats</span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        name="checkbox-item-1"
                        value="P windows"
                        checked={vcrData?.accPwrWinIn === 'Y'}
                      />
                      <span className="r-1">P .Windows</span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        name="checkbox-item-2"
                        value="FM Radio"
                        checked={vcrData?.accFmRadIn === 'Y'}
                      />
                      <span className="r-1">Radio</span>
                    </label>
                  </div>
                </div>
                <div className="fds-layout-grid__cell--span-4">
                  <div class="fmc-check">
                    <label>
                      <input
                        type="checkbox"
                        name="checkbox-item-2"
                        value="item-2"
                        checked={vcrData?.accAftcRdoIn === 'Y'}
                      />
                      <span className="r-1">CD</span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        name="checkbox-item-2"
                        value="item-2"
                        checked={vcrData?.accTiltIn === 'Y'}
                      />
                      <span className="r-1">Tilt Wheel</span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        name="checkbox-item-2"
                        value="item-2"
                        checked={vcrData?.accCrsCtlIn === 'Y'}
                      />
                      <span className="r-1">Cruise control</span>
                    </label>
                  </div>
                </div>
                <div className="fds-layout-grid__cell--span-2">
                  <div class="fmc-check">
                    <label>
                      <input
                        type="checkbox"
                        name="checkbox-item-1"
                        value="item-1"
                        checked={vcrData?.accDieselIn === 'Y'}
                      />
                      <span className="r-1">Diesel</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white">
            <div className="fds-layout-gri p-0">
              <div className="fds-layout-grid__inner p-0 ">
                <div className="fds-layout-grid__cell--span-2">
                  MECHANICAL
                  <br />
                  {vcrData?.mechCndtnCd === 1
                    ? 'GOOD'
                    : vcrData?.mechCndtnCd === 3
                      ? 'Average'
                      : vcrData?.mechCndtnCd === 2
                        ? 'Poor'
                        : ''}
                </div>
                <div className="fds-layout-grid__cell--span-3">
                  GLASS:
                  <br />
                  <div>
                    {' '}
                    {vcrData?.glsCndtnCd === 1
                      ? 'GOOD'
                      : vcrData?.glsCndtnCd === 2
                        ? 'SCRATCHED'
                        : vcrData?.glsCndtnCd === 3
                          ? 'BROKEN'
                          : ''}{' '}
                  </div>
                  <br />
                  INDICATOR LOCATION:
                  <br />
                  {vcrData?.glsBrknDs}
                </div>
                <div className="fds-layout-grid__cell--span-4">
                  {' '}
                  TIRES : <br />
                  Left Front:
                  {vcrData?.lfTireCndtnCd === 1
                    ? 'BALD'
                    : vcrData?.lfTireCndtnCd === 2
                      ? 'GOOD'
                      : vcrData?.lfTireCndtnCd === 3
                        ? 'NONE'
                        : vcrData?.lfTireCndtnCd === 4
                          ? 'AVERAGE'
                          : ''}{' '}
                  <br />
                  Left Rear:
                  {vcrData?.lrTireCndtnCd === 1
                    ? 'BALD'
                    : vcrData?.lrTireCndtnCd === 2
                      ? 'GOOD'
                      : vcrData?.lrTireCndtnCd === 3
                        ? 'NONE'
                        : vcrData?.lrTireCndtnCd === 4
                          ? 'AVERAGE'
                          : ''}{' '}
                  <br />
                  Right Front :
                  {vcrData?.rfTireCndtnCd === 1
                    ? 'BALD'
                    : vcrData?.rfTireCndtnCd === 2
                      ? 'GOOD'
                      : vcrData?.rfTireCndtnCd === 3
                        ? 'NONE'
                        : vcrData?.rfTireCndtnCd === 4
                          ? 'AVERAGE'
                          : ''}{' '}
                  <br />
                  Right Rear:
                  {vcrData?.rrTireCndtnCd === 1
                    ? 'BALD'
                    : vcrData?.rrTireCndtnCd === 2
                      ? 'GOOD'
                      : vcrData?.rrTireCndtnCd === 3
                        ? 'NONE'
                        : vcrData?.rrTireCndtnCd === 4
                          ? 'AVERAGE'
                          : ''}{' '}
                  <br />
                  Spare:
                  {vcrData?.spTireCndtnCd === 1
                    ? 'BALD'
                    : vcrData?.spTireCndtnCd === 2
                      ? 'GOOD'
                      : vcrData?.spTireCndtnCd === 3
                        ? 'NONE'
                        : vcrData?.spTireCndtnCd === 4
                          ? 'AVERAGE'
                          : ''}{' '}
                  <br />
                </div>
                <div className="fds-layout-grid__cell--span-3">
                  INTERIOR:
                  <br />
                  {vcrData?.intCndtnCd === 1
                    ? 'CLEAN'
                    : vcrData?.rrTireCndtnCd === 2
                      ? 'ROUGH'
                      : vcrData?.rrTireCndtnCd === 3
                        ? 'AVERAGE'
                        : ''}
                  <br />
                  Explain:
                  {vcrData?.intExplDs}
                </div>
              </div>
            </div>
          </section>

          <section className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white">
            <div className="fds-layout-gri p-0">
              <div className="fds-layout-grid__inner p-0 ">
                <div className="fds-layout-grid__cell--span-3">
                  {' '}
                  BODY: <br />
                  Body:{' '}
                  {vcrData?.ovrlBodyCndtnCd === 1
                    ? 'DENTED'
                    : vcrData?.ovrlBodyCndtnCd === 2
                      ? 'GOOD'
                      : vcrData?.ovrlBodyCndtnCd === 3
                        ? 'POOR'
                        : vcrData?.ovrlBodyCndtnCd === 4
                          ? 'RUSTED'
                          : vcrData?.ovrlBodyCndtnCd === 5
                            ? 'AVERAGE'
                            : ''}
                  <br />
                  Paint:{' '}
                  {vcrData?.ovrlPntCndtnCd === 1
                    ? 'DENTED'
                    : vcrData?.ovrlPntCndtnCd === 2
                      ? 'GOOD'
                      : vcrData?.ovrlPntCndtnCd === 3
                        ? 'POOR'
                        : vcrData?.ovrlPntCndtnCd === 4
                          ? 'RUSTED'
                          : vcrData?.ovrlPntCndtnCd === 5
                            ? 'AVERAGE'
                            : ''}{' '}
                  <br />
                  Bumper :{' '}
                  {vcrData?.ovrlBmprCndtnCd === 1
                    ? 'DENTED'
                    : vcrData?.ovrlBmprCndtnCd === 2
                      ? 'GOOD'
                      : vcrData?.ovrlBmprCndtnCd === 3
                        ? 'POOR'
                        : vcrData?.ovrlBmprCndtnCd === 4
                          ? 'RUSTED'
                          : vcrData?.ovrlBmprCndtnCd === 5
                            ? 'AVERAGE'
                            : ''}
                  <br />
                </div>
                <div className="fds-layout-grid__cell--span-3">
                  <div> Dent/Rust Location:</div>
                  <div> Driver Side:</div>
                  <div class="fmc-check">
                    <label>
                      <input
                        type="checkbox"
                        name="checkbox-item-2"
                        value="P. Seats"
                        checked={vcrData?.cndtnDsfQpIn === 'Y'}
                      />
                      <span className="r-1"> Front Quarter Panel</span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        name="checkbox-item-1"
                        value="item-1"
                        checked={vcrData?.cndtnDsfDrIn === 'Y'}
                      />
                      <span>Front Door</span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        name="checkbox-item-2"
                        value="item-2"
                        checked={vcrData?.cndtnDsrDrIn === 'Y'}
                      />
                      <span>Rear Door </span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        name="checkbox-item-2"
                        value="P Seats"
                        checked={vcrData?.cndtnDsrQpIn === 'Y'}
                      />
                      <span> Rear Quarter Panel</span>
                    </label>
                  </div>
                </div>

                <div className="fds-layout-grid__cell--span-3">
                  <div> Passenger Side:</div>
                  <div class="fmc-check">
                    <label>
                      <input
                        type="checkbox"
                        name="checkbox-item-2"
                        value="P Seats"
                        checked={vcrData?.cndtnPsfQpIn === 'Y'}
                      />
                      <span className="r-1">Front Quarter Panel</span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        name="checkbox-item-1"
                        value="item-1"
                        checked={vcrData?.cndtnPsfDrIn === 'Y'}
                      />
                      <span>Front Door</span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        name="checkbox-item-2"
                        value="item-2"
                        checked={vcrData?.cndtnPsrDrIn === 'Y'}
                      />
                      <span>Rear Door </span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        name="checkbox-item-2"
                        value="P Seats"
                        checked={vcrData?.cndtnPsrQpIn === 'Y'}
                      />
                      <span>Rear Quarter Panel</span>
                    </label>
                  </div>
                </div>
                <div className="fds-layout-grid__cell--span-1">
                  <div> Top</div>
                  <div class="fmc-check">
                    <label>
                      <input
                        type="checkbox"
                        name="checkbox-item-2"
                        value="item-2"
                        checked={vcrData?.cndtnHoodIn === 'Y'}
                      />
                      <span>Hood</span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        name="checkbox-item-2"
                        value="item-2"
                        checked={vcrData?.cndtnRoofIn === 'Y'}
                      />
                      <span>Roof </span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        name="checkbox-item-2"
                        value="item-2"
                        checked={vcrData?.cndtnTrnkIn === 'Y'}
                      />
                      <span>Trunk</span>
                    </label>
                  </div>
                </div>
                <div className="fds-layout-grid__cell--span-2">
                  <div>Bumper</div>
                  <div class="fmc-check">
                    <label>
                      <input
                        type="checkbox"
                        name="checkbox-item-1"
                        value="item-1"
                        checked={vcrData?.cndtnBumpfIn === 'Y'}
                      />
                      <span className="r-1">Front</span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        name="checkbox-item-1"
                        value="item-1"
                        checked={vcrData?.cndtnBumprIn === 'Y'}
                      />
                      <span className="r-1">Rear</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white">
            <div className="fds-layout-gri p-0">
              <div className="fds-layout-grid__inner p-0 ">
                <div className="fds-layout-grid__cell--span-12">REMARKS MISSING EQUIPMENT: {vcrData?.msgEquipDs} </div>
              </div>
            </div>
          </section>
          <section className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white">
            <div className="fds-layout-gri p-0">
              <div className="fds-layout-grid__inner p-0 ">
                <div className="fds-layout-grid__cell--span-12">
                  ESTIMATED INSURANCE DAMAGE AMOUNT: {vcrData?.dmgEstAm}{' '}
                </div>
              </div>
            </div>
          </section>
          <section className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white">
            <div className="fds-layout-gri p-0">
              <div className="fds-layout-grid__inner p-0 ">
                <div className="fds-layout-grid__cell--span-6">
                  VEHICLE RECOVERED FROM <br />
                  <div>
                    <fieldset class="fmc-check fmc-check--horizontal">
                      <label>
                        <input
                          type="radio"
                          name="vehRcvrdFromNm"
                          value="item-1"
                          checked={vcrData?.vehRcvrdFromNm === 'CUSTOMER'}
                        />
                        <span>Customer</span>
                      </label>

                      <label>
                        <input
                          type="radio"
                          name="vehRcvrdFromNm"
                          value="item-3"
                          checked={vcrData?.vehRcvrdFromNm !== 'CUSTOMER'}
                        />
                        <span>Other(SPECIFY)</span>
                      </label>
                      <label>
                        <input
                          type="text"
                          name="text-1"
                          value={vcrData?.vehRcvrdFromNm === 'CUSTOMER' ? '' : vcrData?.vehRcvrdFromNm}
                          disabled={vcrData?.vehRcvrdFromNm === 'CUSTOMER'}
                        />
                      </label>
                    </fieldset>
                  </div>
                </div>
                <div className="fds-layout-grid__cell--span-6 fds-flex__col-offset-3">
                  RECOVERED FROM :<br />
                  ADDRESS: {vcrData?.vehRcvrdAddrTx} <br />
                  CITY: {vcrData?.vehRcvrdCityNm} <br />
                  {userInfo.countryCd === 'CAN' ? 'PROVINCE' : 'STATE'}: {vcrData?.vehRcvrdStAbbrCd} <br />
                  {userInfo.countryCd === 'CAN' ? 'Postal code' : 'ZIP'}: {vcrData?.vehRcvrdZipCd} <br />
                </div>
              </div>
            </div>
          </section>
          <section className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white">
            <div className="fds-layout-gri p-0">
              <div className="fds-layout-grid__inner p-0 ">
                <div className="fds-layout-grid__cell--span-6">WAS RELEASE AND SIGNED BY CUSTOMER:</div>
                <div className="fds-layout-grid__cell--span-6">
                  <div>
                    <fieldset class="fmc-check fmc-check--horizontal">
                      <label>
                        <input type="radio" name="cstRlsIn" value="item-1" checked={vcrData?.cstRlsIn === 'Y'} />
                        <span>YES</span>
                      </label>

                      <label>
                        <input type="radio" name="cstRlsIn" value="item-3" checked={vcrData?.cstRlsIn === 'N'} />
                        <span>NO</span>
                      </label>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white">
            <div className="fds-layout-gri p-0">
              <div className="fds-layout-grid__inner p-0 ">
                <div className="fds-layout-grid__cell--span-6">
                  IF NO, WERE PROPER AUTHORITIES NOTIFIED? <br />
                  <div>
                    <fieldset class="fmc-check fmc-check--horizontal">
                      <label>
                        <input type="radio" name="athNtfyIn" value="NO" checked={vcrData?.athNtfyIn === 'N'} />
                        <span>NO IF NO EXPLAIN BELOW</span>
                      </label>

                      <label>
                        <input type="radio" name="athNtfyIn" value="item-3" checked={vcrData?.athNtfyIn === 'Y'} />
                        <span>YES</span>
                      </label>
                    </fieldset>
                  </div>
                </div>
                <div className="fds-layout-grid__cell--span-6">
                  AUTHORITIES NOTIFIED :<br />
                  NAME: {vcrData?.athNtfyNm ? vcrData?.athNtfyNm : ''} <br />
                  ADDRESS: {vcrData?.athNtfyAddrTx ? vcrData?.athNtfyAddrTx : ''} <br />
                  CITY: {vcrData?.athNtfyCityNm ? vcrData?.athNtfyCityNm : ''} <br />
                  {userInfo.countryCd === 'CAN' ? 'PROVINCE' : 'STATE'}:{' '}
                  {vcrData?.athNtfyStAbbrCd ? vcrData?.athNtfyStAbbrCd : ''} <br />
                  {userInfo.countryCd === 'CAN' ? 'Postal code' : 'ZIP'}:{' '}
                  {vcrData?.athNtfyZipCd ? vcrData?.athNtfyZipCd : ''} <br />
                </div>
              </div>
            </div>
          </section>

          <section className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white">
            <div className="fds-layout-gri p-0">
              <div className="fds-layout-grid__inner p-0 ">
                <div className="fds-layout-grid__cell--span-12">OTHER COMMENTS :</div>
                <div className="fds-layout-grid__cell--span-12"> {vcrData?.othCmtDs}</div>
              </div>
            </div>
          </section>
          <section className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white">
            <div className="fds-layout-gri p-0">
              <div className="fds-layout-grid__inner p-0 ">
                <div className="fds-layout-grid__cell--span-6">
                  VEHICLE LOCATED AT: <br />
                  <div>
                    <fieldset class="fmc-check fmc-check--vertical">
                      <label>
                        <input type="radio" name="vehlocTypCd" value="item-1" checked={vehloc.vehlocTypCd === 'A'} />
                        <span>AUCTION</span>
                        <span>{vehloc.auctDs}</span>
                      </label>
                      <label>
                        <input type="radio" name="vehlocTypCd" value="item-3" checked={vehloc.vehlocTypCd === 'C'} />
                        <span>CONTRACTOR</span>
                      </label>
                      <label>
                        <input type="radio" name="vehlocTypCd" value="item-1" checked={vehloc.vehlocTypCd === 'D'} />
                        <span>DEALER</span>
                      </label>

                      <label>
                        <input type="radio" name="vehlocTypCd" value="item-3" checked={vehloc.vehlocTypCd === 'O'} />
                        <span>OTHER</span>
                      </label>
                    </fieldset>
                  </div>
                  <br />
                </div>
                <div className="fds-layout-grid__cell--span-6">
                  <br />
                  <br />
                  NAME: {vehloc.vehLocNm} <br />
                  ADDRESS: {vehloc.addrLine1Tx} {vehloc.addrLine2Tx} <br />
                  CITY: {vehloc.cityNm} <br />
                  STATE/PROV: {vehloc.stAbbrCd} {vehloc.zipCd} <br />
                  PHONE: {vehloc.phnCityAreaCd} {vehloc.phnNb} <br />
                </div>
              </div>
            </div>
          </section>
        </section>
        <div className="para">
          <ul class="fmc-list">
            <li>
              {' '}
              Prior to recording the mileage reflected on the vehicle’s odometer consider the age , appearance and
              condition of the vehicle and look for indicator of mileage discrepancies such as oil change stickers
              ,repair work receipt, warranty book entries and odometer replacement stickers .Note any mileage
              discrepancies or inconsistencies in the Comments section.
            </li>
            <li>
              {' '}
              California Repossession :In accordance with Section 75005.115 of the Business and Professions Code , this
              condition report is a general assessment of the collateral and does not include all damage or missing
              parts.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default VCRsummary;
