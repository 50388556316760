import React, { useEffect, useState } from 'react';
import Header from '../../Components/Header/Header';
import InvoicePage from '../invoice/InvoicePage';
import AssignmentDetails from '../AssignmentDetails/AssignmentDetails';
import VehicleConditionReport from '../VehicleConditionReport/VehicleConditionReport';
import PersonalProperty from '../PersonalProperty/PersonalProperty';
import { useParams } from 'react-router-dom';
import { getUserInfoFromSession } from '../../Services/session.mgmt';
import './Maintab.css';
import { AssignmentService } from '../../Services/AssignmentService';

function Maintab() {
  const { teamWrkBpiNb } = useParams();
  const userInfo = getUserInfoFromSession();
  const [assignmentStatus, setAssignmentStatus] = useState('');
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(1); // State to track the active tab
  const [prodCd, setProdCd] = useState();

  // Fetch Assignment Status and Assignment Info
  useEffect(() => {
    const fetchAssignmentStatus = async () => {
      try {
        const repoAssignmentStatusInfo = await AssignmentService.getRepoAssignmentStatusInfo(
          teamWrkBpiNb,
          userInfo.cntrctrNb
        );
        console.log(repoAssignmentStatusInfo);
        const cntrctrActnCd = repoAssignmentStatusInfo.data.result.repoAssignmentStatus[0].cntrctrActnCd;
        const csrActnCd = repoAssignmentStatusInfo.data.result.repoAssignmentStatus[0].csrActnCd;
        if (cntrctrActnCd === 'H' || csrActnCd === 'H') {
          setAssignmentStatus('H');
        } else if (cntrctrActnCd === 'G' || csrActnCd === 'G') {
          setAssignmentStatus('G');
        } else if (cntrctrActnCd === 'A' || csrActnCd === 'A') {
          setAssignmentStatus('A');
        }

        // Fetch Assignment Info after Status
        const assignmentInfoResponse = await AssignmentService.getAssignmentInfo(teamWrkBpiNb, userInfo.cntrctrNb);
        setProdCd(assignmentInfoResponse.data[0].prodCd);
        setLoading(false);
      } catch (err) {
        console.log('Error Fetching Assignment Status: ' + err);
      }
    };
    fetchAssignmentStatus();
  }, []);

  // Function to handle tab switching
  const handleContinue = (tabCode) => {
    setActiveTab((prevTab) => (prevTab < 4 ? prevTab + 1 : tabCode)); // Go to next tab if less than 4
  };

  if (loading) {
    return (
      <>
        <Header heading="" />
        <br />
        Loading...
      </>
    );
  }

  return (
    <div>
      <Header heading="" />
      <div className="tabs">
        <h3 id="tablist-1"></h3>
        <div role="tablist" aria-labelledby="tablist-1" className="manual" style={{ textAlign: 'center' }}>
          <button
            id="tab-1"
            type="button"
            role="tab"
            aria-selected={activeTab === 1}
            aria-controls="tabpanel-1"
            onClick={() => setActiveTab(1)}
          >
            <span className="focus">Assignment Details</span>
          </button>
          <button
            id="tab-2"
            type="button"
            role="tab"
            aria-selected={activeTab === 2}
            aria-controls="tabpanel-2"
            onClick={() => setActiveTab(2)}
          >
            <span className="focus">VCR</span>
          </button>
          <button
            id="tab-3"
            type="button"
            role="tab"
            aria-selected={activeTab === 3}
            aria-controls="tabpanel-3"
            onClick={() => setActiveTab(3)}
          >
            <span className="focus">Invoice</span>
          </button>
          <button
            id="tab-4"
            type="button"
            role="tab"
            aria-selected={activeTab === 4}
            aria-controls="tabpanel-4"
            onClick={() => setActiveTab(4)}
          >
            <span className="focus">Personal Property</span>
          </button>
        </div>

        {/* Tab Panels */}
        <div id="tabpanel-1" role="tabpanel" aria-labelledby="tab-1" className={activeTab === 1 ? '' : 'is-hidden'}>
          <AssignmentDetails bpiNo={teamWrkBpiNb} onContinue={handleContinue} activeTab={activeTab} />
        </div>
        <div id="tabpanel-2" role="tabpanel" aria-labelledby="tab-2" className={activeTab === 2 ? '' : 'is-hidden'}>
          <VehicleConditionReport
            bpiNo={teamWrkBpiNb}
            onContinue={handleContinue}
            status={assignmentStatus}
            activeTab={activeTab}
          />
        </div>
        <div id="tabpanel-3" role="tabpanel" aria-labelledby="tab-3" className={activeTab === 3 ? '' : 'is-hidden'}>
          <InvoicePage
            bpiNo={teamWrkBpiNb}
            onContinue={handleContinue}
            status={assignmentStatus}
            activeTab={activeTab}
            prodCd={prodCd}
          />
        </div>
        <div id="tabpanel-4" role="tabpanel" aria-labelledby="tab-4" className={activeTab === 4 ? '' : 'is-hidden'}>
          <PersonalProperty
            bpiNo={teamWrkBpiNb}
            onContinue={handleContinue}
            status={assignmentStatus}
            activeTab={activeTab}
          />
        </div>
      </div>
      <div className="fmc-bottom-banner__content center copy-rightbanner">
        Copyright &copy; 2024 Ford Motor Credit Company. All Rights Reserved
      </div>
    </div>
  );
}

export default Maintab;
