import React from 'react';
import _ from 'lodash';
import BuyerInfo from './BuyerInfo';

function BuyerAssignmentInfo(props) {
  const {buyerCoBuyerInfo, assignmentDetails} = props;
  
  return(
  <>
    <BuyerInfo buyerInfo={buyerCoBuyerInfo} title="Buyer Information:"/>
    {buyerCoBuyerInfo?.coBuyers?.length > 0 && 
    <BuyerInfo buyerInfo={buyerCoBuyerInfo.coBuyers[0]} title="CoBuyer Information:"/>
    }
  <br />
  {_.map(assignmentDetails, (assignmentDetail) => (
  <section
    key={assignmentDetail.recvrMthdCdDs}
    className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
  >
    <div className="fds-weight--bold">Assignment Information:</div>
    <div className="fds-layout-grid p-0">
      <div className="fds-layout-grid__inner p-0 ">
        <div className="fds-layout-grid__cell--span-2">Type of surrender:</div>
        <div className="fds-layout-grid__cell--span-4">{assignmentDetail.recvrMthdCdDs}</div>
        <div className="fds-layout-grid__cell--span-1">Fee:</div>
        <div className="fds-layout-grid__cell--span-5">{assignmentDetail.acptCntrctrFeeAm}</div>
      </div>
    </div>
    <div className="fds-layout-grid p-0">
      <div className="fds-layout-grid__inner p-0 ">
        <div className="fds-layout-grid__cell--span-2">CSR Name:</div>
        <div className="fds-layout-grid__cell--span-4">{assignmentDetail.reqtUserNm}</div>
        <div className="fds-layout-grid__cell--span-1">Phone:</div>
        <div className="fds-layout-grid__cell--span-5">{assignmentDetail.reqtUserPhnNb}</div>
      </div>
    </div>
    <div className="fds-layout-grid p-0">
      <div className="fds-layout-grid__inner p-0 ">
        <div className="fds-layout-grid__cell--span-2">Special Instructions / Directions:</div>
        <div className="fds-layout-grid__cell--span-10">{assignmentDetail.pckupInstrTxt}</div>
      </div>
    </div>
  </section>
  ))}
  </>
  );
}

export default BuyerAssignmentInfo;