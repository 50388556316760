import './Label.css';

const Input = (props) => {
  return (
    <>
      <label className="boldLabel">{props.text}</label>
    </>
  );
};

export default Input;
