//import moment from "moment";

const reCurrency1 = /^\d+$/;
const reCurrency2 = /^\.\d$/;
const reCurrency3 = /^\.\d\d$/;
const reCurrency4 = /^\d+\.$/;
const reCurrency5 = /^\d+\.\d$/;
const reCurrency6 = /^\d+\.\d\d$/;
const reInteger = /^\d+$/;
const daysInMonth = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
const reCanZip = /^(?<full>(?<part1>[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1})(?:[ ](?=\d))?(?<part2>\d{1}[A-Z]{1}\d{1}))$/;
const reUsZip = /^\d{5}(-\d{4})?$/;

export const validatevcr = (VcrFromData, VehLocData, countryCd) => {
  let errorMessage = '';
  let repoDate = new Date();
  let daysDiff = '';
  let msg = '';
  // Switched to a Calendar date-picker field but have to break it into components.
  let vcr_dorm = VcrFromData.rcvrDt.substring(5, 7);
  let vcr_dord = VcrFromData.rcvrDt.substring(8, 10);
  let vcr_dory = VcrFromData.rcvrDt.substring(0, 4);

  let vcr_preparedate_month = VcrFromData.prepDt.substring(5, 7);
  let vcr_preparedate_day = VcrFromData.prepDt.substring(8, 10);
  let vcr_preparedate_year = VcrFromData.prepDt.substring(0, 4);

  console.log(vcr_dorm + '     ' + vcr_dord + '   ' + vcr_dory);
  if (!isDate(vcr_dorm, vcr_dord, vcr_dory)) {
    errorMessage += 'Date of Recovery is invalid.\n';
    repoDate = null;
  } else {
    //If the date is ,more than 29 days old then give a warning first time only.
    let enteredDate = new Date(vcr_dory, vcr_dorm - 1, vcr_dord);
    if (repoDate == null || repoDate.getTime() !== enteredDate.getTime()) {
      repoDate = enteredDate;
      daysDiff = Math.ceil((new Date().getTime() - repoDate.getTime()) / (1000 * 60 * 60 * 24));
      console.log(parseInt(vcr_dorm) - 1);
      if (daysDiff > 29) errorMessage += 'Recovery date exceeds 29 days.  Please check and re-enter.\n';
      daysDiff = Math.ceil((repoDate.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));
      //const isFuture = moment(repoDate).isAfter(moment());
      //console.log(isFuture)
      //if(isFuture)
      //    errorMessage += "Recovery Date is in the future.\n";
    }
  }

  if (VcrFromData.prepByTx.isEmpty || VcrFromData.prepByTx === '') {
    errorMessage += "Enter a name in the \'Prepared By\' field.\n";
  }
  if (!isDate(vcr_preparedate_month, vcr_preparedate_day, vcr_preparedate_year)) {
    errorMessage += 'Date of preparation is invalid.\n';
  } else {
    let enteredPrepDate = new Date(vcr_preparedate_year, vcr_preparedate_month - 1, vcr_preparedate_day);
    daysDiff = Math.ceil((enteredPrepDate.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));
    if (daysDiff > 0) errorMessage += 'Prepared Date is in the future.\n';
    let enteredRecoveryDate = new Date(vcr_dory, vcr_dorm - 1, vcr_dord);
    if (enteredRecoveryDate.getTime() > enteredPrepDate.getTime())
      errorMessage += 'Recovery Date is after the Prepared Date.\n';
  }

  if (!VcrFromData.vehMlgeNb && VcrFromData.vehMlgeNb !== 0) {
    errorMessage += 'Indicate mileage.\n';
  } else if (VcrFromData.vehMlgeNb < 0) {
    errorMessage += 'Mileage must be numeric and positive.\n';
  } else if (VcrFromData.vehMlgeNb > 999999) {
    errorMessage += 'Mileage must be less than 1000000.\n';
  }

  if (VcrFromData.genCndtnCd.isEmpty || VcrFromData.genCndtnCd === '') {
    errorMessage += 'Indicate the general condition\n';
  }
  if (VcrFromData.mechCndtnCd.isEmpty || VcrFromData.mechCndtnCd === '') {
    errorMessage += 'Indicate the mechanical condition\n';
  }

  if (VcrFromData.glsCndtnCd.isEmpty || VcrFromData.glsCndtnCd === '') {
    errorMessage += 'Indicate the glass condition\n';
  } else {
    if (VcrFromData.glsCndtnCd === 3) {
      if (VcrFromData.glsBrknDs === '') {
        errorMessage += 'Indicate location of broken glass.\n';
      }
    }
    if (VcrFromData.glsCndtnCd === 2) {
      if (VcrFromData.glsBrknDs === '') {
        errorMessage += 'Indicate location of scratched glass.\n';
      }
    }
  }

  if (VcrFromData.lfTireCndtnCd.isEmpty || VcrFromData.lfTireCndtnCd === '') {
    errorMessage += "Enter the condition of the \'Left Front\' tire.\n";
  }
  if (VcrFromData.lrTireCndtnCd.isEmpty || VcrFromData.lrTireCndtnCd === '') {
    errorMessage += "Enter the condition of the \'Left Rear\' tire.\n";
  }
  if (VcrFromData.rfTireCndtnCd.isEmpty || VcrFromData.rfTireCndtnCd === '') {
    errorMessage += "Enter the condition of the \'Right Front\' tire.\n";
  }
  if (VcrFromData.rrTireCndtnCd.isEmpty || VcrFromData.rrTireCndtnCd === '') {
    errorMessage += "Enter the condition of the \'Right Rear\' tire.\n";
  }
  if (VcrFromData.spTireCndtnCd.isEmpty || VcrFromData.spTireCndtnCd === '') {
    errorMessage += "Enter the condition of the \'Spare\' tire.\n";
  }

  if (VcrFromData.intCndtnCd.isEmpty || VcrFromData.intCndtnCd === '') {
    errorMessage += 'Indicate the condition of the interior.\n';
  } else {
    if (VcrFromData.intCndtnCd === 2) {
      if (VcrFromData.intExplDs.isEmpty || VcrFromData.intExplDs === '') {
        errorMessage += 'Explain the rough interior.\n';
      }
    } else {
      VcrFromData.intExplDs = 'NA';
    }
  }

  if (VcrFromData.ovrlBodyCndtnCd === '' || VcrFromData.ovrlBodyCndtnCd.isEmpty) {
    errorMessage += "Enter the condition of the \'body\'.\n";
  }
  if (VcrFromData.ovrlPntCndtnCd === '' || VcrFromData.ovrlPntCndtnCd.isEmpty) {
    errorMessage += "Enter the condition of the \'paint\'.\n";
  }
  if (VcrFromData.ovrlBmprCndtnCd === '' || VcrFromData.ovrlBmprCndtnCd.isEmpty) {
    errorMessage += "Enter the condition of the \'bumpers\'.\n";
  }

  if (VcrFromData.dmgEstAm.isEmpty || VcrFromData.dmgEstAm === '') {
    errorMessage += 'Enter an estimate of the damages.\n';
  } else {
    if (!isCurrency(VcrFromData.dmgEstAm)) {
      errorMessage += 'Damage Estimate is not a valid currency format.\n';
    }
  }

  if (VcrFromData.vehRcvrdFromNm === '' || VcrFromData.vehRcvrdFromNm.isEmpty) {
    errorMessage += 'Indicate who the vehicle was recovered from.\n';
  } else {
    if (VcrFromData.vehRcvrdFromNm === 'OTHER') {
      if (VcrFromData.vehRcvOthrSpcfy.isEmpty || VcrFromData.vehRcvOthrSpcfy === '') {
        errorMessage += 'Specify who (Other) the vehicle was recovered from.\n';
      }
    }
  }
  if (VcrFromData.vehRcvrdAddrTx === '' || VcrFromData.vehRcvrdAddrTx.isEmpty) {
    errorMessage += 'Enter the recovered from address.\n';
  }
  if (VcrFromData.vehRcvrdCityNm === '' || VcrFromData.vehRcvrdCityNm.isEmpty) {
    errorMessage += 'Enter the recovered from city.\n';
  }
  if (VcrFromData.vehRcvrdStAbbrCd === '' || VcrFromData.vehRcvrdStAbbrCd.isEmpty) {
    errorMessage += 'Enter the recovered from state.\n';
  }
  if (VcrFromData.vehRcvrdZipCd === '' || VcrFromData.vehRcvrdZipCd.isEmpty) {
    errorMessage += 'Enter the recovered from zip code.\n';
  } else {
    if (!isZIPCode(VcrFromData.vehRcvrdZipCd, countryCd)) {
      errorMessage += 'Recovered from zip code is invalid.\n';
    }
  }
  if (VcrFromData.cstRlsIn.isEmpty || VcrFromData.cstRlsIn === '') {
    errorMessage += 'Indicate if the release was signed by the customer.\n';
  } else {
    if (VcrFromData.cstRlsIn === 'N') {
      if (VcrFromData.cstRlsDs.isEmpty || VcrFromData.cstRlsDs === '') {
        errorMessage += 'Explain why the release was not signed by the customer.\n';
      }
      if (VcrFromData.athNtfyIn.isEmpty || VcrFromData.athNtfyIn === '') {
        errorMessage += 'Indicate whether the proper authorities were notified.\n';
      } else {
        if (VcrFromData.athNtfyIn === 'Y') {
          if (VcrFromData.athNtfyNm.isEmpty || VcrFromData.athNtfyNm === '') {
            errorMessage += 'Enter the authorities notified name.\n';
          }
          if (VcrFromData.athNtfyAddrTx.isEmpty || VcrFromData.athNtfyAddrTx === '') {
            errorMessage += 'Enter the authorities notified address.\n';
          }
          if (VcrFromData.athNtfyCityNm.isEmpty || VcrFromData.athNtfyCityNm === '') {
            errorMessage += 'Enter the authorities notified city.\n';
          }
          if (VcrFromData.athNtfyStAbbrCd.isEmpty || VcrFromData.athNtfyStAbbrCd === '') {
            errorMessage += 'Enter the authorities notified state.\n';
          }
          if (VcrFromData.athNtfyZipCd.isEmpty || VcrFromData.athNtfyZipCd === '') {
            errorMessage += 'Enter the authorities notified zip code.\n';
          } else {
            if (!isZIPCode(VcrFromData.athNtfyZipCd, countryCd)) {
              errorMessage += 'Authorities notified zip code is invalid.\n';
            }
          }
        } else {
          if (VcrFromData.othCmtDs.isEmpty || VcrFromData.othCmtDs === '') {
            errorMessage += "Please indicate, in \'Other Comments\', why the proper authorities were not notified.\n";
          } else {
            if (VcrFromData.othCmtDs.length > 255) {
              errorMessage += "\'Other Comments\' is too long, it can only be 255 characters";
              errorMessage += 'right now it is' + VcrFromData.othCmtDs.length + ' characters';
            }
          }
        }
      }
    }
  }
  // this is put in in case someone mistakenly fills in this area when it should be blank
  if (VcrFromData.othCmtDs.length > 255) {
    errorMessage += "\'Other Comments\' is too long, it can only be 255 characters";
    errorMessage += 'right now it is' + VcrFromData.othCmtDs.length + ' characters';
  }

  if (VehLocData.vehlocTypCd.isEmpty || VehLocData.vehlocTypCd === '') {
    errorMessage += 'Indicate the location of the vehicle.\n';
  } else {
    if (VehLocData.vehlocTypCd === 'A') {
      if (VehLocData.auctDs.isEmpty || VehLocData.auctDs === '') {
        errorMessage += 'Select the auction name.\n';
      }
    } else if (VehLocData.vehlocTypCd !== 'A') {
      if (VehLocData.vehLocNm.isEmpty || VehLocData.vehLocNm === '') {
        errorMessage += 'Vehicle Located At NAME is required.\n';
      }
      if (VehLocData.addrLine1Tx.isEmpty || VehLocData.addrLine1Tx === '') {
        errorMessage += 'Vehicle Located At ADDRESS LINE 1 is required.\n';
      }
      if (VehLocData.cityNm.isEmpty || VehLocData.cityNm === '') {
        errorMessage += 'Vehicle Located At CITY is required.\n';
      }
      if (VehLocData.stAbbrCd.isEmpty || VehLocData.stAbbrCd === '') {
        errorMessage += 'Vehicle Located At STATE is required.\n';
      }
      if (VehLocData.zipCd.isEmpty || VehLocData.zipCd === '') {
        errorMessage += 'Vehicle Located At ZIP CODE is required.\n';
      } else {
        if (!isZIPCode(VehLocData.zipCd, countryCd)) {
          errorMessage += 'A 5 digit Vehicle Located At ZIP CODE is required.\n';
        }
      }
      if (!checkPhoneArea(VehLocData.phnCityAreaCd) || !checkPhone(VehLocData.phnNb)) {
        errorMessage += 'Vehicle Located At Phone Number must be a 10 digit number.\n';
      }
    }
  }

  if (errorMessage !== '') {
    msg = 'The form was not submitted because the following corrections are required.\n';
    msg = msg + '***********************************\n\n';
    msg += errorMessage;
    // alert(msg);
    return msg;
  } else {
    return null;
  }
};

function checkPhoneArea(phoneAreaField) {
  let bReturn = true;
  let rePhoneAreaNumbers = /^([0-9])([0-9])([0-9])+$/;
  if (!(phoneAreaField.isEmpty || phoneAreaField === '')) {
    if (!rePhoneAreaNumbers.test(phoneAreaField)) {
      bReturn = false;
    }
  }
  return bReturn;
}

function checkPhone(phoneField) {
  let bReturn = true;
  let rePhoneNumbers = /^([0-9])([0-9])([0-9])([0-9])([0-9])([0-9])([0-9])+$/;
  if (!(phoneField.isEmpty || phoneField === '')) {
    if (!rePhoneNumbers.test(phoneField)) {
      bReturn = false;
    }
  }
  return bReturn;
}
function isIntegerInRange(s, a, b) {
  // if (!isInteger(s, false))
  // {
  //     return false;
  // }
  let num = parseInt(s);
  return num >= a && num <= b;
}
function isCurrency(s) {
  return (
    reCurrency1.test(s) ||
    reCurrency2.test(s) ||
    reCurrency3.test(s) ||
    reCurrency4.test(s) ||
    reCurrency5.test(s) ||
    reCurrency6.test(s)
  );
}
function isZIPCode(s, countryCd) {
  if (countryCd === 'CAN') return reCanZip.test(s);
  else return reUsZip.test(s); //(isInteger(s) && ( (s.length === 5) || (s.length === 9) ) )
}
function isInteger(s) {
  return reInteger.test(s);
}
function isMonth(s) {
  let newS = '';
  if (s[0] === '0') newS = s[1];
  else newS = s;
  return isIntegerInRange(newS, 1, 12);
}

function isDay(s) {
  let newS = '';
  if (s[0] === '0') newS = s[1];
  else newS = s;
  return isIntegerInRange(newS, 1, 31);
}

function isYear(s) {
  // if (!(s<0)) { return false; }
  return s.length === 4;
}
function daysInFebruary(year) {
  return year % 4 === 0 && (!(year % 100 === 0) || year % 400 === 0) ? 29 : 28;
}
function stripLeadingZerosFromIntString(s) {
  if (s === '') {
    return s;
  }
  let newS = '';
  let strippingLeadingZeros = true;
  for (let j = 0; j < s.length; j++) {
    if (s[j] === '0' && strippingLeadingZeros !== false) {
      continue;
    } else {
      strippingLeadingZeros = false;
      newS += s[j];
    }
  }
  return newS;
}
function isDate(month, day, year) {
  let newMonth = stripLeadingZerosFromIntString(month);
  let newDay = stripLeadingZerosFromIntString(day);
  let newYear = stripLeadingZerosFromIntString(year);
  console.log(newMonth + '  ' + newDay + '  ' + newYear);
  if (newMonth.length === 0 || newDay.length === 0 || newYear.length === 0) {
    console.log('fails here!!');
    return false;
  }

  if (!(isMonth(newMonth) && isDay(newDay) && isYear(newYear))) {
    console.log('fails here!!');
    return false;
  }

  let intMonth = parseInt(newMonth);
  let intDay = parseInt(newDay);
  let intYear = parseInt(newYear);

  if (intDay > daysInMonth[intMonth - 1]) {
    console.log('fails here!!');
    return false;
  }

  if (intMonth === 2 && intDay > daysInFebruary(intYear)) {
    console.log('fails here!!');
    return false;
  }
  return true;
}

function doOther_spec(s) {
  let vehRcvOthrSpcfyIn = false;
  vehRcvOthrSpcfyIn = s === 'c';
}
