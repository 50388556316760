import {
  getAssignmentInfo,
  getBuyerCobuyerInfo,
  getDbPropertyLocStat,
  getDbVehicleLoc,
  getGroundingInfo,
  getInvoiceDetails,
  getInvoiceTypes,
  getPersonalPropertyFUP,
  getPpDetails,
  getRepoAssignmentStatusInfo,
  getRepoAssignmentStatusInfoForMisc,
  getSummary,
  getVcrDetails,
  getVechileDsiposalInfo,
  getVehLocDetails,
  getContractorNumber,
  getContractorProfile,
} from '../api/assignment.api';

export const AssignmentService = {
  getContractorProfile: async (userId) => {
    return getContractorProfile(userId);
  },
  getContractorNumber: async (userId) => {
    return getContractorNumber(userId);
  },
  getAssignmentInfo: async (teamWrkBpiNb, cntrctrNb) => {
    return getAssignmentInfo(teamWrkBpiNb, cntrctrNb);
  },
  getRepoAssignmentStatusInfo: async (teamWrkBpiNb, cntrctrNb) => {
    return getRepoAssignmentStatusInfo(teamWrkBpiNb, cntrctrNb);
  },
  getRepoAssignmentStatusInfoForMisc: async (cntrctrNb) => {
    return getRepoAssignmentStatusInfoForMisc(cntrctrNb);
  },
  getPersonalPropertyFUP: async (cntrctrNb) => {
    return getPersonalPropertyFUP(cntrctrNb);
  },
  getSummary: async (cntrctrNb) => {
    return getSummary(cntrctrNb);
  },
  getBuyerCobuyerInfo: async (coBusCd,discorigBranchCd,custAcctNb) => {
    return getBuyerCobuyerInfo(coBusCd,discorigBranchCd,custAcctNb);
  },
  getVechileDsiposalInfo: async () => {
    return getVechileDsiposalInfo();
  },
  getVcrDetails: async (bpiNo) => {
    return getVcrDetails(bpiNo);
  },
  getInvoiceDetails: async (bpiNo) => {
    return getInvoiceDetails(bpiNo);
  },
  getVehLocDetails: async (bpiNo) => {
    return getVehLocDetails(bpiNo);
  },
  getGroundingInfo: async (bpiNo) => {
    return getGroundingInfo(bpiNo);
  },
  getDbVehicleLoc: async (bpiNo) => {
    return getDbVehicleLoc(bpiNo);
  },
  getDbPropertyLocStat: async (bpiNo) => {
    return getDbPropertyLocStat(bpiNo);
  },
  getPpDetails: async (bpiNo) => {
    return getPpDetails(bpiNo);
  },
  getInvoiceTypes: async (prodCd) => {
    return getInvoiceTypes(prodCd);
  },
};
