import Header from '../../Components/Header/Header';
import React, { useEffect, useState } from 'react';
import './NewAssignment.css';
import { postCall, putCall } from '../../Services/CommonService';
import { useNavigate, useParams } from 'react-router-dom';
import AssignmentDetailsHeader from '../common/AssignmentDetailsHeader';
import { getUserInfoFromSession } from '../../Services/session.mgmt';
import * as Constants from '../../constants/RepoReactConstants';
import { PINNCALE_LOG_OBJ } from '../../constants/commonJsonConstants';

import { CommonDataService, getcommentStr } from '../../Services/CommonDataService';
import { FcnaDB2Postcall } from '../../api/accountsummary.api';
import { AssignmentService } from '../../Services/AssignmentService';
import BuyerAssignmentInfo from '../../Components/BuyerAssignmentInfo';

function NewAssignment() {
  const [accountInformation, setAccountInformation] = useState([]);
  const [contractorInfoForComment, setContractorInfoForComment] = useState([]);

  const navigate = useNavigate();
  let { teamWrkBpiNb } = useParams();
  console.log(teamWrkBpiNb);

  const [assignmentDetails, setAssignmentDetails] = useState([]);
  const [buyerCoBuyerInfo, setBuyerCoBuyerInfo] = useState([]);
  const userInfo = getUserInfoFromSession();

  function getCurrentTimestamp() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // JavaScript months are 0-indexed
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  useEffect(() => {
    const fetchcommonInfo = async () => {
      try {
        Promise.all([
          CommonDataService.getAccountInfo(teamWrkBpiNb, userInfo.cntrctrNb),
          CommonDataService.getCntrctrInfoForComments(userInfo.userid),
          AssignmentService.getAssignmentInfo(teamWrkBpiNb, userInfo.cntrctrNb),
        ])
          .then(
            ([accountInfoResponse, contractorInfoForCommentResponse, assignmentResponse]) => {
              setAccountInformation(accountInfoResponse.data);
              setContractorInfoForComment(contractorInfoForCommentResponse.data);
              setAssignmentDetails(assignmentResponse.data);

              console.log(`getBuyerCobuyer Params coBusCd: ${accountInfoResponse?.data?.coBusCd} ` + 
              `disorigBranchCd: ${accountInfoResponse?.data?.discorigBranchCd} ` + 
              `custAcctNb: ${accountInfoResponse?.data?.custAcctNb}`)
              AssignmentService.getBuyerCobuyerInfo(accountInfoResponse?.data?.coBusCd, 
                accountInfoResponse?.data?.discorigBranchCd, 
                accountInfoResponse?.data?.custAcctNb)
                .then((buyerInfoResp) => {
                  console.log('getBuyerCobuyer response:', buyerInfoResp)
                  setBuyerCoBuyerInfo(buyerInfoResp?.data);
                })
                .catch((error) => {
                  console.error('AssignmentService.getBuyerCobuyerInfo error:: ', error);
                });
            }
          )
          .catch((error) => {
            console.error('An error occurred:', error);
          });
      } catch (error) {
        // Handle any errors that occur in any of the promises
        console.error('An error occurred:', error);
      }
    };
    fetchcommonInfo();
  }, [teamWrkBpiNb]);

  const logPinnacle = async (accountInformation, contractorInfoForComment, PINNCALE_LOG_OBJ) => {
    PINNCALE_LOG_OBJ.receivableAccountNumber = accountInformation.custAcctNb;
    PINNCALE_LOG_OBJ.coBusCd = accountInformation.coBusCd;
    PINNCALE_LOG_OBJ.createdTimeStamp = getCurrentTimestamp();
    PINNCALE_LOG_OBJ.enteredBranchCode = accountInformation.superBranchCd;
    PINNCALE_LOG_OBJ.createdBy = userInfo.userid;
    PINNCALE_LOG_OBJ.comment = getcommentStr(
      contractorInfoForComment,
      Constants.ACTION_ACCEPT,
      Constants.rsACCEPT,
      userInfo.userid
    );
    console.log(' after comment set:::::::::::::::::::', PINNCALE_LOG_OBJ);
    return CommonDataService.createPinnacleContact(PINNCALE_LOG_OBJ);
  };

  function Accept() {
    try {
      Promise.all([
        logPinnacle(accountInformation, contractorInfoForComment, PINNCALE_LOG_OBJ),
        putCall(
          'processQuestion/updateCntrctAcct?bpiNb=' +
            teamWrkBpiNb +
            '&cntrctrNb=' +
            userInfo.cntrctrNb +
            '&sConfirm=Y&sCsrAction=A'
        ),
        postCall(
          'SqlMgr/insertCPkupLog?actnAm=0.0&actnCd=3&bpiNb=' +
            teamWrkBpiNb +
            '&cntrctrNb=' +
            userInfo.cntrctrNb +
            '&userId=' +
            userInfo.userid
        ),
        FcnaDB2Postcall({
          acctNb: accountInformation?.custAcctNb,
          coBusCd: accountInformation?.coBusCd,
          transTypeCd: 21,
          sourceSystemCode: 12,
          lastUpdatedUserIdentificationCode: userInfo.userid,
          transactionDataText: teamWrkBpiNb + ';ACCEPT',
          isImmediate: true,
        }),
      ])
        .then(([pinnResp, putResp, postResp, fcnadb2Resp]) => {
          console.log('Pinnacle Response:', JSON.stringify(pinnResp));
          console.log('putCall Response:', JSON.stringify(putResp));
          console.log('postCall Response:', JSON.stringify(postResp));
          console.log('FcnaDB2Postcall Response:', JSON.stringify(fcnadb2Resp));
        })
        .catch((error) => {
          console.error('Error in Promise.all:', error);
        })
        .finally(() => {
          navigate('/outstandingAssignment');
        });
    } catch (error) {
      console.log('FcnaDB2Postcall Error: ' + error);
    }
  }

  function Decline() {
    putCall(
      'processQuestion/updateCntrctAcct?bpiNb=' +
        teamWrkBpiNb +
        '&cntrctrNb=' +
        userInfo.cntrctrNb +
        '&sConfirm=Y&sCsrAction=R'
    ).then((response) => console.log(response));
    postCall(
      'SqlMgr/insertCPkupLog?actnAm=11111&actnCd=4&bpiNb=' +
        teamWrkBpiNb +
        '&cntrctrNb=' +
        userInfo.cntrctrNb +
        '&userId=' +
        userInfo.user
    ).then((response) => console.log(response.status));
  }

  return (
    <>
      <Header heading="New Assignment" />
      <br />
      <AssignmentDetailsHeader bpiNo={teamWrkBpiNb} />
      <br />
      <div>
        <BuyerAssignmentInfo buyerCoBuyerInfo={buyerCoBuyerInfo} assignmentDetails={assignmentDetails}/>
        <br />
        <section>
          <div className="center">
            <fmc-button label="Accept" onClick={Accept}></fmc-button>
            <fmc-button label="Decline" onClick={Decline}></fmc-button>
          </div>
        </section>
      </div>
    </>
  );
}

export default NewAssignment;
