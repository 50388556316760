import './Button.css';

const Button = (props) => {
  return (
    <>
      <button className={'fmc-button ' + props.className} onClick={props.onClick}>
        {props.label}
        <span className={props.spanClass}></span>
      </button>
    </>
  );
};

export default Button;
