export function validate(personalDetail, contractorLoginId, country, priceStructs, phoneDisplayVals) {
  let errors = {};

  if (!personalDetail.cntrctrBusNm) {
    errors.cntrctrBusNm = 'Contractor name cannot be blank.';
  }
  if (!personalDetail.effDt) {
    errors.effDt = 'Effective Date cannot be blank.';
  }
  if (!personalDetail.expirDt) {
    errors.expirDt = 'Suspension Date is required';
  }
  if (!personalDetail.taxNb && country === 'USA') {
    errors.taxNb = 'Federal Tax Id is required';
  } else if (country === 'USA' && !isCapsAlphaNumeric(personalDetail.taxNb)) {
    errors.taxNb = 'Federal Tax Id must be Numeric and/or UpperCase letters and must not contain special characters.';
  }

  if (!contractorLoginId) {
    errors.cntrctrUserIdCd = 'Contractor login id must not in blank.';
  }
  if (!personalDetail.addrLine1Tx) {
    errors.addrLine1Tx = 'Address can not in blank.';
  }
  if (!personalDetail.cityNm) {
    errors.cityNm = 'City can not in blank.';
  }
  if (!personalDetail.stAbbrCd) {
    const stateOrProvince = country === 'CAN' ? 'Province' : 'State';
    errors.stAbbrCd = stateOrProvince + ' can not in blank.';
  }
  if (!personalDetail.zipCd) {
    const zipOrPostal = country === 'CAN' ? 'Postal' : 'Zip';
    errors.zipCd = zipOrPostal + ' Code can not in blank.';
  } else if (
    country === 'CAN' &&
    !/^(?<full>(?<part1>[ABCEGHJKLMNPRSTVXY]\d[A-Z])(?:[ ](?=\d))?(?<part2>\d[A-Z]\d))$/.test(personalDetail.zipCd)
  ) {
    errors.zipCd = 'Postal must be a 6 character Canadian Postal Code.';
  } else if (country !== 'CAN' && !/^([a-zA-Z\s]*|\d{5})$/.test(personalDetail.zipCd)) {
    errors.zipCd = 'Zip must be a 5 character US Zip Code.';
  }
  if (!personalDetail.emailAddrTx) {
    errors.emailAddrTx = 'Email can not in blank.';
  } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(personalDetail.emailAddrTx) || personalDetail.emailAddrTx.length > 50) {
    errors.emailAddrTx = 'Invalid email address.';
  }

  validatePriceStructure(priceStructs, errors);
  validatePhones(phoneDisplayVals, errors, personalDetail.alrtPrfrncCd);

  return errors;
}

function validatePhones(phoneDisplayVals, errors, alertPref) {
  if (!isValidPhoneAreaCode(phoneDisplayVals.primary.areaCode, true)) {
    errors.primaryAreaCode = 'A 3 digit Primary Phone Number area code is required.';
  }
  if (!isValidPhone(phoneDisplayVals.primary.num, true)) {
    errors.primaryNum = 'A 7 digit Primary Phone Number is required.';
  }

  if (!isValidPhoneAreaCode(phoneDisplayVals.fax.areaCode, false)) {
    errors.faxAreaCode = 'Fax Number area code must be a 3 digit number.';
    if (!isValidPhone(phoneDisplayVals.fax.areaCode, true)) {
      errors.faxNum = 'Fax Phone Number must be a 7 digit number.';
    }
  } else if (!isValidPhone(phoneDisplayVals.fax.num, false)) {
    errors.faxAreaCode = 'Fax Phone Number must be a 7 digit number.';
    if (!isValidPhoneAreaCode(phoneDisplayVals.fax.areaCode, true)) {
      errors.faxNum = 'Fax Number area code must be a 3 digit number.';
    }
  }

  if (!isValidPhoneAreaCode(phoneDisplayVals.afterHours.areaCode, false)) {
    errors.afterHoursAreaCode = 'After Hours area code must be a 3 digit number.';
    if (!isValidPhone(phoneDisplayVals.afterHours.num, true)) {
      errors.afterHoursNum = 'After Hours Number must be a 7 digit number.';
    }
  } else if (!isValidPhone(phoneDisplayVals.afterHours.num, false)) {
    errors.afterHoursNum = 'After Hours Number must be a 7 digit number.';
    if (!isValidPhoneAreaCode(phoneDisplayVals.afterHours.areaCode, true)) {
      errors.afterHoursAreaCode = 'After Hours area code must be a 3 digit number.';
    }
  }

  if (!isValidPhoneAreaCode(phoneDisplayVals.mobile.areaCode, false)) {
    errors.mobileAreaCode = 'Cell Phone area code must be a 3 digit number.';
    if (!isValidPhone(phoneDisplayVals.mobile.num, true)) {
      errors.mobileNum = 'Cell Phone Number must be a 7 digit number.';
    }
  } else if (!isValidPhone(phoneDisplayVals.mobile.num, false)) {
    errors.mobileNum = 'Cell Phone Number must be a 7 digit number.';
    if (!isValidPhoneAreaCode(phoneDisplayVals.mobile.areaCode, true)) {
      errors.mobileAreaCode = 'Cell Phone area code must be a 3 digit number.';
    }
  }

  if (phoneDisplayVals.primaryText.areaCode.trim() === '' && alertPref === 'T') {
    errors.primaryTextAreaCode = 'Primary Text Phone area code is required when Alert Preference is Text.';
  } else if (phoneDisplayVals.primaryText.areaCode.trim() === '' && alertPref === 'B') {
    errors.primaryTextAreaCode =
      'Primary Text Phone area code is required when Alert Preference is Both Email and Text.';
  } else if (!isValidPhoneAreaCode(phoneDisplayVals.primaryText.areaCode, false)) {
    errors.primaryTextAreaCode = 'Primary Text Phone area code must be a 3 digit number.';
  }

  if (phoneDisplayVals.primaryText.num === '' && alertPref === 'T') {
    errors.primaryTextNum = 'Primary Text Phone Number is required when Alert Preference is Text.';
  } else if (phoneDisplayVals.primaryText.num === '' && alertPref === 'B') {
    errors.primaryTextNum = 'Primary Text Phone Number is required when Alert Preference is Both Email and Text.';
  } else if (!isValidPhoneAreaCode(phoneDisplayVals.primaryText.num, false)) {
    errors.primaryTextNum = 'Primary Text Phone Number must be a 7 digit number.';
  }

  if (phoneDisplayVals.add1.cntctCd !== '') {
    if (!isValidPhoneAreaCode(phoneDisplayVals.add1.areaCode, true)) {
      errors.add1AreaCode = 'Additional Phone 1 area code must be a 3 digit number.';
    }
    if (!isValidPhone(phoneDisplayVals.add1.num, true)) {
      errors.add1Num = 'Additional Phone Number 1 must be a 7 digit number.';
    }
  }

  if (
    (phoneDisplayVals.add1.areaCode.trim() !== '' || phoneDisplayVals.add1.num.trim() !== '') &&
    phoneDisplayVals.add1.cntctCd === ''
  ) {
    errors.add1CntctCd = 'Additional Phone 1 Option cannot be blank';
  }

  if (phoneDisplayVals.add2.cntctCd !== '') {
    if (!isValidPhoneAreaCode(phoneDisplayVals.add2.areaCode, true)) {
      errors.add2AreaCode = 'Additional Phone 2 area code must be a 3 digit number.';
    }
    if (!isValidPhone(phoneDisplayVals.add2.num, true)) {
      errors.add2Num = 'Additional Phone Number 2 must be a 7 digit number.';
    }
  }

  if (
    (phoneDisplayVals.add2.areaCode.trim() !== '' || phoneDisplayVals.add2.num.trim() !== '') &&
    phoneDisplayVals.add2.cntctCd === ''
  ) {
    errors.add2CntctCd = 'Additional Phone 2 Option cannot be blank';
  }

  if (phoneDisplayVals.add3.cntctCd !== '') {
    if (!isValidPhoneAreaCode(phoneDisplayVals.add3.areaCode, true)) {
      errors.add3AreaCode = 'Additional Phone 3 area code must be a 3 digit number.';
    }
    if (!isValidPhone(phoneDisplayVals.add3.num, true)) {
      errors.add3Num = 'Additional Phone Number 3 must be a 7 digit number.';
    }
  }

  if (
    (phoneDisplayVals.add3.areaCode.trim() !== '' || phoneDisplayVals.add3.num.trim() !== '') &&
    phoneDisplayVals.add3.cntctCd === ''
  ) {
    errors.add3CntctCd = 'Additional Phone 3 Option cannot be blank';
  }
}

function validatePriceStructure(priceStructs, errors) {
  let totalCurrentPrcStctNb = priceStructs.length;
  for (let priceStruct of priceStructs) {
    if (priceStruct.status === 'remove') {
      totalCurrentPrcStctNb = totalCurrentPrcStctNb - 1;
    } else {
      validatePcPart1(priceStruct, errors);
      validatePcPart2(priceStruct, errors);
    }
  }
}

function validatePcPart1(priceStruct, errors) {
  const reAlphanumericSpecial = /^[\sa-zA-Z0-9.&/-]+$/;
  if (priceStruct.prcStrctTx.trim() === '') {
    errors.prcStrctTx = 'Price Structure Name cannot be blank.';
  } else if (!reAlphanumericSpecial.test(priceStruct.pcName)) {
    errors.prcStrctTx = 'Valid characters for Price structure name are Alphanumeric and Special characters: -/.&';
  }

  if (priceStruct.feeAm === null || priceStruct.feeAm === '') {
    errors.feeAm = 'Contractor Fee amount cannot be blank.';
  } else if (Math.sign(priceStruct.feeAm) !== 1) {
    errors.feeAm = 'Contractor Fee amount must be a positive number.';
  }

  if (priceStruct.vlntrFeeAm === null || priceStruct.vlntrFeeAm === '') {
    errors.vlntrFeeAm = 'Voluntary contractor Fee amount cannot be blank.';
  } else if (Math.sign(priceStruct.vlntrFeeAm) !== 1) {
    errors.vlntrFeeAm = 'Voluntary contractor fee amount must be a positive number.';
  }

  if (priceStruct.vlntrTodlrFeeAm === null || priceStruct.vlntrTodlrFeeAm === '') {
    errors.vlntrTodlrFeeAm = 'Dealer Voluntary contractor Fee amount cannot be blank.';
  } else if (Math.sign(priceStruct.vlntrTodlrFeeAm) !== 1) {
    errors.vlntrTodlrFeeAm = 'Dealer Voluntary contractor fee amount must be a positive number.';
  }
}

function validatePcPart2(priceStruct, errors) {
  if (priceStruct.cntngntIn === 'C' && priceStruct.clsFeeAm && Math.sign(priceStruct.clsFeeAm) !== 0) {
    errors.clsFeeAm = 'Close Fee Amount is not allowed for a contingent profile.';
  }
  if (priceStruct.cntngntIn === 'N') {
    if (priceStruct.clsFeeAm == null || Math.sign(priceStruct.clsFeeAm) === 0) {
      errors.clsFeeAm = 'Close Fee Amount is required for a non-contingent profile.';
    } else if (Math.sign(priceStruct.clsFeeAm) !== 1) {
      errors.clsFeeAm = 'Close fee amount must be a positive number.';
    }
  }
}

function isCapsAlphaNumeric(c) {
  const capsAlphaNumeric = /^[A-Z0-9]+$/;
  return capsAlphaNumeric.test(c);
}

function isValidPhone(phoneField, required) {
  const rePhoneNumbers = /^(\d)(\d)(\d)(\d)(\d)(\d)(\d)+$/;
  if (phoneField.trim() === '') {
    if (required) return false;
  } else if (!rePhoneNumbers.test(phoneField)) {
    return false;
  }
  return true;
}

function isValidPhoneAreaCode(phoneAreaField, required) {
  const rePhoneAreaNumbers = /^(\d)(\d)(\d)+$/;
  if (phoneAreaField.trim() === '') {
    if (required) return false;
  } else if (!rePhoneAreaNumbers.test(phoneAreaField)) {
    return false;
  }
  return true;
}
