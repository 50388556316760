export const ENDPOINTS = {
  GET_PC_DATA: '/viewCoverageArea/getPostalCodesData',
  GET_COUNTY_DATA: '/viewCoverageArea/getCountiesData',
  GET_PRICE_STRUCT: '/modifyCoverageArea/getPrcStrctTx',
  UPDATE_COVERAGE_CA: '/modifyCoverageArea/updateCANCoverageData',
  UPDATE_COVERAGE_USA: '/modifyCoverageArea/updateUSACoverageData',
  DELETE_COVERAGE_CA: '/modifyCoverageArea/deleteCANCoverageData',
  DELETE_COVERAGE_USA: '/modifyCoverageArea/deleteUSACoverageData',

  GET_SELECT_COVERAGE_CA_PC: '/contractorRequestManager/getSelectedPostalCode',
  GET_SELECT_COVERAGE_COUNTY: '/contractorRequestManager/getSelectedCounty',
  GET_PROVINCE_META: '/contractorRequestManager/getCANprovinces',
  GET_STATES_META: '/contractorRequestManager/getStateCodes',
  GET_PC_META: '/contractorRequestManager/getProvinces',
  GET_CA_PC_META: '/contractorRequestManager/getPostalCode',
  GET_COUNTY_META: '/contractorRequestManager/getCounties',

  GET_CONTRACTOR: '/SqlMgr/getCntrctrProfile',
  GET_CONTRACTOR_PERSONAL: '/modifyContractorProfile/getContractorPersonalDetails',
  GET_CONTRACTOR_FEE_PRICE_STRUCT: '/modifyContractorProfile/constructContractorFeeVector',
  GET_CONTRACTOR_CONTACT: '/profileMaint/getContractorContact',
  GET_BUZ_CENTER: '/contractorRequestManager/getSuperBranchCodes',
  GET_CONTRACTOR_ID_DETAILS: '/modifyContractorProfile/getContractorIdDetails/',
  UPDATE_PROFILE_PERSONAL_DETAIL: '/modifyContractorProfileSave/savePersonalDetails',
  UPDATE_PROFILE_PRICE_STRUCT: '/modifyContractorProfileSave/updateContractorFeeU2',
  UPDATE_PRICE_STRUCT_D: '/modifyContractorProfileSave/updateContractorFeeD',
  DELETE_PRICE_STRUCT_USA: '/modifyContractorProfileSave/deleteUSACoverage',
  DELETE_PRICE_STRUCT_CAN: '/modifyContractorProfileSave/deleteCANCoverage',
  DELETE_CONTACTS_BY_CNTRCTR_NB: '/modifyContractorProfileSave/deleteContactDetails',
  DELETE_CONSTRACTOR_ID_DETAILS: '/modifyContractorProfileSave/deleteContractorIdDetails',
  CREATE_PRICE_STRUCT: '/addContractorProfile/insertContractorFeeDetails',
  CREATE_CONTRACTOR_CONTACT: '/addContractorProfile/insertContractorNumberDetails',
  CREATE_CONTRACTOR_ID_DETAILS: 'addContractorProfile/insertContractorIdDetails',
  GET_CONTACT_CODES: '/profileMaint/contactCodes/other',

  IS_REPO_ADMIN: '/repoSecurity/isRepoUser/id',
  IS_EXTERNAL_USER: '/repoSecurity/isRepoUser/sitecode',
  GET_REPO_USER_DETAIL: '/repoSecurity/isRepoUser/detail',

  GET_CONTRACTOR_DATA: '/contractorRequestManager/getContractorData',

  GET_TIRE_CONDITION_CODE: '/lookupMgr/getTireConditionCodes',
  GET_OVERALL_CONDITION_CODE: '/lookupMgr/getOverallConditionCodes',
  GET_GENERAL_CONDITION_CODE: '/lookupMgr/getGeneralConditionCodes',
  GET_INTERIOR_CONDITION_CODE: '/lookupMgr/getInteriorConditionCodes',
  GET_GLASS_CONDITION_CODE: '/lookupMgr/getGlassConditionCodes',
  GET_MECHANICAL_CONDITION_CODE: '/lookupMgr/getMechanicalConditionCodes',
  SAVE_VCR_DATA: 'SqlMgr/VCR',
  SAVE_VEHLOC_DATA: 'SqlMgr/insDbVehicleLoc',
  UPDATE_AUCTION_GROUNDING: 'SqlMgr/updateGroundingInfo/auction',
  UPDATE_NON_AUCTION_GROUNDING: 'SqlMgr/updateGroundingInfo/nonAuction',
  GET_ASSIGNMENT_INFO: '/manualAssignment/AssignmentInfo',
  SAVE_PP_DETAILS: '/SqlMgr/DbPropertyDetail',
  UPDATE_ASSIGNMENT: '/SqlMgr/updateAssignment',

  GET_PERSONAL_PROPERTY_FUP: '/HomePage/getPersonalPropertyFUP',
  GET_SUMMARY: '/HomePage/getSummary',
  GET_BUYERCOBUYER_INFO: 'buyer',

  SAVE_INVOICE_DATA: 'SqlMgr/DbInvoice',
  GET_VCR: 'SqlMgr/getVCRDetails',
  GET_INVOICE: 'SqlMgr/getDBInvoiceDetails',
  GET_PROPERTY: 'SqlMgr/getDbPropertyDetails',
  GET_VEHLOC: 'SqlMgr/getDbVehicleLoc',
  INSWORKFLOW_COMMENTS_FOR_WF2: 'SqlMgr/WFCmt',
  GET_AUCTION_LOCATION: 'auctionLoc',
  SAVE_PP_LOC_STAT: 'SqlMgr/DbPropertyLocStat',
  GET_GROUNDING_INFO: 'SqlMgr/getGroundingInfo',
  GET_PP_LOCSTAT: 'SqlMgr/getDbPropertyLocStat',
  API_ENDPOINT_GET_INVOICE_CODE_LIST: 'lookupMgr/getInvoiceCodeList?prodCode=',
  GET_REPO_ASSIGNMENT_STATUS: 'DataMgr/getRepoAssignmentStatus/',
  GET_REPO_ASSIGNMENT_STATUS_INFO_MISC: '/HomePage/getQuestion/',
  POST_FCNADB2: 'transaction',
  GET_ACCOUNT_INFO: 'DataMgr/getCommonData/',
  GET_REPOCOMMON_INFO: 'DataMgr/getRepoCommonData/',
  GET_VCR_PDFBYTEARRAY: 'UploadMgr/getvcrpdfstr',
  GET_CONTRACTOR_INFO_FOR_COMMENT: 'SqlMgr/getCntrctrInfoForComments/',
  POST_PINNCAL_LOG: 'v4/',
  CREATE_VECHICLE_DISPOSAL_INFO: 'vehicleDisposal',
  UPDATE_VECHICLE_DISPOSAL_INFO: 'vehicleDisposal/',
  VECHICLE_DISPOSAL_INFO_BASE_URL: 'vehicleDisposal/',
  UPLOADGIV_CSM: 'UploadMgr/uploadGiv/',
};
