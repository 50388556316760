import Header from '../../Components/Header/Header';
import React, { useEffect, useState } from 'react';
import './HoldAssignment.css';
import { putCall } from '../../Services/CommonService';
import { useNavigate, useParams } from 'react-router-dom';
import AssignmentDetailsHeader from '../common/AssignmentDetailsHeader';
import { getUserInfoFromSession } from '../../Services/session.mgmt';
import * as Constants from '../../constants/RepoReactConstants';
import { PINNCALE_LOG_OBJ } from '../../constants/commonJsonConstants';

import { CommonDataService, getcommentStr } from '../../Services/CommonDataService';

import { FcnaDB2Postcall } from '../../api/accountsummary.api';
import { AssignmentService } from '../../Services/AssignmentService';
import BuyerAssignmentInfo from '../../Components/BuyerAssignmentInfo';

function HoldAssignment() {
  const navigate = useNavigate();
  let { teamWrkBpiNb } = useParams();
  console.log(teamWrkBpiNb);

  const [accountInformation, setAccountInformation] = useState([]);
  const [contractorInfoForComment, setContractorInfoForComment] = useState([]);

  const [assignmentDetails, setAssignmentDetails] = useState([]);
  const [buyerCoBuyerInfo, setBuyerCoBuyerInfo] = useState([]);
  const userInfo = getUserInfoFromSession();

  function getCurrentTimestamp() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // JavaScript months are 0-indexed
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const logPinnacle = (accountInformation, contractorInfoForComment, PINNCALE_LOG_OBJ) => {
    PINNCALE_LOG_OBJ.receivableAccountNumber = accountInformation.custAcctNb;
    PINNCALE_LOG_OBJ.coBusCd = accountInformation.coBusCd;
    PINNCALE_LOG_OBJ.createdTimeStamp = getCurrentTimestamp();
    PINNCALE_LOG_OBJ.enteredBranchCode = accountInformation.superBranchCd;
    PINNCALE_LOG_OBJ.createdBy = userInfo.userid;
    console.log(' PINNCALE  LOG Object to Log before add comment :::::::', PINNCALE_LOG_OBJ);

    console.log(
      'comment String with pinnalce:::::::::::::::::::',
      getcommentStr(contractorInfoForComment, Constants.ACTION_HOLD, Constants.rsHOLD_CONFIRM, userInfo.userid)
    );
    PINNCALE_LOG_OBJ.comment = getcommentStr(
      contractorInfoForComment,
      Constants.ACTION_HOLD,
      Constants.rsHOLD_CONFIRM,
      userInfo.userid
    );
    console.log(' after comment set:::::::::::::::::::', PINNCALE_LOG_OBJ);

    return CommonDataService.createPinnacleContact(PINNCALE_LOG_OBJ);
  };

  const onclickHandler = () => {
    Promise.all([
      logPinnacle(accountInformation, contractorInfoForComment, PINNCALE_LOG_OBJ),
      putCall(
        'processQuestion/updateCntrctAcct?bpiNb=' +
          teamWrkBpiNb +
          '&cntrctrNb=' +
          userInfo.cntrctrNb +
          '&sConfirm=Y&sCsrAction=H'
      ),
      FcnaDB2Postcall({
        acctNb: accountInformation?.custAcctNb,
        coBusCd: accountInformation?.coBusCd,
        transTypeCd: 21,
        sourceSystemCode: 12,
        lastUpdatedUserIdentificationCode: userInfo.userid,
        transactionDataText: teamWrkBpiNb + ';HOLD_CONFIRM',
        isImmediate: true,
      }),
    ])
      .then(([pinnResp, putResp, fcnadb2Resp]) => {
        console.log('Pinnacle Response:', JSON.stringify(pinnResp));
        console.log('putCall Response:', JSON.stringify(putResp));
        console.log('FcnaDB2Postcall Response:', JSON.stringify(fcnadb2Resp));
      })
      .catch((error) => {
        console.error('Error in Promise.all:', error);
      })
      .finally(() => {
        navigate('/outstandingAssignment');
      });
  };
  useEffect(() => {
    const fetchcommonInfo = async () => {
      try {
        const assignmentResponse = await AssignmentService.getAssignmentInfo(teamWrkBpiNb, userInfo.cntrctrNb);
        setAssignmentDetails(assignmentResponse.data);

        // Define multiple API calls
        const apiCall1 = CommonDataService.getAccountInfo(teamWrkBpiNb, userInfo.cntrctrNb);
        const apiCall2 = CommonDataService.getCntrctrInfoForComments(userInfo.userid);

        // Use Promise.all with await inside a try block
        const responses = await Promise.all([apiCall1, apiCall2]);

        setAccountInformation(responses[0]?.data);
        setContractorInfoForComment(responses[1]?.data);

        console.log('Data from API 1:', responses[0]?.data);
        console.log('Data from API 2:', responses[1]?.data);

        console.log(`getBuyerCobuyer Params coBusCd: ${responses[0]?.data?.coBusCd} ` + 
        `disorigBranchCd: ${responses[0]?.data?.discorigBranchCd} ` + 
        `custAcctNb: ${responses[0]?.data?.custAcctNb}`)

        AssignmentService.getBuyerCobuyerInfo(responses[0]?.data?.coBusCd, 
          responses[0]?.data?.discorigBranchCd, 
          responses[0]?.data?.custAcctNb)
          .then((buyerInfoResp) => {
            console.log('getBuyerCobuyer response:', buyerInfoResp)
            setBuyerCoBuyerInfo(buyerInfoResp?.data);
          })
          .catch((error) => {
            console.error('AssignmentService.getBuyerCobuyerInfo error:: ', error);
          });
      } catch (error) {
        // Handle any errors that occur in any of the promises
        console.error('An error occurred:', error);
      }
    };
    fetchcommonInfo();
  }, []);

  return (
    <>
      <Header heading="Hold Assignment" />
      <br />
      <AssignmentDetailsHeader bpiNo={teamWrkBpiNb} />
      <br />
      <div>
        <BuyerAssignmentInfo buyerCoBuyerInfo={buyerCoBuyerInfo} assignmentDetails={assignmentDetails}/>
        <br />
        <section>
          <div className="center">
            <button type="button" className="fmc-button" onClick={onclickHandler}>
              Acknowledge
            </button>
          </div>
        </section>
      </div>
    </>
  );
}

export default HoldAssignment;
