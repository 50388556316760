import serviceAxiosInstance from '../config/api.service.config';
import { ENDPOINTS } from '../constants/endpoints';
import axios from 'axios';

export function getTireConditionCodes() {
  return serviceAxiosInstance.get(ENDPOINTS['GET_TIRE_CONDITION_CODE']);
}

export function getOverallConditionCodes() {
  return serviceAxiosInstance.get(ENDPOINTS['GET_OVERALL_CONDITION_CODE']);
}

export function getGeneralConditionCodes() {
  return serviceAxiosInstance.get(ENDPOINTS['GET_GENERAL_CONDITION_CODE']);
}

export function getInteriorConditionCodes() {
  return serviceAxiosInstance.get(ENDPOINTS['GET_INTERIOR_CONDITION_CODE']);
}

export function getGlassConditionCodes() {
  return serviceAxiosInstance.get(ENDPOINTS['GET_GLASS_CONDITION_CODE']);
}

export function getMechanicalConditionCodes() {
  return serviceAxiosInstance.get(ENDPOINTS['GET_MECHANICAL_CONDITION_CODE']);
}

export function getStates() {
  return serviceAxiosInstance.get(ENDPOINTS['GET_STATES_META']);
}

export function getProvinces() {
  return serviceAxiosInstance.get(ENDPOINTS['GET_PROVINCE_META']);
}
export function saveVcr(vcrData) {
  return serviceAxiosInstance.post(ENDPOINTS['SAVE_VCR_DATA'], vcrData);
}
export function saveVehLoc(vehLocData) {
  return serviceAxiosInstance.post(ENDPOINTS['SAVE_VEHLOC_DATA'], vehLocData);
}
export function InsWorkflowCommentForWF2(wf2Obj) {
  return serviceAxiosInstance.post(ENDPOINTS['INSWORKFLOW_COMMENTS_FOR_WF2'], wf2Obj);
}
export function updateAuctionGrounding(auctionData) {
  return serviceAxiosInstance.put(ENDPOINTS['UPDATE_AUCTION_GROUNDING'], null, { params: auctionData });
}
export function updateNonAuctionGrounding(nonAuctionData) {
  return serviceAxiosInstance.put(ENDPOINTS['UPDATE_NON_AUCTION_GROUNDING'], null, { params: nonAuctionData });
}
export function getVcrObj() {
  return axios.get('/JsonFiles/vcrObj.json');
}
export function getVehLocObj() {
  return axios.get('/JsonFiles/VehLoc.json');
}
export function getAuctionLocationList(countrycode) {
  return axios.get(process.env.REACT_APP_SERVICE_BASE_URL_FCNA + ENDPOINTS['GET_AUCTION_LOCATION'] + '/' + countrycode);
}
