import React, { useEffect, useState } from 'react';
import './AssignmentDetails.css';
import AssignmentDetailsHeader from '../common/AssignmentDetailsHeader';
import { getUserInfoFromSession } from '../../Services/session.mgmt';
import { AssignmentService } from '../../Services/AssignmentService';
import BuyerAssignmentInfo from '../../Components/BuyerAssignmentInfo';
import { CommonDataService } from '../../Services/CommonDataService';

function AssignmentDetails({ bpiNo, onContinue, activeTab }) {
  const teamWrkBpiNb = bpiNo;
  console.log(teamWrkBpiNb);
  const [assignmentDetails, setAssignmentDetails] = useState([]);
  const [buyerCoBuyerInfo, setBuyerCoBuyerInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const userInfo = getUserInfoFromSession();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const assignmentResponse = await AssignmentService.getAssignmentInfo(teamWrkBpiNb, userInfo.cntrctrNb);
        setAssignmentDetails(assignmentResponse.data);

        const accountInfoResponse = await CommonDataService.getAccountInfo(teamWrkBpiNb, userInfo.cntrctrNb);
        
        console.log(`getBuyerCobuyer Params coBusCd: ${accountInfoResponse?.data?.coBusCd} ` + 
        `disorigBranchCd: ${accountInfoResponse?.data?.discorigBranchCd} ` + 
        `custAcctNb: ${accountInfoResponse?.data?.custAcctNb}`)
        AssignmentService.getBuyerCobuyerInfo(accountInfoResponse?.data?.coBusCd, 
          accountInfoResponse?.data?.discorigBranchCd, 
          accountInfoResponse?.data?.custAcctNb)
          .then((buyerInfoResp) => {
            console.log('getBuyerCobuyer response:', buyerInfoResp)
            setBuyerCoBuyerInfo(buyerInfoResp?.data);
          })
          .catch((error) => {
            console.error('AssignmentService.getBuyerCobuyerInfo error:: ', error);
          });
      } catch (err) {
        console.log(`Error FectchingData : ${err}`);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [activeTab == 1]);

  if (loading)
    return (
      <>
        <AssignmentDetailsHeader bpiNo={teamWrkBpiNb} />
        <br />
        Loading...
      </>
    );
  return (
    <>
      <AssignmentDetailsHeader bpiNo={teamWrkBpiNb} />
      <br />
      <div>
        <BuyerAssignmentInfo buyerCoBuyerInfo={buyerCoBuyerInfo} assignmentDetails={assignmentDetails}/>
        <br />
        <section>
          <div className="center">
            <button type="button" className="fmc-button" onClick={onContinue}>
              Continue
            </button>
          </div>
        </section>
      </div>
    </>
  );
}

export default AssignmentDetails;
