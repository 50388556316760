import { isContractorAvailable } from './AddContractorApis';
import _ from 'lodash';

function validatePart1(addContractorVals, newErrors) {
  if (addContractorVals.contractorName.trim() === '') {
    newErrors += 'Contractor name cannot be blank.<br/>';
  }

  if (addContractorVals.contractorLoginId.trim() === '') {
    newErrors += 'Contractor login id cannot be blank.<br/>';
  }

  if (addContractorVals.address.trim() === '') {
    newErrors += 'Address cannot be blank.<br/>';
  }

  if (addContractorVals.city.trim() === '') {
    newErrors += 'City cannot be blank.<br/>';
  }

  return newErrors;
}

function validateZip(addContractorVals, newErrors, country) {
  if (addContractorVals.zip.trim() === '') {
    newErrors += 'Zip/Postal Code cannot be blank.<br/>';
  } else {
    if (!isZIPCode(addContractorVals.zip, country)) {
      if (country === 'USA') newErrors += 'Zip must be a 5 digit U.S. ZIP Code. Please reenter it now.<br/>';
      else {
        if (!isCapsAlphaNumeric(addContractorVals.zip))
          newErrors += 'Canadian Postal code must contain only Upper case letters.<br/>';
        else newErrors += 'Postal must be a 6 character Canadian Postal Code. Please reenter it now.<br/>';
      }
    }
  }

  return newErrors;
}

function validatePart2(addContractorVals, newErrors, country, priceStructureSets) {
  newErrors = validateZip(addContractorVals, newErrors, country);

  if (addContractorVals.email.trim() === '') {
    newErrors += 'Email Address is required.<br/>';
  } else if (!isValidateEmail(addContractorVals.email)) {
    newErrors += 'Invalid Email address format.<br/>';
  }
  if (!_.isEmpty(addContractorVals.secemail) && !isValidateEmail(addContractorVals.secemail)) {
    newErrors += 'Invalid Email address format.<br/>';
  }
  if (country === 'USA' && addContractorVals.taxId.trim() === '') {
    newErrors += 'Federal Tax Id cannot be blank.<br/>';
  } else if (country === 'USA' && !isCapsAlphaNumeric(addContractorVals.taxId)) {
    newErrors +=
      'Federal Tax Id must be Numeric and/or UpperCase letters and must not contain special characters.<br/>';
  }

  newErrors = validatePriceStructure(newErrors, priceStructureSets);

  return newErrors;
}

function validatePrimaryFaxPhone(addContractorVals, newErrors) {
  if (!isValidPhoneAreaCode(addContractorVals.primaryPhoneAreaCode, true)) {
    newErrors += 'A 3 digit Primary Phone Number area code is required.<br/>';
  }
  if (!isValidPhone(addContractorVals.primaryPhoneNum, true)) {
    newErrors += 'A 7 digit Primary Phone Number is required.<br/>';
  }

  if (!isValidPhoneAreaCode(addContractorVals.faxAreaCode, false)) {
    newErrors += 'Fax Number area code must be a 3 digit number.<br/>';
    if (!isValidPhone(addContractorVals.faxNum, true)) {
      newErrors += 'Fax Phone Number must be a 7 digit number.<br/>';
    }
  } else if (!isValidPhone(addContractorVals.faxNum, false)) {
    newErrors += 'Fax Phone Number must be a 7 digit number.<br/>';
    if (!isValidPhoneAreaCode(addContractorVals.faxAreaCode, true)) {
      newErrors += 'Fax Number area code must be a 3 digit number.<br/>';
    }
  }

  return newErrors;
}

function validatePart3(addContractorVals, newErrors) {
  newErrors = validatePrimaryFaxPhone(addContractorVals, newErrors);

  if (!isValidPhoneAreaCode(addContractorVals.afterHoursAreaCode, false)) {
    newErrors += 'After Hours area code must be a 3 digit number.<br/>';
    if (!isValidPhone(addContractorVals.afterHoursNum, true)) {
      newErrors += 'After Hours Number must be a 7 digit number.<br/>';
    }
  } else if (!isValidPhone(addContractorVals.afterHoursNum, false)) {
    newErrors += 'After Hours Number must be a 7 digit number.<br/>';
    if (!isValidPhoneAreaCode(addContractorVals.afterHoursAreaCode, true)) {
      newErrors += 'After Hours area code must be a 3 digit number.<br/>';
    }
  }

  if (!isValidPhoneAreaCode(addContractorVals.mobileAreaCode, false)) {
    newErrors += 'Mobile Phone area code must be a 3 digit number.<br/>';
    if (!isValidPhone(addContractorVals.mobileNum, true)) {
      newErrors += 'Mobile Phone Number must be a 7 digit number.<br/>';
    }
  } else if (!isValidPhone(addContractorVals.mobileNum, false)) {
    newErrors += 'Mobile Phone Number must be a 7 digit number.<br/>';
    if (!isValidPhoneAreaCode(addContractorVals.mobileAreaCode, true)) {
      newErrors += 'Mobile Phone area code must be a 3 digit number.<br/>';
    }
  }

  return newErrors;
}

function validateAlertPref(addContractorVals, newErrors) {
  if (addContractorVals.primaryTextAreaCode.trim() === '' && addContractorVals.alertPref === 'T') {
    newErrors += 'Primary Text Phone area code is required when Alert Preference is Text.<br/>';
  } else if (addContractorVals.primaryTextAreaCode.trim() === '' && addContractorVals.alertPref === 'B') {
    newErrors += 'Primary Text Phone area code is required when Alert Preference is Both Email and Text.<br/>';
  } else if (!isValidPhoneAreaCode(addContractorVals.primaryTextAreaCode, false)) {
    newErrors += 'Primary Text Phone area code must be a 3 digit number.<br/>';
  }

  if (addContractorVals.primaryTextNum === '' && addContractorVals.alertPref === 'T') {
    newErrors += 'Primary Text Phone Number is required when Alert Preference is Text.<br/>';
  } else if (addContractorVals.primaryTextNum === '' && addContractorVals.alertPref === 'B') {
    newErrors += 'Primary Text Phone Number is required when Alert Preference is Both Email and Text.<br/>';
  } else if (!isValidPhoneAreaCode(addContractorVals.primaryTextNum, false)) {
    newErrors += 'Primary Text Phone Number must be a 7 digit number.<br/>';
  }
  return newErrors;
}

function validatePart4(addContractorVals, newErrors) {
  if (addContractorVals.alertPref === 'T' || addContractorVals.alertPref === 'B') {
    newErrors = validateAlertPref(addContractorVals, newErrors);
  } else {
    if (!isValidPhoneAreaCode(addContractorVals.primaryTextAreaCode, false)) {
      newErrors += 'Primary Text area code must be a 3 digit number.<br/>';
      if (!isValidPhone(addContractorVals.primaryTextNum, true)) {
        newErrors += 'Primary Text Phone Number must be a 7 digit number.<br/>';
      }
    } else if (!isValidPhone(addContractorVals.primaryTextNum, false)) {
      newErrors += 'Primary Text Phone Number must be a 7 digit number.<br/>';
      if (!isValidPhoneAreaCode(addContractorVals.primaryTextAreaCode, true)) {
        newErrors += 'Primary Text area code must be a 3 digit number.<br/>';
      }
    }
  }

  return newErrors;
}

function validatePart5(addContractorVals, newErrors) {
  if (addContractorVals.addPhone1Type !== '') {
    if (!isValidPhoneAreaCode(addContractorVals.addPhone1AreaCode, true)) {
      newErrors += 'Additional Phone 1 area code must be a 3 digit number.<br/>';
    }
    if (!isValidPhone(addContractorVals.addPhone1Num, true)) {
      newErrors += 'Additional Phone Number 1 must be a 7 digit number.<br/>';
    }
  }

  if (
    (addContractorVals.addPhone1AreaCode.trim() !== '' || addContractorVals.addPhone1Num.trim() !== '') &&
    addContractorVals.addPhone1Type === ''
  ) {
    newErrors += 'Additional Phone 1 Option cannot be blank<br/>';
  }

  if (addContractorVals.addPhone2Type !== '' && !isValidPhoneAreaCode(addContractorVals.addPhone2AreaCode, true)) {
    newErrors += 'Additional Phone 2 area code must be a 3 digit number.<br/>';
  } else if (addContractorVals.addPhone2Type !== '' && !isValidPhone(addContractorVals.addPhone2Num, true)) {
    newErrors += 'Additional Phone Number 2 must be a 7 digit number.<br/>';
  }

  if (
    (addContractorVals.addPhone2AreaCode.trim() !== '' || addContractorVals.addPhone2Num.trim() !== '') &&
    addContractorVals.addPhone2Type === ''
  ) {
    newErrors += 'Additional Phone 2 Option cannot be blank<br/>';
  }

  return newErrors;
}

export async function validate(addContractorVals, priceStructureSets, country) {
  let newErrors = '';

  newErrors += await isContractorAvailable(addContractorVals.contractorLoginId);

  newErrors = validatePart1(addContractorVals, newErrors);
  newErrors = validatePart2(addContractorVals, newErrors, country, priceStructureSets);
  newErrors = validatePart3(addContractorVals, newErrors);
  newErrors = validatePart4(addContractorVals, newErrors);
  newErrors = validatePart5(addContractorVals, newErrors);

  if (addContractorVals.addPhone3Type !== '') {
    if (!isValidPhoneAreaCode(addContractorVals.addPhone3AreaCode, true)) {
      newErrors += 'Additional Phone 3 area code must be a 3 digit number.<br/>';
    }
    if (!isValidPhone(addContractorVals.addPhone3Num, true)) {
      newErrors += 'Additional Phone Number 3 must be a 7 digit number.<br/>';
    }
  }

  if (
    (addContractorVals.addPhone3AreaCode.trim() !== '' || addContractorVals.addPhone3Num.trim() !== '') &&
    addContractorVals.addPhone3Type === ''
  ) {
    newErrors += 'Additional Phone 3 Option cannot be blank<br/>';
  }

  return newErrors;
}

function isZIPCode(zip, country) {
  if (country === 'USA' && /^([a-zA-Z\s]*|\d{5})$/.test(zip)) {
    return true;
  } else if (
    country !== 'USA' &&
    /^(?<full>(?<part1>[ABCEGHJKLMNPRSTVXY]\d[A-Z])(?:[ ](?=\d))?(?<part2>\d[A-Z]\d))$/.test(zip)
  ) {
    return true;
  } else {
    return false;
  }
}

function isCapsAlphaNumeric(c) {
  const capsAlphaNumeric = /^[A-Z0-9]+$/;
  return capsAlphaNumeric.test(c);
}

function isValidateEmail(email) {
  const filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return filter.test(email);
}

function isValidPhone(phoneField, required) {
  const rePhoneNumbers = /^(\d)(\d)(\d)(\d)(\d)(\d)(\d)+$/;
  if (phoneField.trim() === '') {
    if (required) return false;
  } else if (!rePhoneNumbers.test(phoneField)) {
    return false;
  }
  return true;
}

function isValidPhoneAreaCode(phoneAreaField, required) {
  const rePhoneAreaNumbers = /^(\d)(\d)(\d)+$/;
  if (phoneAreaField.trim() === '') {
    if (required) return false;
  } else if (!rePhoneAreaNumbers.test(phoneAreaField)) {
    return false;
  }
  return true;
}

function validatePcPart1(priceStructureSet, newErrors) {
  const reAlphanumericSpecial = /^[\sa-zA-Z0-9.&/-]+$/;
  if (priceStructureSet.pcName.trim() === '') {
    newErrors += 'Price Structure Name cannot be blank.<br/>';
  } else if (!reAlphanumericSpecial.test(priceStructureSet.pcName)) {
    newErrors += 'Valid characters for Price structure name are Alphanumeric and Special characters: -/.&<br/>';
  }

  if (priceStructureSet.selfHelp.trim() === '') {
    newErrors += 'Contractor Fee amount cannot be blank.<br/>';
  } else if (Math.sign(priceStructureSet.selfHelp) !== 1) {
    newErrors += 'Contractor Fee amount must be a positive number.<br/>';
  }

  if (priceStructureSet.volContractor.trim() === '') {
    newErrors += 'Voluntary contractor Fee amount cannot be blank.<br/>';
  } else if (Math.sign(priceStructureSet.volContractor) !== 1) {
    newErrors += 'Voluntary contractor fee amount must be a positive number.<br/>';
  }

  if (priceStructureSet.dealerVolContractorFee.trim() === '') {
    newErrors += 'Dealer Voluntary contractor Fee amount cannot be blank.<br/>';
  } else if (Math.sign(priceStructureSet.dealerVolContractorFee) !== 1) {
    newErrors += 'Dealer Voluntary contractor fee amount must be a positive number.<br/>';
  }
  return newErrors;
}

function validatePcPart2(priceStructureSet, newErrors) {
  if (
    priceStructureSet.contingency === 'C' &&
    priceStructureSet.closeFee.trim() !== '' &&
    priceStructureSet.closeFee.trim() !== '0'
  ) {
    newErrors += 'Close Fee Amount is not allowed for a contingent profile.<br/>';
  }
  if (priceStructureSet.contingency === 'N') {
    if (priceStructureSet.closeFee.trim() === '' || priceStructureSet.closeFee.trim() === '0') {
      newErrors += 'Close Fee Amount is required for a non-contingent profile.<br/>';
    } else if (Math.sign(priceStructureSet.closeFee.trim()) !== 1) {
      newErrors += 'Close fee amount must be a positive number.<br/>';
    }
  }
  return newErrors;
}

// Validate price structure for add and modify page
function validatePriceStructure(newErrors, priceStructureSets) {
  let totalCurrentPrcStctNb = priceStructureSets.length;
  for (let priceStructureSet of priceStructureSets) {
    if (priceStructureSet.checked === true) {
      totalCurrentPrcStctNb = totalCurrentPrcStctNb - 1;
    } else {
      newErrors = validatePcPart1(priceStructureSet, newErrors);
      newErrors = validatePcPart2(priceStructureSet, newErrors);
    }
  }
  return newErrors;
}
