import './PersonalProperty.css';
import React, { useEffect, useState } from 'react';

import swal from 'sweetalert';
import { showAlert, showConfirm } from '../../Services/CommonFunctions';
import AssignmentDetailsHeader from '../common/AssignmentDetailsHeader';
import { PersonalPropertySevice } from '../../Services/PersonalPropertyService';
import { getUserInfoFromSession } from '../../Services/session.mgmt';
import { useNavigate } from 'react-router-dom';
import { AssignmentService } from '../../Services/AssignmentService';
import { VCRService } from '../../Services/VCRService';
import promise from 'promise';
import _ from 'lodash';

function PersonalProperty(props) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { assignmentStatus } = props;
  let teamWrkBpiNb = Number(props.bpiNo);
  const userInfo = getUserInfoFromSession();
  const [formData, setFormData] = useState({
    ppIndicator: '',
    ppReturned: '',
    item01: '',
    item02: '',
    item03: '',
    item04: '',
    item05: '',
    item06: '',
    item07: '',
    item08: '',
    item09: '',
    item10: '',
    item11: '',
    item12: '',
    item13: '',
    item14: '',
    item15: '',
    wherePropertyStored: '',
    storingContractorName: '',
    customerNameDate: '',
  });
  const clearFormData = () => {
    setFormData({
      ppIndicator: '',
      ppReturned: '',
      item01: '',
      item02: '',
      item03: '',
      item04: '',
      item05: '',
      item06: '',
      item07: '',
      item08: '',
      item09: '',
      item10: '',
      item11: '',
      item12: '',
      item13: '',
      item14: '',
      item15: '',
      wherePropertyStored: '',
      storingContractorName: '',
      customerNameDate: '',
    });
  };

  useEffect(() => {
    const fetchPersonalProperties = async () => {
      try {
        Promise.all([
          AssignmentService.getPpDetails(teamWrkBpiNb),
          AssignmentService.getDbPropertyLocStat(teamWrkBpiNb),
        ])
          .then(([ppDetailsResponse, PPLocStatResponse]) => {
            console.log('ppDetailsResponse: ' + JSON.stringify(ppDetailsResponse));
            console.log('ppLocStateResponse: ' + JSON.stringify(PPLocStatResponse));
            if (!_.isEmpty(ppDetailsResponse.data) && !_.isEmpty(PPLocStatResponse.data)) {
              setFormData(() => ({
                ppIndicator: _.isEmpty(ppDetailsResponse.data) ? 'N' : 'Y',
                ppReturned: PPLocStatResponse?.data?.result?.personalPropertyLocStat?.prsnPrptyStatCd ?? '',
                item01: ppDetailsResponse?.data[0]?.itmNm ?? '',
                item02: ppDetailsResponse?.data[1]?.itmNm ?? '',
                item03: ppDetailsResponse?.data[2]?.itmNm ?? '',
                item04: ppDetailsResponse?.data[3]?.itmNm ?? '',
                item05: ppDetailsResponse?.data[4]?.itmNm ?? '',
                item06: ppDetailsResponse?.data[5]?.itmNm ?? '',
                item07: ppDetailsResponse?.data[6]?.itmNm ?? '',
                item08: ppDetailsResponse?.data[7]?.itmNm ?? '',
                item09: ppDetailsResponse?.data[8]?.itmNm ?? '',
                item10: ppDetailsResponse?.data[9]?.itmNm ?? '',
                item11: ppDetailsResponse?.data[10]?.itmNm ?? '',
                item12: ppDetailsResponse?.data[11]?.itmNm ?? '',
                item13: ppDetailsResponse?.data[12]?.itmNm ?? '',
                item14: ppDetailsResponse?.data[13]?.itmNm ?? '',
                item15: ppDetailsResponse?.data[14]?.itmNm ?? '',
                wherePropertyStored: PPLocStatResponse?.data?.result?.personalPropertyLocStat?.prsnPrptyLocTx ?? '',
                storingContractorName:
                  PPLocStatResponse?.data?.result?.personalPropertyLocStat?.prsnPrptyLocBusNm ?? '',
                customerNameDate:
                  PPLocStatResponse?.data?.result?.personalPropertyLocStat?.lstUpdtUserId +
                    '/' +
                    PPLocStatResponse?.data?.result?.personalPropertyLocStat?.lstUpdtTs.substring(0, 10) ?? '',
              }));
            }
          })
          .catch((error) => {
            console.log('Error in personal property promises: ', error);
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    if (props.activeTab == 4) {
      fetchPersonalProperties();
    }
  }, [props.activeTab == 4]);

  async function savePersonalPropertyDetails(formData) {
    try {
      // insert into ZBPT103_PRSNPRPTY_LOC_STAT
      let ppPropertyLocStat = {
        teamwkrBpiNb: teamWrkBpiNb,
        prsnPrptyStatCd: formData.ppReturned,
        prsnPrptyLocBusNm: formData.storingContractorName?.trim() || '',
        prsnPrptyLocTx: formData.wherePropertyStored?.trim() || '',
        lstUpdtUserId: userInfo.userid?.trim(),
      };
      PersonalPropertySevice.deletePsnlPropByBpiNb(teamWrkBpiNb)
        .then((delePpDetailResp) => {
          PersonalPropertySevice.savePPLocStat(ppPropertyLocStat).then((response) => {
            const ppDetailsArray = _.map(_.range(1, 15), (i) => {
              const key = i < 10 ? `item0${i}` : `item${i}`;
              const value = formData[key]?.toString() || '';
              if (value !== '') {
                return {
                  itmNb: i,
                  itmNm: value,
                  lstUpdtUserId: userInfo.userid,
                  teamWrkBpiNb: teamWrkBpiNb,
                };
              }
              return null;
            }).filter(Boolean);
            for (const ppDetails of ppDetailsArray) {
              PersonalPropertySevice.savePPDetails(ppDetails);
            }
          });
        })
        .catch((error) => {
          console.log('Error in saving personal property details: ', error);
        })
        .finally(() => {
          setLoading(false);
          clearFormData();
          return true;
        });
    } catch (err) {
      console.log(err);
    }
  }

  const onChangeHandler = (event) => {
    setFormData(() => ({
      ...formData,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.table(formData);
    //handle submit from repoweb war
    let errorMessage = '';
    if (formData.ppIndicator === '') {
      errorMessage += 'Indicate the personal property status.\n';
    } else if (formData.ppIndicator === 'Y') {
      if (!isPropertyDetailed()) errorMessage += 'Indicate the property that was recovered.\n';
      if (!isStorageDetailed()) errorMessage += 'Indicate where the property is stored & Name of the contractor.\n';
    } else {
      if (await showConfirm('All property data will be cleared, proceed?')) {
        clearPropertyDetail();
        clearDisposalDetail();
      } else {
        errorMessage +=
          'Please delete the personal property that has been inventoried and the storage information if you have disposed or returned it.\n';
      }
    }

    if (errorMessage !== '') {
      await showAlert(errorMessage, 'warning');
    } else {
      if (formData.ppIndicator === 'Y') {
        AssignmentService.getGroundingInfo(teamWrkBpiNb).then((groundingInfo) => {
          let nonAuctionData = {
            vehlocTypCd: groundingInfo.data.vehLocTypCd,
            propRcvrIn: 'Y',
            userId: userInfo.userid,
            bpiNb: teamWrkBpiNb,
          };
          VCRService.updateNonAuctionGrounding(nonAuctionData).then((response) => {
            savePersonalPropertyDetails(formData).then((response) => {
              showAlert('Personal Property Details Saved successfully!!', 'success').then(() => {
                navigate(`/outstandingsummary/${teamWrkBpiNb}`);
              });
            });
          });
        });
      } else {
        //delete all personal property details
        PersonalPropertySevice.deletePsnlPropByBpiNb(teamWrkBpiNb).then((response) => {
          if (response.status >= 200) {
            showAlert('Personal Property Details Removed successfully!!', 'success').then(() => {
              navigate(`/outstandingsummary/${teamWrkBpiNb}`);
            });
          }
        });
      }
    }
  };

  let count;
  function isPropertyDetailed() {
    count = 0;
    for (let i = 1; i < 15; i++) {
      let key = '';
      let value = '';
      if (i < 10) {
        key = 'item0' + i.toString();
        value = formData[key].toString();
        console.log(value);
      } else {
        key = 'item' + i.toString();
        value = formData[key].toString();
        console.log(value);
      }
      if (value !== '') {
        count++;
        console.log(count);
      }
    }
    return count > 0;
  }

  function isStorageDetailed() {
    return !(formData.wherePropertyStored === '') && !(formData.storingContractorName === '');
  }

  const resetFormData = async () => {
    if (await showConfirm('all property data will be cleared, proceed?')) {
      clearPropertyDetail();
    }
  };

  function clearDisposalDetail() {
    formData.ppReturned = '';
  }
  function clearPropertyDetail() {
    console.log('enteredinto clearPropertyDetails');
    clearFormData();
    return true;
  }

  return loading ? (
    () => <div>Loading...</div>
  ) : (
    <>
      <br />
      <AssignmentDetailsHeader bpiNo={teamWrkBpiNb} />
      <br />
      <br />
      <form id="personalProperty-form" onSubmit={handleSubmit}>
        <fieldset disabled={assignmentStatus === 'H' ? true : false}>
          <div style={{ marginInline: 2 + 'em' }}>
            <section className="fds-display--flex">
              Personal property in the vehicle when recovered.
              <div>
                <fieldset className="fmc-check fmc-check--horizontal" style={{ paddingLeft: 2 + 'em' }}>
                  <label>
                    <input
                      type="radio"
                      name="ppIndicator"
                      value="Y"
                      onChange={onChangeHandler}
                      checked={formData.ppIndicator === 'Y'}
                    />
                    <span>Yes</span>
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="ppIndicator"
                      value="N"
                      onChange={onChangeHandler}
                      checked={formData.ppIndicator === 'N'}
                    />
                    <span>No</span>
                  </label>
                </fieldset>
              </div>
            </section>
            If "Yes", complete the following form, itemize the property(expect in CA), and when returned to Customer
            have him sign in space provided.
            <hr />
            <section style={{ padding: 1 + 'em' }}>
              <div>
                <fieldset className="fmc-check fmc-check--horizontal">
                  <label>
                    <input
                      type="radio"
                      name="ppReturned"
                      value="R"
                      onChange={onChangeHandler}
                      checked={formData.ppReturned === 'R'}
                    />
                    <span>Personal property returned.</span>
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="ppReturned"
                      value="D"
                      onChange={onChangeHandler}
                      checked={formData.ppReturned === 'D'}
                    />
                    <span>Personal property disposed.</span>
                  </label>
                </fieldset>
              </div>
            </section>
            <hr />
            <br />
            <section className="fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white">
              <div className="fds-layout-gri p-0">
                <div className="fds-layout-grid__inner p-0 ">
                  <input
                    className="fmc-input fds-layout-grid__cell--span-4"
                    style={{ width: 15 + 'em', margin: 1 + 'em' }}
                    type="text"
                    placeholder="1"
                    name="item01"
                    value={formData.ppIndicator === 'N' ? '' : formData.item01}
                    maxLength="50"
                    onChange={onChangeHandler}
                  />

                  <input
                    className="fmc-input fds-layout-grid__cell--span-4"
                    style={{ width: 15 + 'em', margin: 1 + 'em' }}
                    type="text"
                    placeholder="2"
                    name="item02"
                    value={formData.ppIndicator === 'N' ? '' : formData.item02}
                    maxLength="50"
                    onChange={onChangeHandler}
                  />

                  <input
                    className="fmc-input fds-layout-grid__cell--span-4"
                    style={{ width: 15 + 'em', margin: 1 + 'em' }}
                    type="text"
                    placeholder="3"
                    name="item03"
                    value={formData.ppIndicator === 'N' ? '' : formData.item03}
                    maxLength="50"
                    onChange={onChangeHandler}
                  />
                </div>
                <div className="fds-layout-grid__inner p-0 ">
                  <input
                    className="fmc-input fds-layout-grid__cell--span-4"
                    style={{ width: 15 + 'em', margin: 1 + 'em' }}
                    type="text"
                    placeholder="4"
                    name="item04"
                    value={formData.ppIndicator === 'N' ? '' : formData.item04}
                    maxLength="50"
                    onChange={onChangeHandler}
                  />
                  <input
                    className="fmc-input fds-layout-grid__cell--span-4"
                    style={{ width: 15 + 'em', margin: 1 + 'em' }}
                    type="text"
                    placeholder="5"
                    name="item05"
                    value={formData.ppIndicator === 'N' ? '' : formData.item05}
                    maxLength="50"
                    onChange={onChangeHandler}
                  />
                  <input
                    className="fmc-input fds-layout-grid__cell--span-4"
                    style={{ width: 15 + 'em', margin: 1 + 'em' }}
                    type="text"
                    placeholder="6"
                    name="item06"
                    value={formData.ppIndicator === 'N' ? '' : formData.item06}
                    maxLength="50"
                    onChange={onChangeHandler}
                  />
                </div>
                <div className="fds-layout-grid__inner p-0 ">
                  <input
                    className="fmc-input fds-layout-grid__cell--span-4"
                    style={{ width: 15 + 'em', margin: 1 + 'em' }}
                    type="text"
                    placeholder="7"
                    name="item07"
                    value={formData.ppIndicator === 'N' ? '' : formData.item07}
                    maxLength="50"
                    onChange={onChangeHandler}
                  />
                  <input
                    className="fmc-input fds-layout-grid__cell--span-4"
                    style={{ width: 15 + 'em', margin: 1 + 'em' }}
                    type="text"
                    placeholder="8"
                    name="item08"
                    value={formData.ppIndicator === 'N' ? '' : formData.item08}
                    maxLength="50"
                    onChange={onChangeHandler}
                  />
                  <input
                    className="fmc-input fds-layout-grid__cell--span-4"
                    style={{ width: 15 + 'em', margin: 1 + 'em' }}
                    type="text"
                    placeholder="9"
                    name="item09"
                    value={formData.ppIndicator === 'N' ? '' : formData.item09}
                    maxLength="50"
                    onChange={onChangeHandler}
                  />
                </div>
                <div className="fds-layout-grid__inner p-0 ">
                  <input
                    className="fmc-input fds-layout-grid__cell--span-4"
                    style={{ width: 15 + 'em', margin: 1 + 'em' }}
                    type="text"
                    placeholder="10"
                    name="item10"
                    value={formData.ppIndicator === 'N' ? '' : formData.item10}
                    maxLength="50"
                    onChange={onChangeHandler}
                  />
                  <input
                    className="fmc-input fds-layout-grid__cell--span-4"
                    style={{ width: 15 + 'em', margin: 1 + 'em' }}
                    type="text"
                    placeholder="11"
                    name="item11"
                    value={formData.ppIndicator === 'N' ? '' : formData.item11}
                    maxLength="50"
                    onChange={onChangeHandler}
                  />
                  <input
                    className="fmc-input fds-layout-grid__cell--span-4"
                    style={{ width: 15 + 'em', margin: 1 + 'em' }}
                    type="text"
                    placeholder="12"
                    name="item12"
                    value={formData.ppIndicator === 'N' ? '' : formData.item12}
                    maxLength="50"
                    onChange={onChangeHandler}
                  />
                </div>
                <div className="fds-layout-grid__inner p-0 ">
                  <input
                    className="fmc-input fds-layout-grid__cell--span-4"
                    style={{ width: 15 + 'em', margin: 1 + 'em' }}
                    type="text"
                    placeholder="13"
                    name="item13"
                    value={formData.ppIndicator === 'N' ? '' : formData.item13}
                    maxLength="50"
                    onChange={onChangeHandler}
                  />
                  <input
                    className="fmc-input fds-layout-grid__cell--span-4"
                    style={{ width: 15 + 'em', margin: 1 + 'em' }}
                    type="text"
                    placeholder="14"
                    name="item14"
                    value={formData.ppIndicator === 'N' ? '' : formData.item14}
                    maxLength="50"
                    onChange={onChangeHandler}
                  />
                  <input
                    className="fmc-input fds-layout-grid__cell--span-4"
                    style={{ width: 15 + 'em', margin: 1 + 'em' }}
                    type="text"
                    placeholder="15"
                    name="item15"
                    value={formData.ppIndicator === 'N' ? '' : formData.item15}
                    maxLength="50"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>
            </section>
            <hr />
            <div className="row">
              <div className="column form-group">
                <label>Where property stored</label>
                <input
                  className="fmc-input fds-layout-grid__cell--span-4"
                  style={{ width: 15 + 'em', marginTop: 0.5 + 'em' }}
                  type="text"
                  placeholder=""
                  name="wherePropertyStored"
                  value={formData.wherePropertyStored}
                  onChange={onChangeHandler}
                />
              </div>
              <div className="column form-group">
                <label>Name of storing contractor</label>
                <input
                  className="fmc-input fds-layout-grid__cell--span-4"
                  style={{ width: 15 + 'em', marginTop: 0.5 + 'em' }}
                  type="text"
                  placeholder=""
                  name="storingContractorName"
                  value={formData.storingContractorName}
                  onChange={onChangeHandler}
                />
              </div>
              <div className="column form-group">
                <label>I acknowledge return of property listed.</label>
                <input
                  className="fds-layout-grid__cell--span-4 sign"
                  style={{ width: 18 + 'em', marginTop: 2.5 + 'em' }}
                  type="text"
                  placeholder=""
                  name="customerNameDate"
                  value={formData.customerNameDate}
                  onChange={onChangeHandler}
                />
                <label htmlFor="inputField">Customer's Name and Date</label>
              </div>
            </div>
            <br />
            <p className="fds-align--center">PERSONAL PROPERTY & TRUST RECEIPT</p>
            <section style={{ padding: 2 + 'em' }}>
              <div className="fds-align--center">
                <button className="fmc-button" type="submit" style={{ marginInline: 2 + 'em' }}>
                  Continue
                </button>
                <button className="fmc-button" type="button" style={{ marginInline: 2 + 'em' }} onClick={resetFormData}>
                  Reset
                </button>
              </div>
            </section>
          </div>
        </fieldset>
      </form>
      <br />
    </>
  );
}

export default PersonalProperty;
