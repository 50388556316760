import './Input.css';

const Input = (props) => {
  const inputType = props.type || 'text';
  return (
    <>
      <input
        className={'fmc-input ' + props.className}
        placeholder={props.placeholder}
        maxLength={props.maxLength}
        value={props.value}
        onChange={props.onChange}
        name={props.name}
        pattern={props.pattern}
        type={inputType}
        readOnly={props.isReadOnly}
      />
    </>
  );
};

export default Input;
