import {
  createPinnacleContact,
  getAccountInfo,
  getCntrctrInfoForComments,
  getRepoCommonInfo,
} from '../api/commoninfo.api.js';
import * as Constants from '../constants/RepoReactConstants';

export const CommonDataService = {
  getAccountInfo: async (teamworkBpi, cntrctrNb) => {
    try {
      const accountInfoResponse = await getAccountInfo(teamworkBpi, cntrctrNb);

      // Assuming the response data is an array with a single object
      const dataArray = accountInfoResponse.data;

      if (Array.isArray(dataArray) && dataArray.length > 0) {
        // Return the first object from the array

        const data = { data: arrayToObject(dataArray), status: 200 };
        console.log('getAccountInfo data::::::::::::' + JSON.stringify(data));
        return data;
      } else {
        throw new Error('Data is not in expected format');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      return Promise.resolve([]); // Rethrow the error so the caller can handle it
    }
  },

  getRepoCommonInfo: async (teamworkBpi, cntrctrNb) => {
    try {
      //const response =  getAccountInfo(67211,31372)
      const commomRepoInfoResponse = await getRepoCommonInfo(teamworkBpi, cntrctrNb);

      // Assuming the response data is an array with a single object
      const dataArray = commomRepoInfoResponse.data;

      if (Array.isArray(dataArray) && dataArray.length > 0) {
        // Return the first object from the array
        return { data: arrayToObject(dataArray), status: 200 };
      } else {
        throw new Error('Data is not in expected format');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      return Promise.resolve([]); // Rethrow the error so the caller can handle it
    }
  },

  getCntrctrInfoForComments: async (userId) => {
    try {
      //const response =  getAccountInfo(67211,31372)
      const contractorInfoResponse = await getCntrctrInfoForComments(userId);

      // Assuming the response data is an array with a single object
      const dataArray = contractorInfoResponse.data;

      if (Array.isArray(dataArray) && dataArray.length > 0) {
        // Return the first object from the array
        return { data: arrayToObject(dataArray), status: 200 };
      } else {
        throw new Error('Data is not in expected format');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error; // Rethrow the error so the caller can handle it
    }
  },
  createPinnacleContact: async (pinncalelogobj) => {
    return createPinnacleContact(pinncalelogobj);
  },
};

export function getcommentStr(contractorInfoForComment, actioncomment, rsState, userInfo) {
  try {
    let companyName = contractorInfoForComment.cntrctrBusNm;
    let phoneNb = contractorInfoForComment.phnNb;
    let phnCityAreaCd = contractorInfoForComment.phnCityAreaCd;
    let commentInfo = '';
    let sUserId = userInfo;
    let sComment = actioncomment;

    let cntrcPhNb = '(' + phnCityAreaCd + ') ' + phoneNb;
    console.log(' suerId' + userInfo);

    let returnState = rsState;

    if (returnState === Constants.rsGROUNDED) {
      commentInfo = commentInfo.concat(sComment);
      console.log('comment information:::::::::::::' + commentInfo);

      return commentInfo;
    } else if (
      returnState === Constants.rsACCEPT ||
      returnState === Constants.rsHOLD_CONFIRM ||
      returnState === Constants.rsCANCEL_CONFIRM
    ) {
      commentInfo = commentInfo
        .concat(sComment)
        .concat(' ')
        .concat(sUserId)
        .concat(' ')
        .concat(companyName.trim())
        .concat(' ')
        .concat(cntrcPhNb.trim());
      console.log('comment info   info::::::::::::::::::', commentInfo);

      return commentInfo;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error; // Rethrow the error so the caller can handle it
  }
}

// current timestamp
export function getCurrentTimestamp() {
  const now = new Date();

  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // JavaScript months are 0-indexed
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
export function convertDateToISOString(dateString) {
  // Parse the date string to create a new Date object.
  const dateParts = dateString.split('-');
  const year = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10) - 1; // Month is 0-indexed
  const day = parseInt(dateParts[2], 10);

  // Create a new Date object with the specified date.
  const date = new Date(Date.UTC(year, month, day));

  // Set the time to 14:08:26.404
  date.setUTCHours(14, 8, 26, 404);

  // Convert the Date object to an ISO string.
  return date.toISOString();
}

const dateString = '2024-09-03';

export function getCurrentTimestampInISOFormat() {
  // Create a new Date object representing the current date and time.
  const currentDate = new Date();

  // Convert the Date object to an ISO string.
  return currentDate.toISOString();
}
export const fetchJsonObject = async () => {
  try {
    //const response =  getAccountInfo(67211,31372)
    const accountInfoResponse = await getAccountInfo(6211, 31372);

    // Assuming the response data is an array with a single object
    const dataArray = accountInfoResponse.data;

    if (Array.isArray(dataArray) && dataArray.length > 0) {
      // Return the first object from the array
      return dataArray[0];
    } else {
      throw new Error('Data is not in expected format');
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error; // Rethrow the error so the caller can handle it
  }
};
const personalDetail = {};
const arrayToObject = (array) =>
  array.reduce((obj, item) => {
    obj[item.id] = item; // Use the `id` as the key
    //console.log("arrayToObject::::::::::::" +item.custAcctNb);
    //console.log("arrayToObject::::::::::::",item.cntrctrBusNm);

    return item;
  }, {});
