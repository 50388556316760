import React from 'react';

function BuyerInfo(props) {
  const {buyerInfo} = props;

  return(
    <section className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white">
    <div className="fds-weight--bold">Buyer Information:</div>
    <div className="fds-layout-grid p-0">
      <div className="fds-layout-grid__inner p-0">
        <div className="fds-layout-grid__cell--span-2">Name:</div>
        <div className="fds-layout-grid__cell--span-10">
        {buyerInfo?.firstNm1 || buyerInfo?.firstNm2 || buyerInfo?.lastNm1
          ? `${buyerInfo?.firstNm1?.trim() || ''}${buyerInfo?.firstNm2 ? ` ${buyerInfo?.firstNm2?.trim()}` : ''}${buyerInfo?.lastNm1 ? ` ${buyerInfo?.lastNm1?.trim()}` : ''}`
          : buyerInfo?.coBusNm?.trim() || ''}
        </div>
      </div>
    </div>
    <div className="fds-layout-grid p-0">
      <div className="fds-layout-grid__inner p-0">
        <div className="fds-layout-grid__cell--span-2">Address:</div>
        <div className="fds-layout-grid__cell--span-4"> {buyerInfo?.addrLine1Tx?.trim() }
        {buyerInfo?.addrLine2Tx && (<><br/>{buyerInfo?.addrLine2Tx?.trim()}</>)}
        {(buyerInfo?.cityNm || buyerInfo?.stAbbrCd || buyerInfo?.postCd) && (<><br/></>)}
        {buyerInfo?.cityNm?.trim()}{buyerInfo?.stAbbrCd && `, ${buyerInfo?.stAbbrCd?.trim()}`}{buyerInfo?.postCd && ` ${buyerInfo?.postCd?.trim()}`}</div>
        <div className="fds-layout-grid__cell--span-1">Employer: </div>
        <div className="fds-layout-grid__cell--span-5">{buyerInfo?.emplrNm?.trim() }</div>
      </div>
    </div>
    <div className="fds-layout-grid p-0">
      <div className="fds-layout-grid__inner p-0">
        <div className="fds-layout-grid__cell--span-2">Phone:</div>
        <div className="fds-layout-grid__cell--span-4">{buyerInfo?.phoneNumber?.trim()}</div>
        <div className="fds-layout-grid__cell--span-1">Phone:</div>
        <div className="fds-layout-grid__cell--span-5">{buyerInfo?.empPh?.trim()}</div>
      </div>
    </div>
  </section>
  );
}

export default BuyerInfo;