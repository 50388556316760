import Header from '../../../Components/Header/Header';
import Footer from '../../../Components/Footer/Footer';
import RadioButton from '../../../Components/RadioButton/RadioButton';
import Button from '../../../Components/Button/Button';
import Select from '../../../Components/Select/Select';
import Input from '../../../Components/Input/Input';
import './ContractorProfile.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteCall, getCall } from '../../../Services/CommonService';
import { showAlert, showConfirm } from '../../../Services/CommonFunctions';
import { getUserInfoFromSession } from '../../../Services/session.mgmt';
import { getContractorData } from '../../../api/profile.api';

function ContractorProfile() {
  const [radioVal, setradioVal] = useState('');
  const [selectVal, setSelectVal] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const navigate = useNavigate();

  const [contractorList, setContractorList] = useState([]);
  const [count, setCount] = useState(0);
  const [temp150contractorNb, setTemp150contractorNb] = useState(0);
  const [temp105contractorNb, setTemp105contractorNb] = useState(0);

  const userInfo = getUserInfoFromSession();

  const transformArray = (arr) => {
    return arr.map((item) => ({
      name: item.cntrctrBusNm,
      value: item.cntrctrNb,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getContractorData(userInfo.countryCd);
        console.log(response.data);
        setContractorList(transformArray(response.data));
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [count]);

  function escapeRegExp(string) {
    return string.replace(/[.*+?${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  const filteredItems = contractorList.filter((item) => {
    const escapedQuery = escapeRegExp(searchTerm);
    const regex = new RegExp(escapedQuery, 'i'); // 'i' makes the search case-insensitive
    //console.log(regex)
    return regex.test(item.name);
  });

  const handleRadioChange = (event) => {
    setradioVal(event.target.value);
  };
  const handleSelectChange = (event) => {
    setSelectVal(event.target.value);
  };
  const handleFilterChange1 = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.getAttribute('data-value'));
  };
  const handleFilterChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const reset = () => {
    setradioVal('');
    setSelectVal('');
    setTemp150contractorNb(0);
    setTemp105contractorNb(0);
  };
  const validateContractor = async (contrNum) => {
    getCall('deleteContractor/validateZBPT150', { cntrctrNb: contrNum }).then((r) => {
      console.log(r.data);
      setTemp150contractorNb(r.data);
    });
    getCall('deleteContractor/validateZBPT105', { cntrctrNb: contrNum }).then((r) => {
      console.log(r.data);
      setTemp105contractorNb(r.data);
    });
    if (temp150contractorNb === Number(contrNum) || temp105contractorNb === Number(contrNum)) {
      await showAlert("CAN'T DELETE, THERE ARE OUTSTANDING WORKFLOW COURIERS FOR THIS REPO CONTRACTOR", 'warning');
    } else if (await showConfirm('Are you sure you want to Delete the Contractor?')) {
      await deleteCall(`deleteContractor/deleteContractor?cntrctrNb=${selectVal}`).then((r) => {
        console.log(r);
        if (r.status === 200) {
          showAlert('Contractor Deleted Successfully!!', 'success');
          reset();
          setCount(count + 1);
        } else {
          showAlert(r.message, 'error');
        }
      });
    }
  };

  const startClick = async () => {
    if (radioVal === '') {
      showAlert('Please select at least one of the choices', 'warning').then((r) => console.log(r));
    } else if (radioVal === 'A') {
      navigate(`/addcontractorprofile`);
    } else if (radioVal === 'D' || radioVal === 'M' || radioVal === 'MCA' || radioVal === 'V') {
      if (selectVal === '') {
        showAlert('Please Select a Contractor name', 'warning').then((r) => console.log(r));
      } else if (radioVal === 'D') {
        await validateContractor(selectVal);
        console.log('contractor number:  ' + selectVal);
      } else if (radioVal === 'M') {
        navigate(`/updateProfile/${selectVal}`);
      } else if (radioVal === 'MCA') {
        navigate(`/updateCoverage/${selectVal}`);
      } else if (radioVal === 'V') {
        navigate(`/viewCoverage/${selectVal}`);
      }
    }
  };

  const backClick = () => {
    navigate(`/userVerification`);
  };

  const clearClick = () => {
    setSearchTerm('');
  };

  const rbOptions = [
    { name: 'Add a profile', value: 'A' },
    { name: 'Delete a profile', value: 'D' },
    { name: 'Modify a profile', value: 'M' },
    { name: 'Modify Coverage Area', value: 'MCA' },
    { name: 'View Coverage Area', value: 'V' },
  ];

  return (
    <>
      <Header heading="Contractor Profile" summaryDisplay="none" profileDisplay="none" />

      <div className="margin10">
        <RadioButton
          label=""
          options={rbOptions}
          groupName="profileAction"
          className="marginTop0"
          onChange={handleRadioChange}
          radioVal={radioVal}
        />

        <table border="5" style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>Contractor Name</th>
              <th>Filter</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ width: '50%' }}>
                <Select
                  name="contractors"
                  id="contractorSelect"
                  options={filteredItems}
                  size="8"
                  className="heightAuto"
                  selectedVal={selectVal}
                  onChange={handleSelectChange}
                />
              </td>
              <td style={{ width: '50%' }}>
                <div>
                  Show Contractors:{' '}
                  <a href="#" data-value="" onClick={handleFilterChange1}>
                    All
                  </a>{' '}
                  <br />
                  <a href="#" data-value="^A" onClick={handleFilterChange1}>
                    A
                  </a>{' '}
                  <a href="#" data-value="^B" onClick={handleFilterChange1}>
                    B
                  </a>
                  &nbsp;
                  <a href="#" data-value="^C" onClick={handleFilterChange1}>
                    C
                  </a>{' '}
                  <a href="#" data-value="^D" onClick={handleFilterChange1}>
                    D
                  </a>
                  &nbsp;
                  <a href="#" data-value="^E" onClick={handleFilterChange1}>
                    E
                  </a>{' '}
                  <a href="#" data-value="^F" onClick={handleFilterChange1}>
                    F
                  </a>
                  &nbsp;
                  <a href="#" data-value="^G" onClick={handleFilterChange1}>
                    G
                  </a>{' '}
                  <a href="#" data-value="^H" onClick={handleFilterChange1}>
                    H
                  </a>
                  &nbsp;
                  <a href="#" data-value="^I" onClick={handleFilterChange1}>
                    I
                  </a>{' '}
                  <a href="#" data-value="^J" onClick={handleFilterChange1}>
                    J
                  </a>
                  &nbsp;
                  <a href="#" data-value="^K" onClick={handleFilterChange1}>
                    K
                  </a>{' '}
                  <a href="#" data-value="^L" onClick={handleFilterChange1}>
                    L
                  </a>
                  &nbsp;
                  <a href="#" data-value="^M" onClick={handleFilterChange1}>
                    M
                  </a>{' '}
                  <a href="#" data-value="^N" onClick={handleFilterChange1}>
                    N
                  </a>
                  &nbsp;
                  <a href="#" data-value="^O" onClick={handleFilterChange1}>
                    O
                  </a>{' '}
                  <a href="#" data-value="^P" onClick={handleFilterChange1}>
                    P
                  </a>
                  &nbsp;
                  <a href="#" data-value="^Q" onClick={handleFilterChange1}>
                    Q
                  </a>{' '}
                  <a href="#" data-value="^R" onClick={handleFilterChange1}>
                    R
                  </a>
                  &nbsp;
                  <a href="#" data-value="^S" onClick={handleFilterChange1}>
                    S
                  </a>{' '}
                  <a href="#" data-value="^T" onClick={handleFilterChange1}>
                    T
                  </a>
                  &nbsp;
                  <a href="#" data-value="^U" onClick={handleFilterChange1}>
                    U
                  </a>{' '}
                  <a href="#" data-value="^V" onClick={handleFilterChange1}>
                    V
                  </a>
                  &nbsp;
                  <a href="#" data-value="^W" onClick={handleFilterChange1}>
                    W
                  </a>{' '}
                  <a href="#" data-value="^X" onClick={handleFilterChange1}>
                    X
                  </a>
                  &nbsp;
                  <a href="#" data-value="^Y" onClick={handleFilterChange1}>
                    Y
                  </a>{' '}
                  <a href="#" data-value="^Z" onClick={handleFilterChange1}>
                    Z
                  </a>
                  &nbsp;
                </div>
                <div>
                  <Input
                    placeholder="Contractor Name"
                    className="width60"
                    value={searchTerm}
                    onChange={handleFilterChange}
                  />
                  <Button label="Clear" onClick={() => clearClick()} />
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div className="centerAlign">
          <Button label="Start" className="margin10px" onClick={() => startClick()} />
          <Button label="Back" className="margin10px" onClick={() => backClick()} />
        </div>
      </div>

      <Footer />
    </>
  );
}

export default ContractorProfile;
