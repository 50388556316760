import axios from 'axios';

const serviceAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVICE_BASE_URL,
});

// Add a request interceptor
serviceAxiosInstance.interceptors.request.use(
  function (config) {
    config.withCredentials = true;
    return config;
  },
  function (error) {
    // Do something with request error
    console.log('Error sending request === ' + JSON.stringify(error));
    return Promise.reject(error);
  }
);

// Add a response interceptor
serviceAxiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log('Error with response === ' + JSON.stringify(error));
    return Promise.reject(error);
  }
);
export default serviceAxiosInstance;
