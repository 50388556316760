import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../../Components/Header/Header';
import VCRsummary from './VCRsummary';
import PersonalProprtySum from './PersonalProprtySum';
import Invoicesummary from './Invoicesummary';
import AssignmentDetailsHeader from '../common/AssignmentDetailsHeader';
import swal from 'sweetalert';
import { AssignmentService } from '../../Services/AssignmentService';
import './Summary.css';
import { postCall } from '../../Services/CommonService';
import { getUserInfoFromSession } from '../../Services/session.mgmt';
import { PINNCALE_LOG_OBJ } from '../../constants/commonJsonConstants';
import {
  CommonDataService,
  convertDateToISOString,
  getcommentStr,
  getCurrentTimestamp,
  getCurrentTimestampInISOFormat,
} from '../../Services/CommonDataService';

import { AssignmentSummaryService } from '../../Services/AssignmentSummaryService';
import { profileService as ProfileService } from '../../Services/ProfileService';
import { ACTION_GROUNDING, rsGROUNDED } from '../../constants/RepoReactConstants';
import { FcnaDB2Postcall, updateAssignment } from '../../api/accountsummary.api';
import _ from 'lodash';

function Outstandingsummary() {
  const { teamWrkBpiNb } = useParams();
  const [vcrData, setVcrData] = useState({});
  const [vehLocData, setVehLocData] = useState({});
  const [invoiceTypes, setInvoiceTypes] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [ppData, setPpData] = useState([]);
  const [ppItems, setPpItems] = useState([]);
  const [buyerCoBuyerInfo, setBuyerCoBuyerInfo] = useState({});
  const [assignmentDetails, setAssignmentDetails] = useState([]);
  const [contractorPersonalDetails, setContractorPersonalDetails] = useState({});
  const [accountInformation, setAccountInformation] = useState([]);
  const [matchedEntries, setMatchedEntries] = useState([]);
  const [invoicetotal, setInvoiceTotal] = useState(0);
  const [cscmResponse, setCscmResponse] = useState([]);
  const [loading, setLoading] = useState(true);
  const [contractorInfoForComment, setContractorInfoForComment] = useState([]);
  const userInfo = getUserInfoFromSession();
  const navigate = useNavigate();

  const showAlert = (message, icon) => {
    swal({
      title: message,
      icon: icon,
    }).then(() => {
      navigate(`/outstandingAssignment`);
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          VCRResponse,
          VehLocResponse,
          GroundingResponse,
          InvoiceResponse,
          InvoiceTypesResponse,
          ppItemsResponse,
          AssignmentDetailsResponse,
          ContractorPersonalDetailsReposne,
          AccountInformationResponse,
          ContractorInfoForCommentResponse,
        ] = await Promise.all([
          AssignmentService.getVcrDetails(teamWrkBpiNb),
          AssignmentService.getVehLocDetails(teamWrkBpiNb),
          AssignmentService.getGroundingInfo(teamWrkBpiNb),
          AssignmentService.getInvoiceDetails(teamWrkBpiNb),
          AssignmentService.getInvoiceTypes(1),
          AssignmentService.getPpDetails(teamWrkBpiNb),
          AssignmentService.getAssignmentInfo(teamWrkBpiNb, userInfo.cntrctrNb),
          ProfileService.getContractorPersonal(userInfo.cntrctrNb),
          CommonDataService.getAccountInfo(teamWrkBpiNb, userInfo.cntrctrNb),
          CommonDataService.getCntrctrInfoForComments(userInfo.userid),
        ]);

        setVcrData(VCRResponse.data);
        console.log(VCRResponse.data);
        setVehLocData({
          vehlocTypCd: GroundingResponse?.data?.vehLocTypCd ?? '',
          auctCd: GroundingResponse?.data?.auctLocCd ?? '',
          auctDs: GroundingResponse?.data?.auctLocNm ?? '',
          teamWrkBpiNb: teamWrkBpiNb,
          addrLine1Tx: VehLocResponse?.data?.addrLine1Tx ?? '',
          addrLine2Tx: VehLocResponse?.data?.addrLine2Tx ?? '',
          cityNm: VehLocResponse?.data?.cityNm ?? '',
          custAcctNb: AssignmentDetailsResponse.data[0].custAcctNb ?? '',
          stAbbrCd: VehLocResponse?.data?.stAbbrCd ?? '',
          zipCd: VehLocResponse?.data?.zipCd ?? '',
          phnCityAreaCd: VehLocResponse?.data?.phnCityAreaCd ?? '',
          phnNb: VehLocResponse?.data?.phnNb ?? '',
          lstUpdtUserId: GroundingResponse?.data?.lstUpdtUserId ?? '',
          vehLocNm: VehLocResponse?.data?.vehLocNm ?? '',
        });
        setInvoiceData(InvoiceResponse.data);
        const invoiceTypeRespMapped = Object.keys(InvoiceTypesResponse.data).map((key) => ({
          key: Number(key),
          value: InvoiceTypesResponse.data[key],
        }));
        setInvoiceTypes(invoiceTypeRespMapped); //Map invoice data to invoice types
        setPpItems(ppItemsResponse.data);
        setAssignmentDetails(AssignmentDetailsResponse.data);
        console.log('........' + AssignmentDetailsResponse.data[0].custAcctNb.toString());

        console.log(
          `getBuyerCobuyer Params coBusCd: ${AccountInformationResponse?.data?.coBusCd} ` +
            `disorigBranchCd: ${AccountInformationResponse?.data?.discorigBranchCd} ` +
            `custAcctNb: ${AccountInformationResponse?.data?.custAcctNb}`
        );
        AssignmentService.getBuyerCobuyerInfo(
          AccountInformationResponse?.data?.coBusCd,
          AccountInformationResponse?.data?.discorigBranchCd,
          AccountInformationResponse?.data?.custAcctNb
        )
          .then((buyerInfoResp) => {
            console.log('getBuyerCobuyer response:', buyerInfoResp);
            setBuyerCoBuyerInfo(buyerInfoResp?.data);
          })
          .catch((error) => {
            console.error('AssignmentService.getBuyerCobuyerInfo error:: ', error);
          });

        AssignmentService.getDbPropertyLocStat(teamWrkBpiNb)
          .then((PPropertyResponse) => {
            console.log('getDbPropertyLocStat response:', PPropertyResponse);
            setPpData(PPropertyResponse.data);
          })
          .catch((error) => {
            console.error('AssignmentService.getDbPropertyLocStat error:: ', error);
          });

        setContractorPersonalDetails(() => {
          console.log(ContractorPersonalDetailsReposne.data);
          return ContractorPersonalDetailsReposne.data;
        });
        console.log('Account Information:', AccountInformationResponse.data);
        setAccountInformation(AccountInformationResponse.data);
        setContractorInfoForComment(ContractorInfoForCommentResponse.data);
        console.log(invoiceTypes.length);
        if (InvoiceResponse.data.length) {
          let totalAmount = 0;
          const matched = InvoiceResponse.data
            .map((invoice) => {
              const matchingItem = invoiceTypeRespMapped.find((item) => item.key.toString() === invoice.cmpssEntryCd);
              totalAmount = totalAmount + invoice.cntrctrChgAm;
              return matchingItem
                ? {
                    cmpssEntryCd: invoice.cmpssEntryCd,
                    cntrctrChgAm: invoice.cntrctrChgAm,
                    cmpssEntryDs: matchingItem.value,
                  }
                : null;
            })
            .filter(Boolean);
          setMatchedEntries(matched);
          setInvoiceTotal(totalAmount);
          console.log(totalAmount);
        }
      } catch (err) {
        console.log('Error on Fectching Outstanding Summary Details' + err.response);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  //  =========log pinnacle  ====================
  const logPinnacle = () => {
    //accountInformation.custAcctNb
    const pinnacleLogObj = {
      ...PINNCALE_LOG_OBJ,
      receivableAccountNumber: accountInformation.custAcctNb,
      coBusCd: accountInformation.coBusCd,
      createdTimeStamp: getCurrentTimestamp(),
      enteredBranchCode: accountInformation.superBranchCd,
      comment: getcommentStr(contractorInfoForComment, ACTION_GROUNDING, rsGROUNDED, userInfo.userid),
      createdBy: userInfo.userid,
    };
    console.log(' PINNCALE  LOG Object to Log before add comment :::::::', pinnacleLogObj);

    console.log(
      'comment String with pinnalce:::::::::::::::::::',
      getcommentStr(contractorInfoForComment, ACTION_GROUNDING, rsGROUNDED, userInfo.userid)
    );
    pinnacleLogObj.comment = getcommentStr(contractorInfoForComment, ACTION_GROUNDING, rsGROUNDED, userInfo.userid);
    console.log(' after comment set:::::::::::::::::::', JSON.stringify(pinnacleLogObj));

    if (process.env.REACT_APP_ENV != 'DEV') {
      CommonDataService.createPinnacleContact(pinnacleLogObj)
        .then((r) => {
          if (r.status == 204) {
            console.log('pinnacle Response ' + 'no data');
          }
        })
        .catch((err) => {
          console.log(`Error logging pinnacle contact: ${err}`);
        });
    } else {
      console.log('Pinncale Log Object in DEV ' + JSON.stringify(PINNCALE_LOG_OBJ));
    }
  };

  //=======================/ Insert or update YWFS350_VEHDISPOFU  - VehicleDisposal Followup====

  const insertOrUpdateYWFS350VEHDISPOFU = () => {
    const vehicleDisposalFollowupObj = {
      custAcctNb: accountInformation.custAcctNb,
      coBusCd: accountInformation.coBusCd,
      discorigBranch: accountInformation.discorigBranchCd,
      teamworkBPI: accountInformation.teamwrkBpiNb,
      vin: assignmentDetails[0]?.vinSerialNb,
      repoTrmDt: convertDateToISOString(vcrData.vcr.rcvrDt),
      mlDrvnNb: vcrData.vcr.vehMlgeNb,
      dispoRsnCd: 1,
      crtDt: getCurrentTimestampInISOFormat(),
      legalSaleDt: null,
      lgclDelIn: null,
      availPkDt: convertDateToISOString(vcrData.vcr.rcvrDt),
      lastUpdatedUserIdentificationCode: userInfo.userid,
      lastUpdatedTimestamp: getCurrentTimestampInISOFormat(),
    };

    console.log('VEHICLE_DISPOSAL_OBJ::' + JSON.stringify(vehicleDisposalFollowupObj));

    let cobusCode = accountInformation.coBusCd;
    let origBranch = accountInformation.discorigBranchCd;
    let customerAccoutNumber = accountInformation.custAcctNb;
    console.log('cobusCode :' + cobusCode);
    console.log('origBranch :' + origBranch);
    console.log('customerAccoutNumber :' + customerAccoutNumber);
    AssignmentSummaryService.getVechileDisposalInfo(customerAccoutNumber, cobusCode, origBranch)
      .then((r) => {
        if (r.data.custAcctNb == undefined) {
          console.log(' respone' + r.status);
          console.log('Insert vehicleDsiposalinfo');

          insertVehicleDisposalInfo(vehicleDisposalFollowupObj);
        } else {
          console.log('Update vehicleDsiposalinfo');
          AssignmentSummaryService.UpdateVehicleDisposalInfo(
            customerAccoutNumber,
            cobusCode,
            origBranch,
            vehicleDisposalFollowupObj
          )
            .then((r) => console.log(r.status))
            .catch((err) => {
              console.log(`Error in UpdateVehicleDisposalInfo: ${err}`);
            });
        }
      })
      .catch((err) => {
        // API Returns a 404 response when the record is not found in the table
        if (err.status === 404) {
          insertVehicleDisposalInfo(vehicleDisposalFollowupObj);
        } else {
          console.log(`Error in getVechileDisposalInfo: ${err}`);
        }
      });
  };

  const insertVehicleDisposalInfo = (vehicleDisposalFollowupObj) => {
    AssignmentSummaryService.CreateVehicleDisposalInfo(vehicleDisposalFollowupObj)
      .then((r) => console.log('insert call  response' + r.status))
      .catch((err) => {
        console.log(`Error in CreateVehicleDisposalInfo: ${err}`);
      });
  };

  const getArchiveData = async () => {
    console.log(invoiceData);
    let propertystatuscode = ppData?.result?.personalPropertyLocStat?.prsnPrptyStatCd;
    let archiveData = {
      buyerfirstname: buyerCoBuyerInfo?.firstNm1 ?? '',
      buyermiddleinitial: buyerCoBuyerInfo?.firstNm2 ?? '',
      buyerlastname: buyerCoBuyerInfo?.lastNm1 ?? '',

      custacctno: assignmentDetails[0].custAcctNb?.toString() || '',
      dor: vcrData?.vcr?.rcvrDt ?? '',
      vehyear: assignmentDetails[0]?.vehMdlYr.toString() ?? '',
      vehmake: assignmentDetails[0]?.makeDescTx ?? '',
      vehmodel: assignmentDetails[0]?.mdlDescTx ?? '',
      vin: assignmentDetails[0]?.vinSerialNb ?? '',
      preparedby: vcrData?.vcr?.prepByTx ?? '',
      preparedate: vcrData?.vcr?.prepDt ?? '',
      cntrBusNm: contractorPersonalDetails.result.cntrctrData2ApiList[0].cntrctrBusNm,
      addrlin1txt: contractorPersonalDetails.result.cntrctrData2ApiList[0].addrLine1Tx,
      cityNm: contractorPersonalDetails.result.cntrctrData2ApiList[0].cityNm,
      stateabbrCode: contractorPersonalDetails.result.cntrctrData2ApiList[0].stAbbrCd,
      zipcode: contractorPersonalDetails.result.cntrctrData2ApiList[0].zipCd,
      odometer: vcrData?.vcr?.vehMlgeNb?.toString() ?? '',
      ignitionkey: assignmentDetails[0]?.keyIgntnNb ?? '',
      keysInd: vcrData?.vcr?.keysInd ?? '',
      vehcolor: assignmentDetails[0]?.vehColorTx ?? '',
      gencond:
        vcrData.vcr.glsCndtnCd === 1
          ? 'GOOD'
          : vcrData.vcr.glsCndtnCd === 2
            ? 'SCRATCHED'
            : vcrData.vcr.glsCndtnCd === 3
              ? 'BROKEN'
              : '',
      mechanical:
        vcrData.vcr.mechCndtnCd === 1
          ? 'GOOD'
          : vcrData.vcr.mechCndtnCd === 3
            ? 'Average'
            : vcrData.vcr.mechCndtnCd === 2
              ? 'Poor'
              : '',
      glass_desc: vcrData?.vcr?.glsBrknDs ?? '',
      tire_lf:
        vcrData.vcr.lfTireCndtnCd === 1
          ? 'BALD'
          : vcrData.vcr.lfTireCndtnCd === 2
            ? 'GOOD'
            : vcrData.vcr.lfTireCndtnCd === 3
              ? 'NONE'
              : vcrData.vcr.lfTireCndtnCd === 4
                ? 'AVERAGE'
                : '',
      tire_rf:
        vcrData.vcr.rfTireCndtnCd === 1
          ? 'BALD'
          : vcrData.vcr.rfTireCndtnCd === 2
            ? 'GOOD'
            : vcrData.vcr.rfTireCndtnCd === 3
              ? 'NONE'
              : vcrData.vcr.rfTireCndtnCd === 4
                ? 'AVERAGE'
                : '',
      tire_rr:
        vcrData.vcr.rrTireCndtnCd === 1
          ? 'BALD'
          : vcrData.vcr.rrTireCndtnCd === 2
            ? 'GOOD'
            : vcrData.vcr.rrTireCndtnCd === 3
              ? 'NONE'
              : vcrData.vcr.rrTireCndtnCd === 4
                ? 'AVERAGE'
                : '',
      tire_lr:
        vcrData.vcr.lrTireCndtnCd === 1
          ? 'BALD'
          : vcrData.vcr.lrTireCndtnCd === 2
            ? 'GOOD'
            : vcrData.vcr.lrTireCndtnCd === 3
              ? 'NONE'
              : vcrData.vcr.lrTireCndtnCd === 4
                ? 'AVERAGE'
                : '',
      tire_sp:
        vcrData.vcr.spTireCndtnCd === 1
          ? 'BALD'
          : vcrData.vcr.spTireCndtnCd === 2
            ? 'GOOD'
            : vcrData.vcr.spTireCndtnCd === 3
              ? 'NONE'
              : vcrData.vcr.spTireCndtnCd === 4
                ? 'AVERAGE'
                : '',
      interior_desc: vcrData?.vcr?.intExplDs ?? '',
      body_body:
        vcrData.vcr.ovrlBodyCndtnCd === 1
          ? 'DENTED'
          : vcrData.vcr.ovrlBodyCndtnCd === 2
            ? 'GOOD'
            : vcrData.vcr.ovrlBodyCndtnCd === 3
              ? 'POOR'
              : vcrData.vcr.ovrlBodyCndtnCd === 4
                ? 'RUSTED'
                : vcrData.vcr.ovrlBodyCndtnCd === 5
                  ? 'AVERAGE'
                  : '',
      body_paint:
        vcrData.vcr.ovrlPntCndtnCd === 1
          ? 'DENTED'
          : vcrData.vcr.ovrlPntCndtnCd === 2
            ? 'GOOD'
            : vcrData.vcr.ovrlPntCndtnCd === 3
              ? 'POOR'
              : vcrData.vcr.ovrlPntCndtnCd === 4
                ? 'RUSTED'
                : vcrData.vcr.ovrlPntCndtnCd === 5
                  ? 'AVERAGE'
                  : '',
      body_bumpers:
        vcrData.vcr.ovrlBmprCndtnCd === 1
          ? 'DENTED'
          : vcrData.vcr.ovrlBmprCndtnCd === 2
            ? 'GOOD'
            : vcrData.vcr.ovrlBmprCndtnCd === 3
              ? 'POOR'
              : vcrData.vcr.ovrlBmprCndtnCd === 4
                ? 'RUSTED'
                : vcrData.vcr.ovrlBmprCndtnCd === 5
                  ? 'AVERAGE'
                  : '',
      drds_fqp: vcrData.vcr.cndtnPsfQpIn,
      drds_fd: vcrData.vcr.cndtnDsfDrIn,
      drds_rd: vcrData.vcr.cndtnDsrDrIn,
      drds_rqp: vcrData.vcr.cndtnDsrQpIn,
      drps_fqp: vcrData.vcr.cndtnDsfQpIn,
      drps_fd: vcrData.vcr.cndtnPsfDrIn,
      drps_rd: vcrData.vcr.cndtnPsrDrIn,
      drps_rqp: vcrData.vcr.cndtnPsrQpIn,
      drt_h: vcrData.vcr.cndtnHoodIn,
      drt_r: vcrData.vcr.cndtnRoofIn,
      drt_t: vcrData.vcr.cndtnTrnkIn,
      drb_f: vcrData.vcr.cndtnBumpfIn,
      drb_r: vcrData.vcr.cndtnBumprIn,
      rme: vcrData?.vcr?.msgEquipDs ?? 'NA',

      est_damage_amount: vcrData.vcr.dmgEstAm?.toString(),
      vrf_desc: vcrData?.vcr?.vehRcvrdFromNm ?? '',
      recfrom_addr: vcrData?.vcr?.vehRcvrdAddrTx ?? '',
      recfrom_city: vcrData?.vcr?.vehRcvrdCityNm ?? '',
      recfrom_state: vcrData?.vcr?.vehRcvrdStAbbrCd ?? '',
      recfrom_zip: vcrData?.vcr?.vehRcvrdZipCd ?? '',
      release_signed_desc: vcrData?.vcr?.cstRlsIn ?? '',

      authorities: vcrData?.vcr?.athNtfyNm ?? '',
      authorities_policedeptname: '',
      authorities_addr: vcrData?.vcr?.athNtfyAddrTx ?? '',
      authorities_city: vcrData?.vcr?.athNtfyCityNm ?? '',
      authorities_state: vcrData?.vcr?.athNtfyStAbbrCd ?? '',
      authorities_zip: vcrData?.vcr?.athNtfyZipCd ?? '',
      vcr_comments: vcrData?.vcr?.othCmtDs ?? '',
      vehloc: vehLocData?.vehlocTypCd ?? '',
      locName: vehLocData.auctDs ?? '',
      isNotAuction: vehLocData.vehlocTypCd === 'A' ? 'false' : 'true',
      vehlocnameis: vehLocData.vehlocTypCd === 'A' ? (vehLocData?.auctDs ?? '') : (vehLocData?.vehLocNm ?? ''),
      vehlocaddr1: vehLocData?.addrLine1Tx ?? '',
      vehlocaddr2: vehLocData?.addrLine2Tx ?? '',
      vehloccity: vehLocData?.cityNm ?? '',
      vehlocstate: vehLocData?.stAbbrCd ?? '',
      vehloczip: vehLocData?.zipCd ?? '',
      vehlocphonearea: vehLocData?.phnCityAreaCd ?? '',
      vehlocphone: vehLocData?.phnNb ?? '',

      amt1: 'Amount 1',
      desc1: 'Description 1',
      line01code: matchedEntries[0] ? matchedEntries[0].cmpssEntryDs : '',
      line01amount: matchedEntries[0] ? `$${matchedEntries[0].cntrctrChgAm}` : '',
      amt2: 'Amount 2',
      desc2: 'Description 2',
      line02code: matchedEntries[1] ? matchedEntries[1].cmpssEntryDs : '',
      line02amount: matchedEntries[1] ? `$${matchedEntries[1].cntrctrChgAm}` : '',
      amt3: 'Amount 3',
      desc3: 'Description 3',
      line03code: matchedEntries[2] ? matchedEntries[2].cmpssEntryDs : '',
      line03amount: matchedEntries[2] ? `$${matchedEntries[2].cntrctrChgAm}` : '',
      amt4: 'Amount 4',
      desc4: 'Description 4',
      line04code: matchedEntries[3] ? matchedEntries[3].cmpssEntryDs : '',
      line04amount: matchedEntries[3] ? `$${matchedEntries[3].cntrctrChgAm}` : '',
      amt5: 'Amount 5',
      desc5: 'Description 5',
      line05code: matchedEntries[4] ? matchedEntries[4].cmpssEntryDs : '',
      line05amount: matchedEntries[4] ? `$${matchedEntries[4].cntrctrChgAm}` : '',
      amt6: 'Amount 6',
      desc6: 'Description 6',
      line06code: matchedEntries[5] ? matchedEntries[5].cmpssEntryDs : '',
      line06amount: matchedEntries[5] ? `$${matchedEntries[5].cntrctrChgAm}` : '',
      amt7: 'Amount 7',
      desc7: 'Description 7',
      line07code: matchedEntries[6] ? matchedEntries[6].cmpssEntryDs : '',
      line07amount: matchedEntries[6] ? `$${matchedEntries[6].cntrctrChgAm}` : '',

      recovered: _.isEmpty(ppItems) ? 'N' : 'Y',
      propReturned: propertystatuscode === 'R' ? 'Y' : propertystatuscode ? 'No' : '',
      propDisposed: propertystatuscode === 'D' ? 'Y' : propertystatuscode ? 'No' : '',
      item01: '',
      item02: '',
      item03: '',
      item04: '',
      item05: '',
      item06: '',
      item07: '',
      item08: '',
      item09: '',
      item10: '',
      item11: '',
      item12: '',
      item13: '',
      item14: '',
      item15: '',
      storagelocation: ppData?.result?.personalPropertyLocStat?.prsnPrptyLocTx ?? '',
    };

    for (let i = 0; i < ppItems.length; i++) {
      if (i < 15) {
        // Ensure we do not exceed the output object properties
        const itemKey = `item0${i + 1}`; // Create dynamic key like item01, item02, ...
        archiveData[itemKey] = ppItems[i].itmNm; // Set the item name from input
      }
    }

    console.log('dynamic archivedata ' + JSON.stringify(archiveData));
    try {
      let userId = userInfo.userid;

      //const buyerArrayResponse = await AssignmentSummaryService.getVCRPDFbytes(archiveData);
      const cscmcallResponse = await AssignmentSummaryService.uploadGiv(archiveData, userId);
      setCscmResponse(cscmcallResponse);
      //setVcrbytearray(buyerArrayResponse.data);
      handleResponse(cscmcallResponse);
      // let cscmResponsesucessmsg = cscmcallResponse.data.sucessResponse.message;

      // let cscmResponsemsg = cscmcallResponse.data.errorResponse.message;
      console.log(cscmcallResponse);
      // console.log("cscmResponsemsg::",cscmResponsemsg);
    } catch (err) {
      console.log(`Eroor on getVcrPdfBytes: ${err}`);
    }
  };

  function handleResponse(cscmcallResponse) {
    const responseData = cscmcallResponse.data;

    if (responseData.sucessResponse) {
      // Handle SuccessResponse
      const successResponse = responseData.sucessResponse;
      console.log('Success Response:', successResponse);
      // console.log("Data:", successResponse.data);
      //console.log("Message:", successResponse.message);
      showAlert(successResponse.message, 'success');
    } else if (responseData.errorResponse) {
      // Handle ErrorResponse
      const errorResponse = responseData.errorResponse;
      console.error('Error Response:', errorResponse);
      console.log('Error Message:', errorResponse.message);
      console.log('Status:', errorResponse.status);
      showAlert(errorResponse.message, 'Error');
    } else {
      // If neither success nor error response is present
      console.warn('No valid response received.');
    }
  }
  const handleSumbit = async () => {
    console.log('Vehicle grounded!');
    console.log('VCR Data:', JSON.stringify(vcrData));
    console.log('VehLoc Data:', JSON.stringify(vehLocData));
    console.log('Invoice Data:', JSON.stringify(matchedEntries));
    console.log('Invoice Total:', JSON.stringify(invoicetotal));
    console.log('PersonalProperty Data:', JSON.stringify(ppData));
    console.log('Personal Property Item:', JSON.stringify(ppItems));
    console.log('Buyer Info:', JSON.stringify(buyerCoBuyerInfo));
    try {
      getArchiveData();
      console.log(cscmResponse);

      insertOrUpdateYWFS350VEHDISPOFU();
      postCall(
        'SqlMgr/insertCPkupLog?actnAm=' +
          invoicetotal +
          '&actnCd=8&bpiNb=' +
          teamWrkBpiNb +
          '&cntrctrNb=' +
          userInfo.cntrctrNb +
          '&userId=' +
          userInfo.userid
      ).then((response) => console.log(response.status));

      const updateAssignmentResponse = await updateAssignment({
        confirm: 'Y',
        agentAction: 'G',
        supApproval: 'Y',
        csrAction: 'G',
        userId: userInfo.userid,
        bpiNb: teamWrkBpiNb,
        cntrctrNb: userInfo.cntrctrNb,
      });

      console.log('Update Assignment response: ' + updateAssignmentResponse);

      const FcnaDB2PostcallResponse = await FcnaDB2Postcall({
        acctNb: assignmentDetails[0]?.custAcctNb,
        coBusCd: 1,
        transTypeCd: 21,
        sourceSystemCode: 12,
        lastUpdatedUserIdentificationCode: userInfo.userid,
        transactionDataText: teamWrkBpiNb + ';GROUNDED',
        isImmediate: true,
      });

      logPinnacle();

      showAlert('Vehicle Grounded Successfully!', 'success');
    } catch (err) {
      console.log(`Error on cscm multipart Upload: ${err}`);
      showAlert('Error when grounding vehicle', 'error');
    }
  };
  if (loading)
    return (
      <>
        <Header heading="" />
        <br />
        Loading...
      </>
    );
  return (
    <div>
      <br />
      <div className="center">
        <h2>Outstanding Assignments Summary</h2>
      </div>
      <div className="details_cntr_summmary">
        <Header heading="" />
        <br />
        <AssignmentDetailsHeader bpiNo={teamWrkBpiNb} />
        <br />
        <fieldset disabled>
          <VCRsummary teamWrkBpiNb={teamWrkBpiNb} />
          <PersonalProprtySum ppDetails={ppData} ppItems={ppItems} />
          <Invoicesummary teamWrkBpiNb={teamWrkBpiNb} />
        </fieldset>
        <div className="center" style={{ padding: '10px' }}>
          <button type="submit" className="fmc-button" onClick={handleSumbit}>
            Submit
          </button>
        </div>
        <div className="fmc-bottom-banner__content center copy-rightbanner">
          Copyright &copy; 2024 Ford Motor Credit Company. All Rights Reserved
        </div>
      </div>
    </div>
  );
}

export default Outstandingsummary;
