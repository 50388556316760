import {
  createContractorContact,
  createContractorIdDetails,
  createPriceStruct,
  deleteCANPriceStruct,
  deleteContactsByCntrctrNb,
  deleteContractorIdDetails,
  deletePriceStructByUpdateD,
  deleteUSAPriceStruct,
  getBuzCenters,
  getContactCodes,
  getContractorByUserId,
  getContractorContacts,
  getContractorFeePriceStruct,
  getContractorIdDetailsByCntrctrNb,
  getContractorPersonal,
  getContractorIdDetails,
  isContractorAvailable,
  updatePersonalDetail,
  updateProfilePriceStruct,
} from '../api/profile.api';
import _ from 'lodash';

export const profileService = {
  getContractorProfile: async (userId) => {
    return getContractorByUserId(userId);
  },
  getContractorPersonal: async (contractorNb) => {
    return getContractorPersonal(contractorNb);
  },
  getContractorIdDetails: async (contractorNb) => {
    return getContractorIdDetails(contractorNb);
  },
  getContractorPriceStructs: async (contractorNb) => {
    return getContractorFeePriceStruct(contractorNb);
  },
  getContractContactsByCntrctrNb: async (contractorNb) => {
    try {
      const contactResponse = await getContractorContacts(contractorNb);
      // if(contactResponse.data) {
      //     _.map(contactTemplateList, cTemplate => {
      //         _.forEach(contactResponse.data, cResp => {
      //             if(cTemplate.cntctSqncNb === cResp.cntctSqncNb && cTemplate.cntctCd === cResp.cntctCd){
      //                 cTemplate.phnCityAreaCd = cResp.phnCityAreaCd;
      //                 cTemplate.phnNb = cResp.phnNb;
      //             }
      //         });
      //     });
      // }
      return { data: contactResponse.data, status: 200 };
    } catch (e) {}
  },
  getBuzCenters: async () => {
    return getBuzCenters();
  },
  updatePersonalDetail: async (personalDetail) => {
    personalDetail['cntrctrNb'] = Number(personalDetail.cntrctrNb);
    return updatePersonalDetail(personalDetail);
  },
  updatePriceStruct: async (priceStructList, cntrctrNb, cntryCd) => {
    let recordsProcessed = 0;
    _.forEach(priceStructList, (priceStruct) => {
      console.log('updatePriceStructor : ' + JSON.stringify(priceStruct));
      if (priceStruct.status === 'remove') {
        if (cntryCd === 'CAN') {
          recordsProcessed += deleteCANPriceStruct(Number(cntrctrNb), priceStruct.prcStrctNb);
        } else {
          recordsProcessed += deleteUSAPriceStruct(Number(cntrctrNb), priceStruct.prcStrctNb);
        }
        deletePriceStructByUpdateD(priceStruct, Number(cntrctrNb));
      } else if (priceStruct.status === 'new') {
        delete priceStruct.status;
        delete priceStruct.effDtStr;
        priceStruct.prcStrctDelCd = ' ';
        recordsProcessed += createPriceStruct({ ...priceStruct, cntrctrNb: Number(cntrctrNb) });
      } else {
        delete priceStruct.status;
        recordsProcessed += updateProfilePriceStruct({ ...priceStruct, cntrctrNb: Number(cntrctrNb) });
      }
    });
    return recordsProcessed;
  },
  updateContacts: async (contacts, cntrctrNbIn, userId) => {
    deleteContactsByCntrctrNb(cntrctrNbIn).then((value) => {
      _.forEach(contacts, (contact) => {
        if (contact.phnCityAreaCd.length === 3 && contact.phnNb.length === 7)
          createContractorContact({
            ...contact,
            cntrctrNb: Number(cntrctrNbIn),
            lstUpdtUserId: userId,
          });
      });
    });
  },
  isContractorAvailable: async (userId) => {
    return isContractorAvailable(userId);
  },
  getContractorIdDetailsByCntrctrNb: async (contractNb) => {
    return getContractorIdDetailsByCntrctrNb(contractNb);
  },
  saveContractorIdDetails: async (idDetail) => {
    deleteContractorIdDetails(idDetail.cntrctrNb).then((value) => {
      // 200 means contractor id was deleted
      if (value.status === 200) {
        createContractorIdDetails(idDetail);
      }
    });
  },
  getPhoneCodes: async () => {
    return getContactCodes();
  },
};
