import React, { useEffect, useState } from 'react';
import Header from '../../Components/Header/Header';
import './Misc.css';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../../Components/Footer/Footer';
import { AssignmentService } from '../../Services/AssignmentService';
import { getUserInfoFromSession, setUserInfoToSession } from '../../Services/session.mgmt';
import { differenceInDays, parse } from 'date-fns';
import ExpirationNotification from '../../Components/ExpirationNotification';
import { DATE_FORMAT } from '../../constants/RepoReactConstants';

function Misc() {
  const companyName = 'Ford Motor Credit Company';
  const companyPlace = 'Collections Center of Excellence (COE)';
  const companyZipCode = 'PO Box 64400';
  const cityPlace = 'Colorado Springs, CO 80962-4400';

  const companyPhoneNumber = 'Phone: 866-832-4720';
  const companyMaiID = 'E-Mail: webrepo@ford.com';
  const [showExpireWarning, setShowExpireWarning] = useState(false);
  const userInfo = getUserInfoFromSession();
  let navigate = useNavigate();

  useEffect(() => {
    const updateUserInfo = async () => {
      try {
        const contractorProfileResponse = await AssignmentService.getContractorProfile(userInfo.userid);
        let newUserInfo = userInfo;
        newUserInfo['cntrctrNb'] = contractorProfileResponse?.data[0]?.cntrctrNb;
        newUserInfo['countryCd'] = contractorProfileResponse?.data[0]?.cntryCd;
        newUserInfo['rdnInd'] = contractorProfileResponse?.data[0]?.rdnInd;
        setUserInfoToSession(newUserInfo);
      } catch (err) {
        console.log(`Error on getting Contractor Profile for misc : ${err}`);
      }
    };

    updateUserInfo();
  }, []);

  const routeChange = async () => {
    navigate(`/outstandingAssignment`);
  };

  const checkExpiration = async () => {
    const currDate = new Date();
    const buzLcnsExpireDate = parse(userInfo.buzLcnsExpireDate, DATE_FORMAT, new Date());
    const insuranceExpireDate = parse(userInfo.insuranceExpireDate, DATE_FORMAT, new Date());
    if (
      (differenceInDays(insuranceExpireDate, currDate) <= 30 && differenceInDays(insuranceExpireDate, currDate) >= 0) ||
      (differenceInDays(buzLcnsExpireDate, currDate) <= 30 && differenceInDays(buzLcnsExpireDate, currDate) >= 0)
    ) {
      setShowExpireWarning(true);
    } else {
      routeChange();
    }
  };

  if (showExpireWarning) return <ExpirationNotification uInfo={userInfo} clickCallback={routeChange} />;

  return (
    <div>
      <Header value="none" />

      <div className="details_cntr">
        <div className="details">
          <h2>Welcome to the Ford Credit</h2>
          <br />
          <h2> Repossession Contractor Website </h2>
        </div>
        <br />

        <div className="fds-layout-grid fmc-pa-3 dividerdiv">
          <div className="fds-layout-grid__inner">
            <div
              className="
      fds-layout-grid__cell--span-12
      fmc-pb-3
      fmc-pb-5-md
      fmc-divider--bottom
    "
            >
              {/* <h3 class="fmc-type--heading3">Related Content</h3> */}
              <div className="details">
                {' '}
                <span>Whom to Contact... </span> <br />
                <span>{companyName} </span> <br />
                <span>{companyPlace} </span> <br />
                <span>{companyZipCode} </span> <br />
                <span>{cityPlace}</span> <br />
                <span>{companyPhoneNumber} </span> <br />
                <span>{companyMaiID}</span> <br />
              </div>
              <div className="concur_contain fds-weight--bold"></div>
            </div>
            <div
              className="
      fds-layout-grid__cell--span-12
      fmc-pt-1-md
    "
            >
              <div className="details">
                {' '}
                <span className="termcond ">
                  By clicking on the "I Concur" button below, I agree to the following:{' '}
                </span>{' '}
                <br />{' '}
              </div>
              <div className="para">
                <span>
                  For every vehicle repossessed based on an assignment through the Ford Credit Repossession Contractor
                  Web Page, I will provide Ford Credit with a Vehicle Condition Report, Invoice and Personal Property
                  Report. These documents will be submitted through the Ford Credit Repossession Contractor Web Page,
                  within 12 hours of the vehicle being recovered.
                </span>
              </div>

              <div>
                <div className="para">
                  <ul className="fmc-list">
                    <li> Vehicle Condition Report</li>
                    <li> Invoice</li>
                    <li> Property Report</li>
                  </ul>
                </div>
              </div>

              <div className="para">
                <span>
                  {' '}
                  I will complete the{' '}
                  <span className="tranining">
                    <Link to="https://repoweb-pit.apps.pp01i.edc1.cf.ford.com/training/training.htm">
                      online training
                    </Link>{' '}
                  </span>
                  before I use this Web Page to receive repossession assignments. All information viewed and/or
                  submitted via this website is subject to the terms and conditions of your Independent Contractor
                  Agreement - Repossession and your Supplier Privacy Assessment with Ford Motor Credit Company LLC, Ford
                  Credit Canada Company or Canadian Road Leasing Company.
                </span>
              </div>
              <div className="details">
                <fmc-button label="I CONCUR" outlined onClick={checkExpiration}></fmc-button>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Misc;
