export const PARAM_KEY = {
  session: {
    KEY1: 'USER_INFO',
    KEY2: 'BUYER_INFO',
    KEY3: 'ASSIGNMENT_INFO',
    KEY4: 'PPROPERTY_INFO',
    KEY_EXPIRE: 'USER_INFO_EXPIRATION',
  },
};

export default PARAM_KEY;
