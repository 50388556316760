import {
  getProvinces,
  getStates,
  getTireConditionCodes,
  getGeneralConditionCodes,
  getOverallConditionCodes,
  getMechanicalConditionCodes,
  getGlassConditionCodes,
  getInteriorConditionCodes,
  getVcrObj,
  getVehLocObj,
  saveVcr,
  saveVehLoc,
  updateAuctionGrounding,
  updateNonAuctionGrounding,
  InsWorkflowCommentForWF2,
  getAuctionLocationList,
} from '../api/vcr.api';
import _ from 'lodash';

export const VCRService = {
  getStateOrProvinceByStAbbr: async (countryCd) => {
    let stateOrProvince = [];
    if (countryCd === 'CAN') {
      await getProvinces().then((res) => {
        stateOrProvince = res.data.map((prv) => {
          return {
            stAbbrCd: prv.provinceAbbrCode,
          };
        });
      });
    } else {
      await getStates().then((res) => {
        stateOrProvince = res.data.map((prv) => {
          return {
            stAbbrCd: prv.stateabbrCode,
          };
        });
      });
    }
    _.sortBy(stateOrProvince, ['stAbbrCd']);
    return Promise.resolve(stateOrProvince);
  },
  getTireCondition: async () => {
    return getTireConditionCodes();
  },
  getOverallCondition: async () => {
    return getOverallConditionCodes();
  },
  getGeneralCondition: async () => {
    return getGeneralConditionCodes();
  },

  getInteriorCondition: async () => {
    return getInteriorConditionCodes();
  },
  getGlassCondition: async () => {
    return getGlassConditionCodes();
  },
  getMechanicalCondition: async () => {
    return getMechanicalConditionCodes();
  },
  getVcrObj: async () => {
    return getVcrObj();
  },
  getVehLocObj: async () => {
    return getVehLocObj();
  },
  saveVcr: async (vcrData) => {
    return saveVcr(vcrData);
  },
  saveVehLoc: async (vehLocData) => {
    return saveVehLoc(vehLocData);
  },
  InsWorkflowCommentForWF2: async (wf2Obj) => {
    return InsWorkflowCommentForWF2(wf2Obj);
  },
  updateAuctionGrounding: async (auctionData) => {
    return updateAuctionGrounding(auctionData);
  },
  updateNonAuctionGrounding: async (nonAuctionData) => {
    return updateNonAuctionGrounding(nonAuctionData);
  },
  getAuctionLocationList: async (country) => {
    return getAuctionLocationList(country);
  },
};
