import React, { useEffect, useState } from 'react';
import './invoice.css';
import { getCall, postCall } from '../../Services/CommonService';
import * as Constants from '../../constants/RepoReactConstants';

import AssignmentDetailsHeader from '../common/AssignmentDetailsHeader';
import Swal from 'sweetalert2';
import { getUserInfoFromSession } from '../../Services/session.mgmt';
import { InvoiceService } from '../../Services/InvoiceService';
import { showAlert } from '../../Services/CommonFunctions';
import { AssignmentService } from '../../Services/AssignmentService';
import '@ford/ford-design-system/dist/web-components.js';

function InvoicePage(props) {
  const teamWrkBpiNb = props.bpiNo;
  const [invoiceTypeList, setInvoiceTypeList] = useState([]);
  const [loading, setLoading] = useState(true);
  const userInfo = getUserInfoFromSession();
  let valid = true;
  let data = [];
  let errorMessage = '';
  let totalAmount = 0;
  const [formData, setFormData] = useState({
    invoice1: '',
    invoice2: '',
    invoice3: '',
    invoice4: '',
    invoice5: '',
    invoice6: '',
    invoice7: '',
    amount1: '',
    amount2: '',
    amount3: '',
    amount4: '',
    amount5: '',
    amount6: '',
    amount7: '',
  });
  const onChangeHandler = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [event.target.name]: event.target.value,
    }));
  };

  function isCurrency(s) {
    let reCurrency1 = /^\d+$/;
    let reCurrency2 = /^\.\d$/;
    let reCurrency3 = /^\.\d\d$/;
    let reCurrency4 = /^\d+\.$/;
    let reCurrency5 = /^\d+\.\d$/;
    let reCurrency6 = /^\d+\.\d\d$/;
    return (
      reCurrency1.test(s) ||
      reCurrency2.test(s) ||
      reCurrency3.test(s) ||
      reCurrency4.test(s) ||
      reCurrency5.test(s) ||
      reCurrency6.test(s)
    );
  }

  function isEmpty(s) {
    return s == null || s.length == 0;
  }
  function validateinvoice(formData) {
    errorMessage = '';
    for (let idx = 1; idx <= 7; idx++) {
      let key1 = 'invoice' + idx.toString();
      let key2 = 'amount' + idx.toString();
      let invCode = formData[key1]?.toString() || '';
      let invAmt = formData[key2]?.toString() || '';

      if (invCode === '' && invAmt === '' && idx !== 1) {
        break;
      }

      if (!validateLine(invCode, invAmt, idx)) {
        break;
      }

      if (valid && invAmt > 0 && invCode > 0) {
        addInvoiceData(idx, invCode, invAmt);
      }
    }
  }

  function validateLine(invCode, invAmt, idx) {
    if (invCode === '' || invAmt === '') {
      errorMessage += 'Line ' + idx + ' is incomplete.\n';
      valid = false;
      return false;
    }

    if (!isEmpty(invAmt) && !(isCurrency(invAmt) && parseFloat(invAmt) > 0)) {
      errorMessage += 'Line ' + idx + ' amount is not valid.\nIt must be an amount greater than 0.\n';
      valid = false;
      return false;
    }

    return true;
  }

  function addInvoiceData(idx, invCode, invAmt) {
    console.log('ready to insert condition added');
    let invoicedata = {
      teamwrkBpiNb: Number(teamWrkBpiNb),
      cntrctrNb: userInfo.cntrctrNb,
      chgSqncNb: idx,
      cmpssEntryCd: invCode,
      cntrctrChgAm: parseFloat(invAmt),
      lstUpdtUserId: userInfo.userid,
    };
    data.push(invoicedata);
    totalAmount = totalAmount + parseFloat(invAmt);
  }
  //==========End  of  Invoice validation
  // to filter invoice call:
  function saveInvoicedata(dataret) {
    let hasDuplicates = false;
    const cmpssEntryCdCounts = {};
    for (const item of dataret) {
      const code = item.cmpssEntryCd;
      if (cmpssEntryCdCounts[code]) {
        cmpssEntryCdCounts[code]++;
        hasDuplicates = true;
      } else {
        cmpssEntryCdCounts[code] = 1;
      }
    }
    if (!hasDuplicates) {
      let filteredArrayValues = dataret
        .filter((item) => item.cntrctrChgAm > 0 && item.cmpssEntryCd != '')
        .map((item) => {
          postinvoicecall(item);
        });
      console.log('filterapplied' + filteredArrayValues);
      return true;
    } else {
      Swal.fire({
        width: '500px',
        title: 'warning',
        position: 'top',
        text: 'Invoice Type should not be duplicate!!',
        color: '#00095b',
        iconColor: '#00095b',
        icon: 'warning',
        confirmButtonText: 'OK',
        confirmButtonColor: '#00095b',
      });
      return false;
    }
  }

  const handleSubmit = (e) => {
    console.log(formData);
    validateinvoice(formData);
    if (valid) {
      console.log(data);
      if (saveInvoicedata(data)) {
        console.log('totalamount' + totalAmount);
        postCall(
          'SqlMgr/insertCPkupLog?actnAm=' +
            totalAmount +
            '&actnCd=4&bpiNb=' +
            teamWrkBpiNb +
            '&cntrctrNb=' +
            userInfo.cntrctrNb +
            '&userId=' +
            userInfo.userid
        ).then((response) => console.log(response.status));
        handleReset();
        props.onContinue();
      }
    } else {
      if (errorMessage !== '') {
        Swal.fire({
          width: '500px',
          title: 'warning',
          position: 'top',
          text: errorMessage,
          color: '#00095b',
          iconColor: '#00095b',
          icon: 'warning',
          confirmButtonText: 'OK',
          confirmButtonColor: '#00095b',
        });
      }
    }
  };
  //to insert invoice
  function postinvoicecall(postinvoicedata) {
    try {
      console.log(postinvoicedata);
      const response = InvoiceService.saveInvoice(postinvoicedata);
      console.log('Invoice Saved Successfully!!');
      console.log(response.data);
      showAlert('Invoice Saved Successfully!!', 'success');
      return true;
    } catch (err) {
      console.error(err);
      showAlert(err.message, 'error');
    }
  }

  //To reset  Invoice page text fileds
  const handleReset = (e) => {
    setFormData({
      invoice1: '',
      invoice2: '',
      invoice3: '',
      invoice4: '',
      invoice5: '',
      invoice6: '',
      invoice7: '',
      amount1: '',
      amount2: '',
      amount3: '',
      amount4: '',
      amount5: '',
      amount6: '',
      amount7: '',
    });
  };

  useEffect(() => {
    const fetchInvoiceData = async () => {
      try {
        console.log(`InvoicePage useEffect, prodCd = ${props.prodCd}`);
        if (props.prodCd) {
          const response = await AssignmentService.getInvoiceTypes(props.prodCd);
          console.log('InvoicePage useEffect, InvoiceTypes = ', response.data);
          setInvoiceTypeList(response.data);

          let response1 = await AssignmentService.getInvoiceDetails(teamWrkBpiNb);
          const getInvoice = response1.data;
          console.log('InvoicePage useEffect, InvoiceDetails = ', getInvoice);
          if (getInvoice.length > 0) {
            setFormData(() => ({
              invoice1: getInvoice[0]?.cmpssEntryCd ?? '',
              invoice2: getInvoice[1]?.cmpssEntryCd ?? '',
              invoice3: getInvoice[2]?.cmpssEntryCd ?? '',
              invoice4: getInvoice[3]?.cmpssEntryCd ?? '',
              invoice5: getInvoice[4]?.cmpssEntryCd ?? '',
              invoice6: getInvoice[5]?.cmpssEntryCd ?? '',
              invoice7: getInvoice[6]?.cmpssEntryCd ?? '',
              amount1: getInvoice[0]?.cntrctrChgAm ?? '',
              amount2: getInvoice[1]?.cntrctrChgAm ?? '',
              amount3: getInvoice[2]?.cntrctrChgAm ?? '',
              amount4: getInvoice[3]?.cntrctrChgAm ?? '',
              amount5: getInvoice[4]?.cntrctrChgAm ?? '',
              amount6: getInvoice[5]?.cntrctrChgAm ?? '',
              amount7: getInvoice[6]?.cntrctrChgAm ?? '',
            }));
          }
        } else {
          console.log('ProdCd missing. Skipping Invoice API Calls');
        }
      } catch (err) {
        console.error('Invoice Page API calls failed. ' + err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchInvoiceData();
  }, [props.activeTab == 3]);

  return (
    <div id="details_tab_cntr">
      <div className="details_cntr">
        <br />
        <AssignmentDetailsHeader bpiNo={teamWrkBpiNb} />
        <br />
        <div style={{ padding: '10px' }} className="center">
          <h2>ASSIGNMENT INVOICE</h2>
        </div>
        <section className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white">
          <div className="center">
            <table class="fmc-table fmc-table--no-borders">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Description:</th>
                  <th>Amount:</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>
                    <select
                      name="invoice1"
                      id="invoice-select"
                      value={formData.invoice1}
                      onChange={onChangeHandler}
                      class="fmc-select"
                      style={{ width: 'fit-content' }}
                    >
                      <option>Please Choose invoiceType</option>
                      {Object.entries(invoiceTypeList)
                        .reverse()
                        .map(([key, value]) => (
                          <option value={key} key={key}>
                            <strong>{value}</strong>
                          </option>
                        ))}
                    </select>
                  </td>
                  <td>
                    <div style={{ display: 'flex' }}>
                      {' '}
                      <div> $</div>
                      <fmc-input name="amount1" value={formData.amount1} onInput={onChangeHandler}></fmc-input>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>
                    <select
                      name="invoice2"
                      id="invoice-select"
                      class="fmc-select"
                      style={{ width: 'fit-content' }}
                      value={formData.invoice2}
                      onChange={onChangeHandler}
                    >
                      {/* invoice list */}
                      <option>Please Choose invoiceType</option>
                      {Object.entries(invoiceTypeList)
                        .reverse()
                        .map(([key, value]) => (
                          <option value={key} key={key}>
                            <strong>{value}</strong>
                          </option>
                        ))}
                    </select>
                  </td>
                  <td>
                    <div style={{ display: 'flex' }}>
                      {' '}
                      <div> $</div>
                      <fmc-input
                        name="amount2"
                        id="textinvoice"
                        value={formData.amount2}
                        onInput={onChangeHandler}
                      ></fmc-input>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>
                    <select
                      name="invoice3"
                      id="invoice-select"
                      class="fmc-select"
                      style={{ width: 'fit-content' }}
                      value={formData.invoice3}
                      onChange={onChangeHandler}
                    >
                      <option value="">Please Choose invoiceType</option>

                      {Object.entries(invoiceTypeList)
                        .reverse()
                        .map(([key, value]) => (
                          <option value={key} key={key}>
                            <strong>{value}</strong>
                          </option>
                        ))}
                    </select>
                  </td>
                  <td>
                    <div style={{ display: 'flex' }}>
                      {' '}
                      <div> $</div>
                      <fmc-input name="amount3" value={formData.amount3} onInput={onChangeHandler}></fmc-input>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>
                    <select
                      name="invoice4"
                      id="invoice-select"
                      class="fmc-select"
                      value={formData.invoice4}
                      onChange={onChangeHandler}
                    >
                      <option value="">Please Choose invoiceType</option>
                      {Object.entries(invoiceTypeList)
                        .reverse()
                        .map(([key, value]) => (
                          <option value={key} key={key}>
                            <strong>{value}</strong>
                          </option>
                        ))}
                    </select>
                  </td>
                  <td>
                    <div style={{ display: 'flex' }}>
                      {' '}
                      <div> $</div>
                      <fmc-input name="amount4" value={formData.amount4} onInput={onChangeHandler}></fmc-input>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>
                    <select
                      name="invoice5"
                      id="invoice-select"
                      class="fmc-select"
                      value={formData.invoice5}
                      onChange={onChangeHandler}
                    >
                      <option value="">Please Choose invoiceType</option>
                      {Object.entries(invoiceTypeList)
                        .reverse()
                        .map(([key, value]) => (
                          <option value={key} key={key}>
                            <strong>{value}</strong>
                          </option>
                        ))}
                    </select>
                  </td>
                  <td>
                    <div style={{ display: 'flex' }}>
                      {' '}
                      <div> $</div>
                      <fmc-input name="amount5" value={formData.amount5} onInput={onChangeHandler}></fmc-input>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>
                    <select
                      name="invoice6"
                      id="invoice-select"
                      class="fmc-select"
                      value={formData.invoice6}
                      onChange={onChangeHandler}
                    >
                      <option value="">Please Choose invoiceType</option>

                      {Object.entries(invoiceTypeList)
                        .reverse()
                        .map(([key, value]) => (
                          <option value={key} key={key}>
                            <strong>{value}</strong>
                          </option>
                        ))}
                    </select>
                  </td>
                  <td>
                    <div style={{ display: 'flex' }}>
                      {' '}
                      <div> $</div>
                      <fmc-input name="amount6" value={formData.amount6} onInput={onChangeHandler}></fmc-input>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>7</td>
                  <td>
                    <select
                      name="invoice7"
                      id="invoice-select"
                      class="fmc-select"
                      value={formData.invoice7}
                      onChange={onChangeHandler}
                    >
                      <option value="">Please Choose invoiceType</option>

                      {Object.entries(invoiceTypeList)
                        .reverse()
                        .map(([key, value]) => (
                          <option value={key} key={key}>
                            <strong>{value}</strong>
                          </option>
                        ))}
                    </select>
                  </td>
                  <td>
                    <div style={{ display: 'flex' }}>
                      {' '}
                      <div> $</div>
                      <fmc-input name="amount7" value={formData.amount7} onInput={onChangeHandler}></fmc-input>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
        <div className="center" style={{ padding: '10px' }}>
          <fmc-button label="Continue" onClick={handleSubmit}>
            {' '}
          </fmc-button>
          <fmc-button label="Reset" onClick={handleReset}></fmc-button>
        </div>
      </div>
    </div>
  );
}
export default InvoicePage;
