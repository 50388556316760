import serviceAxiosInstance from '../config/api.service.config';
import { ENDPOINTS } from '../constants/endpoints';
import axios from 'axios';

export function updateAssignment(assignmentInfo) {
  return serviceAxiosInstance.put(
    ENDPOINTS['UPDATE_ASSIGNMENT'] +
      '?agentAction=' +
      assignmentInfo.agentAction +
      '&bpiNb=' +
      assignmentInfo.bpiNb +
      '&confirm=' +
      assignmentInfo.confirm +
      '&csrAction=' +
      assignmentInfo.csrAction +
      '&cntrctrNb=' +
      assignmentInfo.cntrctrNb +
      '&supApproval=' +
      assignmentInfo.supApproval +
      '&userId=' +
      assignmentInfo.userId
  );
}
export function getVechileDisposalInfo(customerAccoutNumber, cobusCode, origBranch) {
  return axios.get(
    process.env.REACT_APP_SERVICE_BASE_URL_FCNA +
      ENDPOINTS['VECHICLE_DISPOSAL_INFO_BASE_URL'] +
      cobusCode +
      '/' +
      origBranch +
      '/' +
      customerAccoutNumber
  );
  //return axios.get(process.env.REACT_APP_SERVICE_BASE_URL_FCNA+"api/v1/vehicleDisposal/7201/AC/356744684");
}
export function CreateVehicleDisposalInfo(insertvehicladisposaldata) {
  return axios.post(
    process.env.REACT_APP_SERVICE_BASE_URL_FCNA + ENDPOINTS['VECHICLE_DISPOSAL_INFO_BASE_URL'],
    insertvehicladisposaldata
  );
}
export function UpdateVehicleDisposalInfo(customerAccoutNumber, cobusCode, origBranch, updatevehicleDisposaldata1) {
  return axios.put(
    process.env.REACT_APP_SERVICE_BASE_URL_FCNA +
      ENDPOINTS['VECHICLE_DISPOSAL_INFO_BASE_URL'] +
      cobusCode +
      '/' +
      origBranch +
      '/' +
      customerAccoutNumber,
    updatevehicleDisposaldata1
  );
}

export function getAuctionLocationList(countrycode) {
  return axios.get(process.env.REACT_APP_SERVICE_BASE_URL_FCNA + ENDPOINTS['GET_AUCTION_LOCATION'] + countrycode);
}

export function postGivUpload(fileByte, CscmRequestObj) {
  return axios.get(process.env.REACT_APP_SERVICE_BASE_URL_CSCM + ENDPOINTS['CSCM_URL']);
}

export function FcnaDB2Postcall(transactionObj) {
  return axios.post(process.env.REACT_APP_SERVICE_BASE_URL_FCNA + ENDPOINTS['POST_FCNADB2'], transactionObj);
}
