import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { getCall } from '../../Services/CommonService';
import {
  API_ENDPOINT_REPO_SECURITY_REPO_USER_ID,
  API_ENDPOINT_REPO_SECURITY_SITE_CODE_ID,
  API_ENDPOINT_REPO_USER_DETAIL,
} from '../../constants/RepoReactConstants';
import { getUserInfoFromSession } from '../../Services/session.mgmt';

function LoginAuthorization() {
  const navigate = useNavigate();

  //let userId = "nhenry25"; //admin
  //let userId = "c-midwe5"; //repo
  //let userId = "asdf" //Unauthorized
  //const gsdbCd = "" //"825";
  const userInfo = getUserInfoFromSession();

  const goToPage = (isAdmin, isRepoUser) => {
    if (isAdmin) {
      navigate(`/contractorProfile`);
    } else if (isRepoUser) {
      navigate(`/home`);
    } else {
      navigate(`/unauthorized`);
    }
  };
  useEffect(() => {
    const isRepoUser = async () => {
      try {
        let isAdmin = false;
        let isRepoUser = false;

        const adminResponse = await getCall(API_ENDPOINT_REPO_SECURITY_REPO_USER_ID + userInfo.userid);

        console.log(adminResponse);

        if (adminResponse && adminResponse.status === 200 && adminResponse.data) {
          isAdmin = true;
        }

        if (userInfo.sitecode) {
          const gsdbResponse = await getCall(API_ENDPOINT_REPO_SECURITY_SITE_CODE_ID + userInfo.sitecode);

          console.log(gsdbResponse);

          if (gsdbResponse && gsdbResponse.status === 200 && gsdbResponse.data) {
            userInfo.userid = gsdbResponse.data.result.trim();
          }
        }

        const userResponse = await getCall(API_ENDPOINT_REPO_USER_DETAIL + userInfo.userid);

        console.log(userResponse);

        if (userResponse && userResponse.status === 200 && userResponse.data) {
          userInfo.userid = userResponse.data.result;
          isRepoUser = true;
        }
        console.log(`isAdmin = ${isAdmin}`);
        console.log(`isRepoUser = ${isRepoUser}`);
        goToPage(isAdmin, isRepoUser);
      } catch (err) {
        console.log('Error when validating login user');
        console.log(err);
        navigate(`/unauthorized`);
      }
    };

    isRepoUser();
  }, []);

  return <p>Redirecting...</p>;
}

export default LoginAuthorization;
