import './Select.css';

const Select = (props) => {
  return (
    <>
      <select
        name={props.name}
        id={props.id}
        className={'fmc-select ' + props.className}
        value={props.selectedVal}
        size={props.size ? props.size : 1}
        onChange={props.onChange}
      >
        {!props.emptyDefault && <option value=""></option>}
        {props.options?.map((option) => (
          <option value={option.value} key={option.value}>
            {option.name}
          </option>
        ))}
      </select>
    </>
  );
};

export default Select;
