import axios from 'axios';

const pinnacleUrl = 'https://api.d01i.gcp.ford.com/account-activities/v4/';
const DB2TransactionApi = 'https://api.d01i.gcp.ford.com/fcnadb2services/api/v1/transaction';
const baseURL = 'http://localhost:8090/fcnadb2services/api/v1/';
export const fcnaBFFapi = 'http://localhost:3001/fcnadb2services/';

axios.defaults.withCredentials = true;

export const getCall = async (url) => {
  try {
    return await axios.get(process.env.REACT_APP_SERVICE_BASE_URL + url);
  } catch (err) {
    return err;
  }
};
export const getCustomerAccountLookUp = async (url) => {
  try {
    return await axios.get(process.env.REACT_APP_SERVICE_BASE_URL_EXT + url);
  } catch (err) {
    return err;
  }
};

export const postCall = async (url, data) => {
  try {
    return await axios.post(process.env.REACT_APP_SERVICE_BASE_URL + url, data);
  } catch (error) {
    console.error(error);
    return error;
  }
};
export const putCall = async (url, data) => {
  try {
    return await axios.put(process.env.REACT_APP_SERVICE_BASE_URL + url, data);
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const deleteCall = async (url) => {
  try {
    return await axios.delete(process.env.REACT_APP_SERVICE_BASE_URL + url);
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getCallFCNA = async (url, param) => {
  try {
    return await axios.get(fcnaBFFapi + url, {
      url: url,
      method: 'GET',
      params: param,
    });
  } catch (err) {
    return err;
  }
};

export const postCallFCNA = async (url, data, param) => {
  try {
    return await axios.post(fcnaBFFapi + url, {
      url: baseURL + url,
      method: 'POST',
      body: data,
      params: param,
    });
  } catch (error) {
    console.error(error);
    return error;
  }
};
export const putCallFCNA = async (url, data, param) => {
  try {
    return await axios.put(fcnaBFFapi + url, {
      url: baseURL + url,
      method: 'PUT',
      body: data,
      params: param,
    });
  } catch (error) {
    console.error(error.message);
    return error;
  }
};
export const postCallDB2 = async (custAccNb, teamwrkBpiNb, coBusCd, UserId, action) => {
  try {
    return await axios(DB2TransactionApi, {
      method: 'POST',
      // headers: header1,
      data: {
        acctNb: custAccNb,
        coBusCd: coBusCd,
        errorCode: 0,
        lastUpdatedUserIdentificationCode: UserId,
        sourceSystemCode: 12,
        statusCode: 0,
        transTypeCd: 21,
        transactionDataText: teamwrkBpiNb + ';' + action,
      },
    });
  } catch (error) {
    console.error(error);
    return error;
  }
};
export const postCallBPM = async (custAccNb, teamwrkBpiNb, coBusCd, userId, action) => {
  let message = '<eventmsg>';
  message += '<event';
  message += ' processApp="CPKUP"';
  message += ' ucaname="' + 'FC_BP_CM_RelocateAgentWithParam' + '">';
  message += 'Relocate with Param';
  message += '</event>';
  message += '<parameters>';
  message += '<parameter>';
  message += '<key>message</key>';
  message += '<value>' + teamwrkBpiNb + 'RepoWebActn</value>';
  message += '</parameter>';
  message += '<parameter>';
  message += '<key>param</key>';
  message += '<value>' + action + '</value>';
  message += '</parameter>';
  message += '</parameters>';
  message += '</eventmsg>';

  return postCallDB2(custAccNb, teamwrkBpiNb, coBusCd, userId, action);
};

export const postCallPinnacle = async () => {
  return '';
};
